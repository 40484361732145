<script lang="ts">
    import Fa from "svelte-fa";
    import { _ } from "svelte-i18n";
    import { faXmark } from "@fortawesome/free-solid-svg-icons";
    import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";
    import { TextInput, Button, Radio } from "@two-ui/core";

    import {
        fraudRulesAddNewFlow,
        fraudRulesAddNewStep,
        fraudRulesOrgNumber,
        fraudRulesEmails,
        fraudRulesPhones,
        fraudRulesReason,
        fraudRulesType,
        fraudRulesComment,
        addPhoneToStore,
        removePhoneFromStore
    } from "@/store/merchant/risk.store";
    import PhoneInput from "@/components/authentication/PhoneInput.svelte";
    import { account as accountStore } from "@/store/merchant/account.store";
    import { COUNTRIES } from "@/static/constant";
    import { emailIsValid, validatePhoneNumber } from "@/utils/validation";

    let initialPhoneCountryCode = "";

    let commentIsEmpty = false;
    let remarksAreEmpty = false;
    let phoneErrors = [];
    let emailErrors = [];

    function handleOrgNumberChange(e: Event) {
        const target = e.target as HTMLInputElement;
        fraudRulesOrgNumber.set(target.value);
    }

    function handleCommentChange(e: Event) {
        const target = e.target as HTMLInputElement;
        fraudRulesComment.set(target.value);
    }

    let showFillAtLeastOneError = false;

    function onContinue() {
        // reset errors
        emailErrors = [];
        commentIsEmpty = !$fraudRulesComment;
        remarksAreEmpty =
            ($fraudRulesAddNewFlow === "whitelist" && !$fraudRulesReason) ||
            ($fraudRulesAddNewFlow === "block" && !$fraudRulesType);

        // check at least one field filled in
        showFillAtLeastOneError =
            !$fraudRulesOrgNumber &&
            $fraudRulesPhones.every(
                (phone) => !phone.value || phone.value === initialPhoneCountryCode
            ) &&
            $fraudRulesEmails.every((email) => !email);

        if (showFillAtLeastOneError) {
            $fraudRulesEmails.forEach((email, index) => {
                emailErrors[index] = true;
            });
            return;
        }
        // check phones are valid
        for (let index = 0; index < $fraudRulesPhones.length; index++) {
            const phoneValue = $fraudRulesPhones[index].value;
            if (phoneValue !== initialPhoneCountryCode) {
                const phoneError = validatePhoneNumber($_, initialPhoneCountryCode, phoneValue);
                if (phoneError) {
                    phoneErrors[index] = phoneError;
                    return;
                }
            }
        }

        // check emails are valid
        for (let index = 0; index < $fraudRulesEmails.length; index++) {
            if ($fraudRulesEmails[index] && !emailIsValid($fraudRulesEmails[index])) {
                emailErrors[index] = $_("errors.validEmail");
                return;
            }
        }

        if (commentIsEmpty) return;

        fraudRulesAddNewStep.set(2);
    }

    $: if ($accountStore.countryCode) {
        initialPhoneCountryCode = COUNTRIES[$accountStore.countryCode]?.phoneCountryCode || "+351";
        if (!$fraudRulesPhones.length) {
            addPhoneToStore(initialPhoneCountryCode);
        }
    }

    export { onContinue };
</script>

<div id="add-rule-wrapper" class:with-error={showFillAtLeastOneError}>
    <!-- Step 1 -->
    <h3 class="text-base font-bold mt-2">{$_("riskManagement.rules.step1Title")}</h3>
    <h4
        class="text-sm text-Content-General-Secondary"
        class:text-Content-Semantic-Error-Primary={showFillAtLeastOneError}
    >
        {$_("riskManagement.rules.step1Description")}
    </h4>
    <TextInput
        id="rule-org-number"
        size="md"
        on:change={handleOrgNumberChange}
        label={$_("riskManagement.fraud.organizationNumber")}
        class="my-6 max-w-[320px]"
        error={showFillAtLeastOneError}
        value={$fraudRulesOrgNumber}
        placeholder={$_("components.enterField", {
            values: { field: $_("order.details.org_number") }
        })}
    />

    <!-- Phones -->
    {#each $fraudRulesPhones as phone, index}
        <div class="flex justify-between items-end mt-2">
            <div>
                {#if index === 0}
                    <label
                        class="label text-sm text-Content-General-Secondary"
                        for="add-rule-phone"
                    >
                        {$_("components.inputType.phone")}
                    </label>
                {/if}
                <div class="flex">
                    <div class="w-[320px]">
                        <PhoneInput
                            id={`add-rule-phone-${phone.id}`}
                            useCustomInput={true}
                            disable={false}
                            bind:phoneNumberWithCode={phone.value}
                        />
                    </div>
                    {#if $fraudRulesPhones.length > 1}
                        <button
                            class="ml-2 cursor-pointer"
                            on:click={() => removePhoneFromStore(phone.id)}
                        >
                            <Fa size="lg" icon={faXmark} color="var(--ctnGeneralTertiary)" />
                        </button>
                    {/if}
                </div>
            </div>
            {#if index === $fraudRulesPhones.length - 1}
                <Button
                    on:click={() => addPhoneToStore(initialPhoneCountryCode)}
                    size="md"
                    class="add-phone-btn mt-2"
                    variant="tertiaryColor"
                    content={$_("riskManagement.rules.addField")}
                >
                    <Fa icon={faPlusSquare} color="var(--primary-600)" slot="leftIcon" />
                </Button>
            {/if}
        </div>
    {/each}

    <!-- Emails -->
    {#each $fraudRulesEmails as email, index}
        <div class="flex justify-between items-center mt-1" class:mt-6={index === 0}>
            <div class="flex items-center">
                <TextInput
                    id={`rule-email-${index}`}
                    size="md"
                    bind:value={email}
                    bind:error={emailErrors[index]}
                    label={index === 0 ? $_("components.inputType.email") : null}
                    class="w-[320px]"
                    placeholder={$_("login.enterEmail")}
                />
                {#if $fraudRulesEmails.length > 1}
                    <button
                        class="ml-2 cursor-pointer"
                        class:mt-5={index === 0}
                        on:click={() =>
                            fraudRulesEmails.set([
                                ...$fraudRulesEmails.slice(0, index),
                                ...$fraudRulesEmails.slice(index + 1)
                            ])}
                    >
                        <Fa size="lg" icon={faXmark} color="var(--ctnGeneralTertiary)" />
                    </button>
                {/if}
            </div>
            {#if index === $fraudRulesEmails.length - 1}
                <Button
                    on:click={() => fraudRulesEmails.set([...$fraudRulesEmails, ""])}
                    size="md"
                    class="add-email-btn mt-2"
                    variant="tertiaryColor"
                    content={$_("riskManagement.rules.addField")}
                >
                    <Fa icon={faPlusSquare} color="var(--primary-600)" slot="leftIcon" />
                </Button>
            {/if}
        </div>
    {/each}

    <!-- Step 2 -->
    <h3 class="text-base font-bold mt-9">{$_("riskManagement.rules.step2Title")}</h3>
    <h4 class="text-sm text-Content-General-Secondary">
        {$_("riskManagement.rules.step2Description")}
    </h4>
    <p class="text-sm mt-6">
        {$fraudRulesAddNewFlow === "whitelist"
            ? $_("riskManagement.fraud.reason")
            : $_("riskManagement.fraud.fraudType")}
    </p>
    <div class="grid grid-cols-2 gap-1">
        {#if $fraudRulesAddNewFlow === "whitelist"}
            <Radio
                class="mt-2"
                checked={$fraudRulesReason === "verified"}
                label={$_("riskManagement.fraud.buyerVerified")}
                on:click={() => fraudRulesReason.set("verified")}
            />
            <Radio
                class="mt-2"
                checked={$fraudRulesReason === "trusted"}
                label={$_("riskManagement.fraud.existingCustomer")}
                on:click={() => fraudRulesReason.set("trusted")}
            />
            <Radio
                class="mt-2"
                checked={$fraudRulesReason === "other"}
                label={$_("riskManagement.fraud.otherReasons")}
                on:click={() => fraudRulesReason.set("other")}
            />
        {:else}
            <Radio
                class="mt-2"
                checked={$fraudRulesType === "first-party"}
                label={$_("riskManagement.fraud.firstParty")}
                on:click={() => fraudRulesType.set("first-party")}
            />
            <Radio
                class="mt-2"
                checked={$fraudRulesType === "third-party"}
                label={$_("riskManagement.fraud.thirdParty")}
                on:click={() => fraudRulesType.set("third-party")}
            />
        {/if}
    </div>
    {#if remarksAreEmpty}
        <p class="mt-1 text-Content-Semantic-Error-Primary text-sm">
            {$_("errors.cantBeEmpty")}
        </p>
    {/if}

    <h3 class="text-sm font-medium mt-6 mb-2">{$_("riskManagement.fraud.comments")}</h3>
    <textarea
        id="add-rule-comment"
        on:change={handleCommentChange}
        value={$fraudRulesComment}
        rows="4"
        class:border-Border-Semantic-Error-Secondary={commentIsEmpty}
        placeholder={$_("components.descriptionPlaceholder")}
        class="w-full border rounded-sm p-2 text-sm"
    />
    {#if commentIsEmpty}
        <p class="text-Content-Semantic-Error-Primary text-sm">
            {$_("errors.cantBeEmpty")}
        </p>
    {/if}
</div>
