<script lang="ts">
    import { _ } from "svelte-i18n";

    import { getBaseUrl } from "../../../../utils/functions";
    import utils from "../../../../utilsV2/currency";
    import { DOWNLOAD_BULK_PDFS } from "../../../../static/endPoints";
    import TableCellDropdown from "../../../../components/table/TableCellDropdown.svelte";
    import API from "../../../../services/apiService";
    import { UPDATE_INVOICE_STATUS } from "../../../../static/endPoints";
    import { NotificationType } from "../../../../static/types";
    import notificationState from "../../../../store/notifications.store";
    import environment from "@/services/environment";

    export let title = "";
    export let columns = [];
    export let items = [];
    export let locale = "";
    export let currency: any;
    export let isInstalmentOrder: boolean = false;
    let downloadAllQueryString = "";
    let queryString = "";
    let selectedItems = [];
    let baseURL;
    let collapseMode: string = "-";

    const customerPaymentStatus = {
        NOT_DUE: "Not due",
        DUE: "Due",
        OVERDUE: "Overdue",
        OVERDUE_PARTIALLY_PAID: "Overdue, partially paid",
        PAID: "Paid",
        RECOURSED: "Recoursed",
        PARTIAL_RECOURSE: "Partially recoursed",
        REFUNDED: "Refunded",
        PARTIAL_REFUNDED: "Partially refunded"
    };

    $: {
        handleItemsChange(items);
    }

    function handleItemsChange(items) {
        downloadAllQueryString = items
            .map((order) => `order_id=${encodeURIComponent(order.order_id)}`)
            .join("&");

        baseURL = getBaseUrl();

        items.forEach((item) => {
            setPaymentStatus(item);
        });
        items = items;

        if (items.length > 5) {
            collapseMode = "+";
        }
    }

    function itemSelectionChanged(event) {
        if (event.target.checked && selectedItems.indexOf(event.target.id) === -1) {
            selectedItems.push(event.target.id);
        } else if (!event.target.checked && selectedItems.indexOf(event.target.id) !== -1) {
            const index = selectedItems.indexOf(event.target.id);
            selectedItems.splice(index, 1);
        }

        selectedItems = selectedItems;

        queryString = selectedItems
            .map((order_id) => `order_id=${encodeURIComponent(order_id)}`)
            .join("&");
    }

    function setPaymentStatus(item) {
        const day = item.due_date?.substring(8);
        const month = item.due_date?.substring(5, 7);
        const year = item.due_date?.substring(0, 4);
        const dueDate: Date = new Date(month + "-" + day + "-" + year);

        const dateDiff: number = Date.now() - dueDate.getTime();

        if (item.payment_status.toUpperCase() === "PAID") {
            item.paymentStatus = customerPaymentStatus.PAID;
            item.paymentStatusIcon = "paid";
        } else if (item.payment_status.toUpperCase() === "REFUNDED") {
            item.paymentStatus = customerPaymentStatus.REFUNDED;
            item.paymentStatusIcon = "paid";
        } else if (item.payment_status.toUpperCase() === "PARTIAL_REFUND") {
            item.paymentStatus = customerPaymentStatus.PARTIAL_REFUNDED;
            item.paymentStatusIcon = "overdue";
        } else if (item.payment_status.toUpperCase() == "RECOURSED") {
            item.paymentStatus = customerPaymentStatus.RECOURSED;
            item.paymentStatusIcon = "overdue";
        } else if (item.payment_status.toUpperCase() == "PARTIAL_RECOURSE") {
            item.paymentStatus = customerPaymentStatus.PARTIAL_RECOURSE;
            item.paymentStatusIcon = "overdue";
        } else if (dateDiff <= 0) {
            item.paymentStatus = customerPaymentStatus.DUE;
            item.paymentStatusIcon = "due";
        } else if (dateDiff > 0) {
            item.paymentStatus = customerPaymentStatus.OVERDUE;
            item.paymentStatusIcon = "overdue";
        } else {
            item.paymentStatus = "";
            item.paymentStatusIcon = "";
        }
    }

    function changeCollapseMode() {
        if (collapseMode === "+") {
            collapseMode = "-";
        } else {
            collapseMode = "+";
        }
    }

    function changePaymentStatus(event, orderID) {
        API.post(UPDATE_INVOICE_STATUS(orderID), {
            payment_status: event.detail.newStatus
        })
            .then(() => {
                notificationState.actions.create(
                    NotificationType.SUCCESS,
                    $_("order.details.payment_status_updated")
                );
            })
            .catch(() => {
                notificationState.actions.create(
                    NotificationType.ERROR,
                    $_("order.details.payment_status_update_error"),
                    null,
                    $_("order.details.payment_status_update_try_again", {
                        values: { email: environment.branding.supportEmail }
                    })
                );
            });
    }
</script>

<div>
    <div class="main-bar">
        <span class="card-title">{title}</span>

        {#if !isInstalmentOrder}
            <div class="button-container">
                {#if selectedItems.length > 0}
                    <a
                        class="download-invoice"
                        href={`${baseURL}${DOWNLOAD_BULK_PDFS(queryString)}`}
                        >{$_("order.downloadSelected")}</a
                    >
                {/if}
                {#if items.length > 1 && collapseMode === "-"}
                    <a
                        class="download-invoice"
                        href={`${baseURL}${DOWNLOAD_BULK_PDFS(downloadAllQueryString)}`}
                        >{$_("order.downloadAll")}</a
                    >
                {/if}
                <button class="collapsible-button" on:click={changeCollapseMode}
                    >{collapseMode}</button
                >
            </div>
        {/if}
    </div>

    {#if collapseMode === "-"}
        <div class="product-table-wrap">
            <table class="table">
                <thead class="table-head">
                    {#each columns as column}
                        <th
                            class="table-header table-header--{column.alignment}"
                            class:extend-margin={column.label === $_("order.edit.invoice_number") &&
                                items.length > 1}
                            style="width: {column.width}"
                        >
                            {@html column.label}
                        </th>
                    {/each}
                </thead>
                {#each items as item}
                    <tr class="table-row">
                        <td class="table-cell">
                            <label class="checkbox-label">
                                {#if items.length > 1}
                                    <input
                                        class="item-checkbox"
                                        id={item.order_id}
                                        type="checkbox"
                                        on:change={itemSelectionChanged}
                                    />
                                {/if}
                                {item.invoice_number}
                            </label></td
                        >
                        <td class="table-cell">{item.invoice_issueDate}</td>
                        <td class="table-cell">{item.due_date}</td>
                        <td class="table-cell"
                            >{utils.formatCurrency(currency)(item.value, { locale })}</td
                        >
                        <td class="table-cell">
                            {#if item.invoice_type === "DIRECT_INVOICE"}
                                <TableCellDropdown
                                    cellItem={item.payment_status}
                                    on:selectionchanged={(event) =>
                                        changePaymentStatus(event, item.order_id)}
                                />
                            {:else}
                                <div class="flex">
                                    <div class="pr-2">{item.paymentStatus}</div>
                                    <img
                                        class="tick"
                                        style="width: 50px;max-width: 12px;"
                                        src="/pictures/{item.paymentStatusIcon}.svg"
                                        alt={item.paymentStatus}
                                    />
                                </div>{/if}
                        </td>
                        <td class="table-cell">
                            {#if !isInstalmentOrder}
                                <a href={item.invoice_url} class="download-button" target="_blank">
                                    {$_("components.download")}
                                </a>
                            {/if}
                        </td>
                    </tr>
                {/each}
            </table>
        </div>
    {/if}
</div>

<style lang="scss">
    .card {
        flex-basis: 100%;
        border-radius: 0.5rem;
        background-color: var(--basic);
        padding: 1.5rem;
        --tw-bg-opacity: 1;

        border: 1px solid var(--gray-100);
        box-shadow: 0 0 6px rgba(208, 213, 221, 0.25);

        &--table {
            padding: 0;
        }
    }

    .collapsible-button {
        border-radius: 8px;
        border: 1px solid var(--gray-300, #d0d5dd);
        background: var(--basic-white, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        width: 38px;
        font-size: 24px;
        color: #344054;
    }

    .scrollbar {
        overflow: auto;
    }

    .card-title {
        display: flex;
        gap: 10px;
        font-weight: 700;
        line-height: 24px;
    }

    .card-title {
        padding: 24px;
    }

    .card-heading {
        color: var(--ctnGeneralTertiary);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-top: 16px;
    }

    .download-invoice {
        border-radius: 8px;
        border: 1px solid var(--gray-300, #d0d5dd);
        background: var(--basic-white, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--gray-700, #344054);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.5px;
        padding: 8px 14px;
    }

    .product-table-wrap {
        width: 100%;
    }

    .main-bar {
        display: flex;
        justify-content: space-between;
    }

    .button-container {
        margin: auto 1rem;
        display: flex;
        gap: 1rem;
    }

    .checkbox-label {
        line-height: 20px;
        margin: auto;
        height: 20px;
        display: flex;
    }

    .item-checkbox {
        height: 1rem;
        width: 1rem;
        margin-right: 1rem;
    }

    .table {
        width: 100%;
        min-width: 930px;
        table-layout: fixed;
        font-size: 14px;
        font-weight: 400;
        color: var(--gray-700);
        margin-bottom: 24px;

        border-collapse: collapse;
        border-spacing: unset;
    }

    th,
    td {
        cursor: default;
    }

    .table-head {
        background-color: var(--bgGeneralQuaternary);
        color: var(--ctnGeneralSecondary);
    }

    .table-header {
        width: 100%;
        padding: 0 8px;
        height: 48px;

        &:first-child {
            padding-left: 24px;
        }

        &.extend-margin {
            padding-left: 56px;
        }

        &:last-child {
            padding-right: 24px;
        }

        &--left {
            text-align: left;
        }

        &--right {
            text-align: right;
        }
    }

    .table-cell {
        padding: 0 8px;
        font-weight: 400;

        &:first-child {
            padding-left: 24px;
        }

        &:last-child {
            padding-right: 24px;
        }
    }

    .download-button {
        color: var(--primary-600);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.5px;
    }
</style>
