<script lang="ts">
    import { _ } from "svelte-i18n";
    import Fa from "svelte-fa";
    import { createEventDispatcher, onMount } from "svelte";

    import StatusTag from "../statusTag/StatusTag.svelte";
    import modalState from "../../store/modals.store";
    import Icon from "../icons/Icon.svelte";
    import ToolTip from "../tooltip/ToolTip.svelte";

    const dispatch = createEventDispatcher();

    export let cellItem;
    let dynamicIcons: Array<{
        faIcon: string;
        noContainer?: boolean;
        link?: string;
        importedIcon;
        id?: string;
        action?: () => {};
    }> = [];

    export let type;
    export let dataTestId = "";

    function handleModalAction(item) {
        if (item.actionModal) {
            modalState.actions.setModal(item.actionModal, item.actionProps || {});
        }
    }

    function translateTag(key) {
        switch (key) {
            case "Downloaded":
                return $_("statements.downloaded");
            case "Not Downloaded":
                return $_("statements.notDownloaded");
            default:
                return key;
        }
    }

    function iconClicked(id, action) {
        dispatch("itemClicked", {
            id,
            action
        });
    }

    onMount(async () => {
        if (cellItem.hasOwnProperty("icons") && Array.isArray(cellItem.icons)) {
            dynamicIcons = await Promise.all(
                cellItem.icons
                    .filter((icon: any) => icon.hasOwnProperty("faIcon"))
                    .map(async (icon: any) => {
                        const iconName =
                            icon.faIcon as keyof typeof import("@fortawesome/free-solid-svg-icons");
                        const { [iconName]: importedIcon } = await import(
                            "@fortawesome/free-solid-svg-icons"
                        );
                        return { ...icon, importedIcon };
                    })
            );
        }
    });
</script>

{#if cellItem}
    <div class="m-auto inline-block" class:hidden={cellItem.hidden}>
        <div class="flex">
            <div
                data-testid={dataTestId}
                class={cellItem.actionModal ? "text-action" : ""}
                on:click={() => {
                    handleModalAction(cellItem);
                }}
                on:keydown={() => {
                    handleModalAction(cellItem);
                }}
                role="button"
                tabindex="0"
            >
                {#if cellItem.hasOwnProperty("tableItemBold")}
                    <div
                        class={cellItem.cellType}
                        class:bold-text={!cellItem.cellType ||
                            !cellItem.cellType?.includes("item-status")}
                        class:number={type === "number"}
                        style={cellItem?.itemTextColor ? `color: ${cellItem.itemTextColor};` : ""}
                    >
                        {cellItem.hasOwnProperty("tableItem")
                            ? `${cellItem.tableItemBold}, `
                            : cellItem.tableItemBold}
                        {#if cellItem.hasOwnProperty("tableItem")}
                            <span class={cellItem.cellType ? cellItem.cellType : "normal-text"}>
                                {cellItem.tableItem}
                            </span>
                        {/if}
                    </div>
                {:else if cellItem.hasOwnProperty("tableItem")}
                    <div
                        class={cellItem.cellType ? cellItem.cellType : "normal-text"}
                        class:number={type === "number"}
                        class:withIcon={cellItem.faIcon}
                        style={cellItem?.itemTextColor ? `color: ${cellItem.itemTextColor};` : ""}
                    >
                        {#if cellItem.iconName || cellItem.iconSrc}
                            <div class="flex items-center gap-2">
                                {#if cellItem.iconName}
                                    <Icon name={cellItem.iconName} />
                                {:else}
                                    <img
                                        alt="cell-item-icon"
                                        style="height:28px"
                                        src={cellItem.iconSrc}
                                    />
                                {/if}
                                {#if cellItem.tooltip}
                                    <ToolTip
                                        width="150px"
                                        position="left-down"
                                        content={cellItem.tooltipTranslation || cellItem.tooltip}
                                    >
                                        {cellItem.tableItem}
                                    </ToolTip>
                                {:else}
                                    {cellItem.tableItem}
                                {/if}
                            </div>
                        {:else}
                            {#if cellItem.faIcon}
                                <Fa size="md" class="mr-2" icon={cellItem.faIcon} />
                            {/if}
                            <!-- NOTE deliberately putting this into else block to not affect layout of existing instances -->
                            <span>{cellItem.tableItem}</span>
                        {/if}
                    </div>
                {/if}
            </div>
        </div>
        {#if cellItem.hasOwnProperty("subItem")}
            {#if cellItem.cellType == "user-role"}
                <StatusTag
                    statusText={cellItem.subItem}
                    textColor={cellItem.itemStatusTagTextColor || cellItem.itemTextColor}
                    backgroundColor={cellItem.itemBgColor}
                />
            {:else if [$_("statuses.paid"), $_("statuses.unpaid"), $_("statuses.partiallyPaid")].includes(cellItem.subItem) && !cellItem.hasOwnProperty("subItemTextColor")}
                <StatusTag
                    statusText={cellItem.subItem}
                    textColor={cellItem.itemStatusTagTextColor || cellItem.itemTextColor}
                    backgroundColor={cellItem.itemBgColor}
                />
            {:else}
                <div
                    class={`small-text flex gap-1 items-center ${
                        cellItem.subItemClasses || (cellItem.itemBgColor ? "px-1 py-0.5" : "")
                    }`}
                    style="{cellItem.subItemTextColor ? `color:${cellItem.subItemTextColor};` : ''}
          {`background-color: ${cellItem.itemBgColor || 'transparent'};`}
        "
                >
                    {cellItem.subItem}
                    {#if cellItem.subIconName}
                        <Icon
                            name={cellItem.subIconName}
                            size={12}
                            fill={"var(--ctnGeneralTertiary)"}
                        />
                    {/if}
                </div>
            {/if}
        {/if}
        {#if cellItem.hasOwnProperty("textLink")}
            <a
                on:click|stopPropagation
                class="text-link"
                href={cellItem.textLink.link}
                target="_blank"
            >
                {cellItem.textLink.text}
            </a>
        {:else if cellItem.hasOwnProperty("icons")}
            <div class="icon-container">
                {#each cellItem.icons as icon}
                    {#if icon.hasOwnProperty("iconSource")}
                        <a
                            on:click|stopPropagation
                            class:icon-image-no-container={icon.noContainer}
                            class:icon-image-container={!icon.noContainer}
                            href={icon.link}
                            target="_blank"
                        >
                            <img class="icon-image" src={icon.iconSource} alt={icon.description} />
                        </a>
                    {/if}

                    {#if icon.hasOwnProperty("iconText")}
                        <a
                            class="icon-text"
                            on:click|stopPropagation
                            class:disabled={icon.disabled}
                            href={icon.link}
                            target="_blank">{icon.iconText}</a
                        >
                    {/if}
                {/each}
                {#each dynamicIcons as icon}
                    {#if icon.hasOwnProperty("faIcon")}
                        <ToolTip
                            hideTip={!icon.tooltip}
                            width="100px"
                            position="left-down"
                            content={icon.tooltip}
                        >
                            <div
                                on:click={() => {
                                    iconClicked(icon.id, icon.action);
                                }}
                                on:keyup
                                class="fa-icon-container"
                            >
                                <Fa
                                    size="md"
                                    icon={icon.importedIcon}
                                    color="var(--ctnGeneralPrimary)"
                                />
                            </div>
                        </ToolTip>
                    {/if}
                {/each}
            </div>
        {:else if cellItem.hasOwnProperty("tags")}
            {#each cellItem.tags as tag}
                <StatusTag
                    statusText={translateTag(tag.text)}
                    textColor={tag.textColor}
                    backgroundColor={tag.bgColor}
                />
            {/each}
        {/if}
    </div>
{/if}

<style>
    .table-cell {
        padding: 5px 10px 5px 0;
    }
    .item-status {
        border-radius: 8px;
        min-width: 100px;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        padding: 8px 6px;
    }

    .accepted {
        color: var(--ctnSemanticSuccessPrimary);
        background: var(--bgSemanticSuccessTertiary);
    }

    .refunded {
        color: var(--ctnSemanticWarningPrimary);
        background: var(--bgSemanticWarningTertiary);
    }

    .ready-to-fulfill {
        color: var(--ctnSemanticInfoPrimary);
        background: var(--bgSemanticInfoTertiary);
    }

    .pending {
        color: var(--ctnGeneralTertiary);
        background: var(--bgGeneralTertiary);
    }

    .cancelled {
        color: var(--ctnGeneralPrimary);
        background: var(--bgGeneralQuaternary);
    }

    .rejected {
        color: var(--error-600);
        background: var(--error-50);
    }

    .blue-highlight {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--info-600);
    }

    .normal-text {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: var(--ctnGeneralSecondary);
    }

    .bold-text {
        font-size: 13px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        padding-right: 0.25rem;
        color: var(--ctnGeneralPrimary);
    }

    .withIcon {
        display: flex;
        align-items: center;
    }

    .small-text {
        font-family: Aeonik;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: var(--ctnGeneralTertiary);
    }

    .negative-amount {
        color: var(--error-700);
    }

    .number {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 12rem;
    }

    .text-action:hover {
        text-decoration: underline;
    }

    .icon-container {
        display: flex;
        gap: 8px;
    }

    .icon-image-no-container {
        margin-block: 0.5rem;
    }

    .icon-image-container {
        border: 1px solid #d0d5dd;
        border-radius: 8px;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        padding: 8px;
    }

    .fa-icon-container {
        padding: 0.5rem;
    }

    .icon-image {
        max-width: none !important;
    }

    .icon-text {
        color: var(--gray-700);
        border: 1px solid var(--gray-300);
        padding: 8px 14px;
        border-radius: 8px;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    }

    .paid {
        color: var(--success-800);
    }

    .unpaid {
        color: var(--warning-700);
    }

    .partially-paid {
        color: var(--info-800);
    }

    .disabled {
        color: var(--gray-200);
        pointer-events: none;
        cursor: default;
    }

    @media only screen and (max-width: 1300px) {
        .number {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 8rem;
        }
    }

    .text-link {
        color: var(--primary-600);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.5px;
    }

    img {
        display: inline-block;
    }
</style>
