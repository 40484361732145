import type { ComponentType } from "svelte";

import { CurrencyCodeEnum, type ICountry, LanguageCode, NotificationType } from "./types";
import CheckCircle from "../components/icons/checkCircle.svelte";
import AlertIcon from "../components/icons/alert2.svelte";
import ErrorIcon from "../components/icons/erroricon.svelte";
import { getEnvironmentName } from "@/services/environment";

export const CUSTOMERS_SEARCH_PLACEHOLDER = "Search by Company name, Company ID.";
export const TWO_LEGAL_NAME = "Two B2B LTD";

// ISO 3166-2 country codes
export const GB = "GB";
export const NO = "NO";
export const SE = "SE";
export const US = "US";
export const NL = "NL";
export const ES = "ES";
// const AT = "AT";
// const BE = "BE";
// const EE = "EE";
// const FR = "FR";
// const DE = "DE";
// const IS = "IS";
// const IE = "IE";
// const IT = "IT";
// const LV = "LV";
// const LT = "LT";
// const PL = "PL";

export const DK = "DK";
export const FI = "FI";
export const GR = "GR";

export const ZZ = "ZZ";

export const BUSINESS_CUSTOMER_ONBOARDED_MERCHANTS = [];

enum paymentProductType {
    STATEMENT = "STATEMENT",
    INVOICE = "INVOICE",
    PREVIEW_STATEMENT = "PREVIEW_STATEMENT"
}

export const FILE_TYPES: string[] = ["CSV", "XLSX"];

export { paymentProductType };

// NOTE: When we implement translations,
// the language field and name could be dynamically set depending on merchant language
let BASE_COUNTRIES: Record<string, ICountry> = {
    NO: <ICountry>{
        name: "Norway",
        country_code: NO,
        currency: CurrencyCodeEnum.NOK,
        languageCode: LanguageCode.NB,
        language: "Norwegian",
        phoneCountryCode: "+47"
    },
    GB: <ICountry>{
        name: "United Kingdom",
        country_code: GB,
        currency: CurrencyCodeEnum.GBP,
        languageCode: LanguageCode.EN,
        language: "English",
        phoneCountryCode: "+44"
    },
    SE: <ICountry>{
        name: "Sweden",
        country_code: SE,
        currency: CurrencyCodeEnum.SEK,
        locale: "sv_SE",
        languageCode: LanguageCode.SV,
        language: "Swedish",
        phoneCountryCode: "+46"
    },
    US: <ICountry>{
        name: "USA",
        country_code: US,
        currency: CurrencyCodeEnum.USD,
        languageCode: LanguageCode.US,
        language: "English",
        phoneCountryCode: "+1"
    },
    NL: <ICountry>{
        name: "Netherlands",
        country_code: NL,
        currency: CurrencyCodeEnum.EUR,
        languageCode: LanguageCode.NL,
        language: "Dutch",
        phoneCountryCode: "+31"
    },
    ES: <ICountry>{
        name: "Spain",
        country_code: ES,
        currency: CurrencyCodeEnum.EUR,
        languageCode: LanguageCode.EN,
        language: "Spanish",
        phoneCountryCode: "+34"
    },
    DK: <ICountry>{
        name: "Denmark",
        country_code: DK,
        currency: CurrencyCodeEnum.DKK,
        languageCode: LanguageCode.DA,
        language: "Danish",
        phoneCountryCode: "+45"
    },
    FI: <ICountry>{
        name: "Finland",
        country_code: FI,
        currency: CurrencyCodeEnum.EUR,
        languageCode: LanguageCode.FI,
        language: "Finnish",
        phoneCountryCode: "+358"
    }
};
if (getEnvironmentName() !== "production") {
    BASE_COUNTRIES[GR] = <ICountry>{
        name: "Greece",
        country_code: GR,
        currency: CurrencyCodeEnum.EUR,
        languageCode: LanguageCode.EN,
        language: "English",
        phoneCountryCode: "+30"
    };
}

export const COUNTRIES: Record<string, ICountry> = BASE_COUNTRIES;
// AT: <ICountry>{
//     name: "Austria",
//     country_code: AT,
//     currency: CurrencyCodeEnum.EUR,
//     languageCode: LanguageCode.DE,
//     language: "German",
//     phoneCountryCode: "+43"
// },
// BE: <ICountry>{
//     name: "Belgium",
//     country_code: BE,
//     currency: CurrencyCodeEnum.EUR,
//     languageCode: LanguageCode.NL,
//     language: "Dutch",
//     phoneCountryCode: "+32"
// },
// EE: <ICountry>{
//     name: "Estonia",
//     country_code: EE,
//     currency: CurrencyCodeEnum.EUR,
//     languageCode: LanguageCode.ET,
//     language: "Estonian",
//     phoneCountryCode: "+372"
// },
// FR: <ICountry>{
//     name: "France",
//     country_code: FR,
//     currency: CurrencyCodeEnum.EUR,
//     languageCode: LanguageCode.FR,
//     language: "French",
//     phoneCountryCode: "+33"
// },
// DE: <ICountry>{
//     name: "Germany",
//     country_code: DE,
//     currency: CurrencyCodeEnum.EUR,
//     languageCode: LanguageCode.DE,
//     language: "German",
//     phoneCountryCode: "+49"
// },
// IS: <ICountry>{
//     name: "Iceland",
//     country_code: IS,
//     currency: CurrencyCodeEnum.EUR,
//     languageCode: LanguageCode.IS,
//     language: "Icelandic",
//     phoneCountryCode: "+354"
// },
// IE: <ICountry>{
//     name: "Ireland",
//     country_code: IE,
//     currency: CurrencyCodeEnum.EUR,
//     languageCode: LanguageCode.EN,
//     language: "English",
//     phoneCountryCode: "+353"
// },
// IT: <ICountry>{
//     name: "Italy",
//     country_code: IT,
//     currency: CurrencyCodeEnum.EUR,
//     languageCode: LanguageCode.IT,
//     language: "Italian",
//     phoneCountryCode: "+39"
// },
// LV: <ICountry>{
//     name: "Latvia",
//     country_code: LV,
//     currency: CurrencyCodeEnum.EUR,
//     languageCode: LanguageCode.LV,
//     language: "Latvian",
//     phoneCountryCode: "+371"
// },
// LT: <ICountry>{
//     name: "Lithuania",
//     country_code: LT,
//     currency: CurrencyCodeEnum.EUR,
//     languageCode: LanguageCode.LT,
//     language: "Lithuanian",
//     phoneCountryCode: "+370"
// },
// PL: <ICountry>{
//     name: "Poland",
//     country_code: PL,
//     currency: CurrencyCodeEnum.EUR,
//     languageCode: LanguageCode.PL,
//     language: "Polish",
//     phoneCountryCode: "+48"
// },
// PT: <ICountry>{
//     name: "Portugal",
//     country_code: "PT",
//     currency: CurrencyCodeEnum.EUR,
//     languageCode: LanguageCode.PT,
//     language: "Portuguese",
//     phoneCountryCode: "+351"
// }

export const UNKNOWN_COUNTRY: ICountry = {
    name: "Unknown",
    country_code: ZZ,
    currency: CurrencyCodeEnum.ZZZ
};

export const LOGIN_SIGNUP_IMAGES: object[] = [
    {
        src: "/pictures/carousel/image01.png",
        testimonial:
            '"Online B2B transactions generally include long, manual processes - so much so that we often risk losing the entire purchase. Without Two, we would have had to reject 52% of our B2B customers!"',
        providerName: "Line S. Ludvigsen",
        roleCompany: "Marketing & E-Commerce Manager, Eplehuset"
    },
    {
        src: "/pictures/carousel/image02.png",
        testimonial:
            '"Introducing Two was the best decision we could have made as a business. It gives our customers the extra breathing space with the 30 day credit terms and it sets us apart from our competitors. We saw a 400% increase in AOV after using Two!"',
        providerName: "Mark Farr",
        roleCompany: "CEO & Co-Founder, Purple Planet"
    },
    {
        src: "/pictures/carousel/image03.jpg",
        testimonial:
            '"The main reason we chose Two as our B2B payment solution is because our previous methods created a lot of manual work. We no longer have that issue, which is something I\'m very happy with."',
        providerName: "Jørgen Bertelsen",
        roleCompany: "Founder, RiktigHandel"
    }
];

export const NotificationTypeIcons: Record<string, ComponentType> = {
    [NotificationType.SUCCESS]: CheckCircle,
    [NotificationType.WARNING]: AlertIcon,
    [NotificationType.ERROR]: ErrorIcon
};
