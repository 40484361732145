<script lang="ts">
    import { _ } from "svelte-i18n";
    import { createEventDispatcher } from "svelte";
    import { Button } from "@two-ui/core";
    import Fa from "svelte-fa";
    import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

    import Modal from "@/components/Elements/Modal.svelte";
    import CheckboxItem from "@/components/checkboxitem/CheckboxItem.svelte";
    import API from "@/services/apiService";
    import notificationState from "@/store/notifications.store";
    import { NotificationType } from "@/static/types";
    import { getErrorMessage } from "@/utilsV2/helpers";

    export let showRemoveFraudModal = false;
    export let orderID = "";

    let comment = "";
    let commentError = "";
    let isChecked = false;
    let isCheckedError = "";
    let isLoading = false;
    let formSubmitted = false;

    const dispatch = createEventDispatcher();

    function closeModal() {
        showRemoveFraudModal = false;
        dispatch("close");
    }

    function isFormValid() {
        formSubmitted = true;

        commentError = comment ? "" : $_("errors.fieldRequired");
        isCheckedError = isChecked ? "" : $_("errors.confirmConsent");

        return Boolean(!commentError && !isCheckedError);
    }

    function submitRemoveFraudLabel() {
        if (!isFormValid()) return;
        isLoading = true;

        const data = {
            comment
        };

        API.post("https://jsonplaceholder.typicode.com/posts", data)
            .then(() => {
                closeModal();
                notificationState.actions.create(
                    NotificationType.SUCCESS,
                    $_("order.details.fraud_label_removed")
                );
            })
            .catch((error) => {
                const errorMessage = getErrorMessage(
                    error.response,
                    "submitRemoveFraudLabel failed"
                );
                notificationState.actions.create(NotificationType.ERROR, errorMessage);
            })
            .finally(() => {
                isLoading = false;
            });
    }
</script>

<Modal
    bind:show={showRemoveFraudModal}
    on:close={closeModal}
    title={$_("order.details.removeFraudTitle", { values: { orderId: orderID } })}
>
    <p class="mb-4">{$_("order.details.blockRulesRemovedText")}</p>

    <label for="remove-fraud-lagel-comment" class="text-sm mt-6 mb-2"
        >{$_("riskManagement.fraud.comments")} ({$_("components.required")})</label
    >
    <textarea
        id="remove-fraud-lagel-comment"
        bind:value={comment}
        rows="4"
        class:border-Border-Semantic-Error-Secondary={commentError}
        class:bg-Background-Interactive-Semantic-Error-Hover-Secondary={commentError}
        placeholder={$_("components.descriptionPlaceholder")}
        class="w-full border rounded-sm border-Border-General-Secondary p-2 text-sm"
    />
    {#if commentError}
        <p class="text-sm text-Content-Semantic-Error-Primary">{commentError}</p>
    {/if}

    <div
        class="mt-8 text-sm flex flex-row font-medium px-2 pb-4 py-6 bg-Background-Semantic-Info-Tertiary"
    >
        <CheckboxItem
            statusItem={{
                name: "",
                value: $_("order.details.confirm_remove_label_text")
            }}
            bind:isChecked
            isInvalid={Boolean(isCheckedError)}
        />
    </div>
    {#if isCheckedError}
        <p class="text-sm mt-1 flex items-center text-Content-Semantic-Error-Primary">
            <Fa
                class="mr-1"
                icon={faCircleExclamation}
                color={"var(--ctnSemanticErrorPrimary)"}
            />{isCheckedError}
        </p>
    {/if}

    <div slot="footer" class="flex gap-2 mt-14">
        <Button
            size="md"
            variant="secondaryGray"
            content={$_("components.cancel")}
            on:click={() => (showRemoveFraudModal = false)}
        />
        <Button
            size="md"
            variant="destructivePrimary"
            loading={isLoading}
            content={$_("components.remove")}
            on:click={submitRemoveFraudLabel}
        />
    </div>
</Modal>
