<script lang="ts">
    import { _ } from "svelte-i18n";
    import moment from "moment";
    import Fa from "svelte-fa";
    import { Statistic } from "@two-ui/core";

    import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
    import environment from "@/services/environment";
    import MetaWrapper from "@/components/metadata/MetaWrapper.svelte";
    import ToolTip from "@/components/tooltip/ToolTip.svelte";
    import type {
        IDashboardData,
        IMerchantPeriodFulfilledOverviewResponseSchema,
        MerchantPeriodBuyerOverview
    } from "@/static/types";
    import { account, showAccountAlert, signupComplete } from "@/store/merchant/account.store";
    import { dashboard } from "@/store/merchant/dashboard.store";
    import utils, { type CurrencyFormatOptions } from "@/utilsV2/currency";
    import Chart from "./Chart.svelte";
    import { DASHBOARD_EXCEL_DOWNLOAD } from "@/static/endPoints";
    import { getBaseUrl } from "@/utils/functions";
    import PageHeader from "@/components/pageHeader/PageHeader.svelte";
    import * as helpLinks from "@/services/helpCentreService";

    const helpMenuLinks = {
        [$_("helpLinks.portalTour")]: helpLinks.PORTAL_TOUR,
        [$_("helpLinks.merchantFaq")]: helpLinks.MERCHANT_FAQ,
        [$_("helpLinks.paymentFaq")]: helpLinks.PAYMENT_FAQ,
        [$_("helpLinks.aboutTwo", { values: { brandName: environment.branding.displayName } })]:
            environment.branding.aboutLink
    };
    import DashboardFilter from "./DashboardFilter.svelte";

    let dashboardData: IDashboardData = {};
    let startDate;
    let endDate;
    let avgOrderValue: number = 0;
    let creditApproval: number = 0;
    let totalSales: number = 0;
    let fulfilmentRate: number = 0;
    let fulfilledOrderCount: number = 0;
    let uniqueBuyers: number = 0;
    let verificationRate: number = 0;
    let totalRefunds: number = 0;

    let incAvgOrderValue: number | "∞" = 0;
    let inctotalSales: number | "∞" = 0;
    let incOrderPlaced: number | "∞" = 0;

    //---------------------------------------
    // Functions
    //---------------------------------------
    const formatMoney = (money: string | number, options?: CurrencyFormatOptions) =>
        utils.formatCurrency(dashboardData?.fulfilled_overview?.currency)(money, options);

    //---------------------------------------
    // Reactive blocks
    //---------------------------------------

    // Subscribe to merchant account data
    const getPercentagePrefix = (value: number) => (value > 0 ? "+" : "");

    // Subscribe to dashboard data
    $: if ($dashboard?.fulfilled_overview) {
        dashboardData = $dashboard;
        creditApproval = roundToInt(dashboardData.buyer_overview.buyer_credit_approval_rate);
        useFulfillmentData(dashboardData.fulfilled_overview, dashboardData.buyer_overview);
    }

    const roundToInt = (value) => Math.round(Number(value || "0"));

    function downloadDashboardOrders() {
        const query =
            "?" +
            new URLSearchParams({
                end_date: endDate.toDateString(),
                start_date: startDate.toDateString()
            }).toString();

        window.location.href = `${getBaseUrl()}${DASHBOARD_EXCEL_DOWNLOAD}${query}`;
    }

    const handlePercentage = (value: string | number) => {
        if (value !== "∞") {
            value = Number(value || "0") * 100;
        }
        return value;
    };
    function useFulfillmentData(
        fulfilledData: IMerchantPeriodFulfilledOverviewResponseSchema,
        buyerData: MerchantPeriodBuyerOverview
    ) {
        avgOrderValue = Number(fulfilledData.average_fulfilled_order_value);
        totalSales = Number(fulfilledData.total_fulfilled_order_value);
        totalRefunds = Number(fulfilledData.total_fulfilled_order_refund_value);
        fulfilmentRate = roundToInt(buyerData.orders_fulfilled_rate);
        fulfilledOrderCount = Number(fulfilledData.total_fulfilled_order_count);
        uniqueBuyers = buyerData.unique_buyer_count;
        verificationRate = roundToInt(buyerData.buyer_conversion_rate);
        incAvgOrderValue = handlePercentage(fulfilledData.average_fulfilled_order_value_increase);
        inctotalSales = handlePercentage(fulfilledData.total_fulfilled_order_value_increase);
        incOrderPlaced = handlePercentage(fulfilledData.total_fulfilled_order_count_increase);
    }
</script>

<!--  divitis to get rid of here -->
<MetaWrapper title={$_("dashboard.pageHeader.title")}>
    <div class="page-container">
        {#if $signupComplete}
            <PageHeader
                title={$_("dashboard.pageHeader.title")}
                showAccountAlert={$showAccountAlert}
                newUser={!$account?.flags?.has_made_order}
                {helpMenuLinks}
            >
                <span
                    slot="cta"
                    class={"cursor-pointer link-text hover:text-main"}
                    on:click={downloadDashboardOrders}
                    on:keypress={downloadDashboardOrders}
                >
                    <p>{$_("dashboard.pageHeader.downloadCta")}</p>
                </span>
            </PageHeader>
        {:else}
            <PageHeader
                title={$_("dashboard.pageHeader.title")}
                showAccountAlert={$showAccountAlert}
                newUser={!$account?.flags?.has_made_order}
            />
        {/if}
        <div class="content-container">
            <div class="filter-container">
                <DashboardFilter bind:endDate bind:startDate />
            </div>
            <section id="cards" class="mx-8 mt-6 grid grid-cols-3 gap-4">
                <Statistic
                    id="fulfilled-orders-total"
                    tooltipText={$_("dashboard.fulfilledOrders.tooltip")}
                    text={$_("dashboard.fulfilledOrders.title")}
                    value={formatMoney(totalSales - Math.abs(totalRefunds))}
                    textClasses="font-semibold"
                >
                    <span
                        class="change-value"
                        class:increased={inctotalSales === "∞" || inctotalSales >= 0}
                        class:decreased={typeof inctotalSales === "number" && inctotalSales < 0}
                    >
                        {#if typeof inctotalSales === "string"}
                            ∞%
                        {:else}
                            {getPercentagePrefix(inctotalSales)}{Math.round(
                                inctotalSales
                            ).toLocaleString()}%
                        {/if}
                        <span class="ctn-general-tertiary font-normal">
                            {$_("dashboard.fulfilledOrders.percentageChange", {
                                values: {
                                    days: moment(endDate).diff(moment(startDate), "days") + 1
                                }
                            })}
                        </span>
                    </span>
                </Statistic>
                <Statistic
                    id="fulfilled-orders-avg"
                    tooltipText={$_("dashboard.avgOrderValue.tooltip")}
                    text={$_("dashboard.avgOrderValue.title")}
                    value={formatMoney(avgOrderValue)}
                    textClasses="font-semibold"
                >
                    <span
                        class="change-value"
                        class:increased={incAvgOrderValue === "∞" || incAvgOrderValue >= 0}
                        class:decreased={typeof incAvgOrderValue === "number" &&
                            incAvgOrderValue < 0}
                    >
                        {typeof incAvgOrderValue === "string"
                            ? "∞%"
                            : `${getPercentagePrefix(incAvgOrderValue)}${Math.round(
                                  incAvgOrderValue
                              ).toLocaleString()}%`}
                    </span>
                    {$_("dashboard.avgOrderValue.percentageChange", {
                        values: {
                            days: moment(endDate).diff(moment(startDate), "days") + 1
                        }
                    })}
                </Statistic>
                <Statistic
                    id="fulfilled-orders-count"
                    tooltipText={$_("dashboard.fulfilments.tooltip")}
                    text={$_("dashboard.fulfilments.title")}
                    value={fulfilledOrderCount}
                    textClasses="font-semibold"
                >
                    <span
                        class="change-value"
                        class:increased={incOrderPlaced === "∞" || incOrderPlaced >= 0}
                        class:decreased={typeof incOrderPlaced === "number" && incOrderPlaced < 0}
                        >{getPercentagePrefix(incOrderPlaced)}{incOrderPlaced === "∞"
                            ? incOrderPlaced
                            : Math.round(incOrderPlaced).toLocaleString()}%
                    </span>
                    {$_("dashboard.fulfilments.percentageChange", {
                        values: {
                            days: moment(endDate).diff(moment(startDate), "days") + 1
                        }
                    })}
                </Statistic>
            </section>
            <div class="text-slight-dark main-content">
                <div class="chart_container p-4 pr-8">
                    <Chart
                        graphData={$dashboard?.report_graph_data}
                        currenciesUsed={$dashboard?.currencies_used}
                    />
                </div>
                <div class="card-long">
                    <div id="main-container" class="flex flex-row justify-between h-full">
                        <div class="card-half">
                            <div class="card-quadrant">
                                <div class="card-quadrant-title">
                                    <p class="ctn-general-tertiary font-normal">
                                        {$_("dashboard.uniqueCustomers.title")}
                                    </p>
                                    <span class="ml-2 info-icon">
                                        <ToolTip
                                            width="250px"
                                            position="left-down"
                                            placement="start"
                                            content={$_("dashboard.uniqueCustomers.tooltip", {
                                                values: {
                                                    branding: environment.branding.displayName
                                                }
                                            })}
                                        >
                                            <Fa
                                                size="sm"
                                                icon={faCircleQuestion}
                                                color="var(--primary-500)"
                                            />
                                        </ToolTip>
                                    </span>
                                </div>

                                <p class="text-3xl mt-3 main-values">{uniqueBuyers}</p>
                            </div>

                            <div class="card-quadrant">
                                <div class="card-quadrant-title">
                                    <p class="ctn-general-tertiary font-normal">
                                        {$_("dashboard.fulfilmentRate.title")}
                                    </p>
                                    <span class="ml-2 info-icon">
                                        <ToolTip
                                            width="250px"
                                            position="left-down"
                                            placement="start"
                                            content={$_("dashboard.fulfilmentRate.tooltip")}
                                        >
                                            <Fa
                                                size="sm"
                                                icon={faCircleQuestion}
                                                color="var(--primary-500)"
                                            />
                                        </ToolTip>
                                    </span>
                                </div>
                                <p class="text-3xl mt-3 w-full main-values">
                                    {fulfilmentRate}%
                                </p>
                            </div>
                        </div>
                        <div class="card-half custom-border-l">
                            <div class="card-quadrant">
                                <div class="card-quadrant-title">
                                    <p class="ctn-general-tertiary font-normal">
                                        {$_("dashboard.conversionRate.title")}
                                    </p>
                                    <span class="ml-2 info-icon">
                                        <ToolTip
                                            width="250px"
                                            position="left-down"
                                            placement="start"
                                            content={$_("dashboard.conversionRate.tooltip")}
                                        >
                                            <Fa
                                                size="sm"
                                                icon={faCircleQuestion}
                                                color="var(--primary-500)"
                                            />
                                        </ToolTip>
                                    </span>
                                </div>

                                <p class="text-3xl mt-3 w-full main-values">
                                    {verificationRate}%
                                </p>
                            </div>
                            <div class="card-quadrant">
                                <div class="card-quadrant-title">
                                    <p class="ctn-general-tertiary font-normal">
                                        {$_("dashboard.creditApproval.title")}
                                    </p>
                                    <span class="ml-2 info-icon">
                                        <ToolTip
                                            width="250px"
                                            position="left-down"
                                            placement="start"
                                            content={$_("dashboard.creditApproval.tooltip")}
                                        >
                                            <Fa
                                                size="sm"
                                                icon={faCircleQuestion}
                                                color="var(--primary-500)"
                                            />
                                        </ToolTip>
                                    </span>
                                </div>
                                <p class="text-3xl mt-3 w-full main-values">
                                    {creditApproval}%
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</MetaWrapper>

<style lang="scss">
    .link-text {
        color: var(--ctnInteractiveSemanticHighlightDefault);
        font-weight: 500;
    }

    .card-long {
        padding: 24px;
        border-radius: 8px;
        background-color: #fff;
        width: 100%;
        font-weight: 600;
        min-height: 50%;
        border-top: 0;
        border-left: 1px solid var(--gray-100);
        border-right: 1px solid var(--gray-100);
        border-bottom: 1px solid var(--gray-100);
        grid-column: span 2;
        height: fit-content;
    }

    .main-values {
        color: var(--ctnGeneralPrimary);
    }

    .chart_container {
        min-height: 400px;
        grid-column: span 4;
        border-radius: 8px;
        background-color: #fff;
        width: 100%;
        font-weight: 600;
        height: auto;
        border: 1px solid var(--gray-100);
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
    }

    /* normal border-l does not seem to be working */
    .custom-border-l {
        border-left-width: 1px;
    }

    .card-quadrant-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 0.5rem;
        align-items: center;
    }
    .card-quadrant {
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
        justify-content: space-between;
        height: 50%;
        .info-icon {
            visibility: hidden;
        }
        &:hover .info-icon {
            visibility: visible;
        }
    }

    #main-container .card-half:nth-child(2) {
        padding-left: 20px;
    }
    .main-content {
        display: grid;
        grid-gap: 1.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 1.5rem;
        display: grid;
        grid-template-columns: repeat(6, 1fr); /* Define a 6-column grid */
    }

    .filter-container {
        margin: 1.5rem 2rem 0;
        display: flex;
        gap: 1rem;
    }

    .change-value {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        &.increased {
            color: var(--ctnSemanticSuccessPrimary);
        }
        &.decreased {
            color: var(--ctnSemanticErrorPrimary);
        }
    }
</style>
