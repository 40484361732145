import type { RelativeUrl } from "../services/http/config";
import type { CountryCode } from "./types";
const baseUrl = import.meta.env.VITE_BASE_URL;
const companySearchGB = import.meta.env.VITE_COMPANY_SEARCH_GB;
const companySearchNO = import.meta.env.VITE_COMPANY_SEARCH_NO;
const companySearchSE = import.meta.env.VITE_COMPANY_SEARCH_SE;

const getBaseUrl = (): string => {
    const hostname = window.location.hostname;
    const envMap: { [key: string]: (env: string, domain: string) => string } = {
        "^portal\\.(.*?)\\.?(two\\.inc|achterafbetalen\\.co|achterafbetalen\\.abnamro\\.nl)$": (
            env,
            domain
        ) => `https://api${env ? "." + env : ""}.${domain}`,
        "run\\.app$": () => "https://api.staging.two.inc"
    };

    for (const [pattern, urlFunction] of Object.entries(envMap)) {
        const match = new RegExp(pattern).exec(hostname);
        if (match) {
            const [, env = "", domain = "two.inc"] = match;
            return urlFunction(env, domain);
        }
    }

    return "http://localhost:8080";
};

export const BASE_URL: string = baseUrl || getBaseUrl();
export const DOCS_URL = "https://docs.two.inc";
export const AUTH_LOGIN = "/v1/user/login";
export const GET_MERCHANT_ORDER = "/v1/portal/merchant/orders";
export const GET_GROUPED_ORDERS = "/billing/v1/statements";
export const API_KEY = "/v1/portal/merchant/api_key";
export const SECONDARY_API_KEY = "/v1/portal/merchant/secondary_api_key";
export const ADD_USER = "/user/add";
export const UPDATE_PASSWORD = "/update-password";
export const SEND_CONFIRM_EMAIL = "/sendconfimremail";
export const CREATE_PASSWORD = "/createpassword";
export const AUTH_SIGNUP = "/user/sign-up";
export const MERCHANT_START_LOGIN = "/v1/portal/login/merchant";
export const COMPLETE_VERIFY = "/verification/v1/complete";
export const TRIGGER_VERIFY = "/verification/v1/start";
export const EMAIL_VERIFICATION = "/verification/v1/complete";
export const GET_CUSTOMER = "/v1/portal/merchant/customers";
export const GET_CUSTOMER_BETA = "/v1/portal/merchant/customers-beta";
export const UPDATE_PAYMENT_TERMS = "/v1/portal/merchant/customer_settings";
export const ADD_PAYMENT_TERMS = "/v1/portal/merchant/payment_terms";
export const GET_STATEMENT = "/v1/portal/merchant/statements";
export const GET_STATEMENT_DOWNLOAD = "/v1/portal/merchant/statement";
export const GET_PAYMENT_TERMS = "/v1/portal/merchant/payment_terms";
export const GET_ACCOUNT = "/v1/portal/merchant";
export const GET_TEAM = "/v1/portal/merchant/user";
export const GET_ALL_REPAYMENT_PLANS = "/v1/portal/merchant/instalments/plan";
export const GET_REPAYMENT_PLAN_DETAILS = (planId: string): string => {
    return `${GET_ALL_REPAYMENT_PLANS}/${planId}`;
};
export const GET_REPAYMENT_PLAN_INVOICE_URL = (invoiceId: string): string => {
    return `/v1/portal/merchant/instalments/invoice/${invoiceId}/pdf`;
};
export const GET_ORDER_SUMMARY = "/v1/portal/merchant/secondary_order_summary";
export const UPDATE_INVOICE_STATUS = (order_id: string): string => {
    return `/v1/portal/merchant/order/${order_id}/payment_status`;
};
export const UPDATE_INVOICE_EMAIL = (order_id: string): string => {
    return `/v1/portal/merchant/order/${order_id}/update_invoice_email`;
};
export const MERCHANT_LOG_OUT = "/v1/portal/logout/merchant";
export const BUYER_LOG_OUT = "/v1/portal/logout/buyer";
export const REFUND_ORDER = (order_id: string, lang: string): string => {
    return `/v1/portal/merchant/order/${order_id}/refund?lang=${lang}`;
};
export const CANCEL_ORDER = (order_id: string): string => {
    return `/v1/portal/merchant/order/${order_id}/cancel`;
};

export const CANCEL_REMAINING_ORDER = (order_id: string): string => {
    return `/v1/order/${order_id}/fulfillments/complete_partial`;
};

export const CANCEL_MI_ORDER = (order_id: string): string => {
    return `/v1/portal/mi/order/${order_id}/cancel`;
};

export const CREATE_USER_MERCHANT = "/v1/portal/merchant/user";
export const USER_MERCHANT = (user_id: string): string => {
    return `/v1/portal/merchant/user/${user_id}`;
};
export const FULFILL_ORDER = (order_id: string, lang: string): string => {
    return `/v1/portal/merchant/order/${order_id}/fulfill?lang=${lang}`;
};
export const ORDER_MAIN = (order_id: string): string => {
    return `/v1/order/${order_id}`;
};
export const PARTIALLY_FULFILL_ORDER = (order_id: string): string => {
    return `/v1/order/${order_id}/fulfillments`;
};
export const FULFILLED_ENDPOINT = (order_id: string): string => {
    return `/v1/order/${order_id}/fulfilled`;
};

export const M_ONBOARDING = "v1/monboarding";

const getSearchUrl = (countryCode: string): string => {
    let searchUrl = `https://${countryCode}.search.staging.two.inc`;
    switch (window.location.hostname) {
        case "portal.two.inc":
        case "sandbox.portal.two.inc":
        case "demo.portal.two.inc":
            searchUrl = `https://${countryCode}.search.two.inc`;
            break;
    }
    return searchUrl;
};

export const COMPANY_SEARCH_GB = companySearchGB || getSearchUrl("gb");
export const COMPANY_SEARCH_NO = companySearchNO || getSearchUrl("no");
export const COMPANY_SEARCH_SE = companySearchSE || getSearchUrl("se");
export const COMPANY_SEARCH_CHECKOUT_API = (countryCode: string | CountryCode) =>
    BASE_URL + `/v1/${countryCode}/companies`;

export const VERIFICATION_CONFIG = "/v1/portal/merchant/verification_config";
export const SET_VERIFICATION_CONFIG = "/v1/portal/merchant/create_verification_config";

// onboarding business customers endpoints
export const CREATE_BUSINESS_CUSTOMER = (mid: string): string => {
    return `/v1/merchant/${mid}/customer`;
};

export const GET_BUSINESS_CUSTOMER = (mid: string, cid: string): string => {
    return `/v1/merchant/${mid}/customer/${cid}`;
};

export const UPDATE_BUSINESS_CUSTOMER = (mid: string, cid: string): string => {
    return `/v1/merchant/${mid}/customer/${cid}`;
};

// onboarding business customers' users
export const CREATE_CUSTOMER_USER = (mid: string, cid: string): string => {
    return `/v1/merchant/${mid}/customer/${cid}/user`;
};

export const TRIGGER_VERIFY_BILLING_EMAIL = (mid: string, cid: string): string => {
    return `/v1/merchant/${mid}/customer/${cid}/verify_billing_email`;
};

export const VERIFY_BILLING_EMAIL = "/v1/verify/email";

export const GET_DASHBOARD = "/v1/portal/merchant/dashboard";
export const DASHBOARD_EXCEL_DOWNLOAD = "/v2/portal/merchant/xlsx_orders";
export const DOWNLOAD_GROUPED_STATEMENT_PDF = "/v2/portal/merchant/grouped_statement_pdf";
export const GET_CUSTOMER_USER = (mid: string, cid: string, uid: string): string => {
    return `/v1/merchant/${mid}/customer/${cid}/user/${uid}`;
};

export const POST_PAYMENT_INITIATION_INTENT = "/v1/payment-initiation/intent";

export const GET_PAYMENT_INITIATION_INSTITUTIONS = "/v1/payment-initiation/institutions";

export const POST_PAYMENT_INITIATION_AUTHORIZATION = "/v1/payment-initiation/authorization-request";

export const POST_PAYMENT_REQUEST = "/v1/payment-initiation/payment-request";

export const GET_PAYMENT_STATUS = "/v1/payment-initiation/payment-request/status";

export const GET_PAYMENT_FAILURE_RESPONSE = "/v1/payment-initiation/auth-request/failure";

export const GET_AUTH_STATUS = "/v1/payment-initiation/state";

export const CREATE_NEW_ORDER = "/v1/portal/mi/order";

export const CREATE_NEW_ORDER_WITH_FRAUD_CHECK = "/v1/order";

export const NEW_ORDER_INTENT = "/v1/order_intent";

export const GET_QUOTES = "/v1/mi/orders";

export const GET_ALL_ORDERS = "/v1/portal/merchant/all_orders";

export const MERCHANT_UPDATE = "/v1/portal/merchant";

export const MERCHANT_SIGNUP = (application_id: string): string =>
    `/v1/monboarding/${application_id}`;

export const GET_ALL_INVOICES = (order_id: string): string => {
    return `/v1/portal/merchant/order/${order_id}/fulfillments`;
};

export const GET_INVOICE_DETAILS = (order_id: string): string => {
    return `/v1/order/${order_id}/invoice_details`;
};

export const ORDER_INVOICE = (invoice_id: string): string => {
    return `/v1/invoice/${invoice_id}`;
};

export const DOWNLOAD_BULK_PDFS = (queryString: string): string => {
    return `/v1/invoice/pdfs?${queryString}`;
};

export const GET_CREDIT_LIMIT = "/v1/portal/merchant/buyer_credit_limit";

export const GET_COMBINED_CREDIT_LIMIT = (countryCode, buyersOrgNumber) =>
    `/v1/portal/merchant/customer/${countryCode}/${buyersOrgNumber}/combined_credit_limit`;

export const GET_CREDIT_LIMIT_V2 = "/v2/portal/merchant/buyer_credit_limit";

export const GET_SVIX_APP_PORTAL_URL = "/v1/get_svix_app_portal_url";

export const GET_ORDER_DETAILS = (order_id: string): string => {
    return `/v1/portal/merchant/order/${order_id}`;
};

export const GET_LATENT_ORDER_DETAILS = (order_id: string): string => {
    return `/v1/portal/mi/order/${order_id}`;
};

export const GET_MERCHANT_RECOURSE_LIMIT = "/v1/portal/merchant/recourse_limit";
export const MERCHANT_BUYER_RECOURSE_LIMIT = "/v1/merchant/buyer_recourse_limit";

export const MERCHANT_BUYER_RECOURSE_LIMIT_MAX_AMOUNT = (
    buyer_country_code: string,
    buyer_organization_number: string
): string => {
    return `${MERCHANT_BUYER_RECOURSE_LIMIT}/${buyer_country_code}/${buyer_organization_number}/max_allowed`;
};

export const GET_RECENT_PAYOUTS: RelativeUrl = "/v1/portal/merchant/settlements";
export const GET_PROVIDER_BALANCES: RelativeUrl = "/v1/portal/merchant/settlements/pending";
export const GET_PENDING_PAYOUTS_REPORT: RelativeUrl =
    "/v1/portal/merchant/settlements/pending/statement";
export const GET_SETTLEMENT_REPORT_BY_SETTLEMENT_ID = (
    fileType: string,
    settlementId?: string
): string => {
    let relativePath = `/v1/portal/merchant/statement-by-settlement-id?file_type=${fileType}`;
    if (settlementId?.length) {
        relativePath += `&settlement_id=${settlementId}`;
    }
    return getBaseUrl() + relativePath;
};

// Endpoint for issuing email invite to go through the business registration sign-up on checkout-page
export const SEND_BUSINESS_REGISTRATION_INVITE = "/v1/portal/merchant/business_registration";
export const GET_BUSINESS_REGISTRATION_INVITE = "/v1/portal/merchant/business_registration";

// Credit insights endpoints
export const GET_CREDIT_INSIGHTS = "/v1/portal/merchant/customer/credit/insights";
export const GET_RISK_METRICS_OVERVIEW = "/v1/portal/merchant/risk_metrics/overview";

export const GET_CREDIT_ALERT_DATA = (company_id: string): string => {
    return `/v1/portal/merchant/credit_limit/company/${company_id}/alert/preview`;
};

export const SEND_CREDIT_ALERT = (company_id: string): string => {
    return `/v1/portal/merchant/credit_limit/company/${company_id}/alert`;
};
