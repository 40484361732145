/*
*****************************************************************************
*****************************************************************************
*****************************************************************************

DO NOT ADD NEW FUNCTIONS HERE!!
USE functionsV2.ts instead and make sure to cover it with unit tests
in functionsV2.spec.ts

*****************************************************************************
*****************************************************************************
*****************************************************************************
*/
/* eslint-disable
@typescript-eslint/no-unsafe-assignment,
@typescript-eslint/no-unsafe-call,
@typescript-eslint/no-unsafe-member-access,
@typescript-eslint/restrict-template-expressions,
@typescript-eslint/restrict-plus-operands,
@typescript-eslint/no-explicit-any,
@typescript-eslint/no-unsafe-argument,
@typescript-eslint/no-unsafe-return,
sonarjs/cognitive-complexity,
no-case-declarations */
import { isDate } from "lodash";
import {
    BUSINESS_CUSTOMER_ONBOARDED_MERCHANTS,
    COUNTRIES,
    GB,
    NO,
    SE,
    US,
    UNKNOWN_COUNTRY
} from "../static/constant";
import type countryName from "../static/countryName";
import {
    BASE_URL,
    COMPANY_SEARCH_GB,
    COMPANY_SEARCH_NO,
    COMPANY_SEARCH_SE,
    COMPANY_SEARCH_CHECKOUT_API
} from "../static/endPoints";
import { monthNames, weekNames } from "../static/monthName";
import type {
    CountryCode,
    IAllOrders,
    IBMorder,
    IDashboardChartValue,
    IGroupedStatement,
    IMerchantRecourseLimitConfig,
    IMerchantUnreconciledInvoiceAmount,
    IMerchantUnreconciledInvoiceAmountOutdated
} from "../static/types";
import mGroupedOrdersState from "../store/merchant/groupedOrders.store";

export const DateToFormat = (Date: Date): string => {
    if (Date) {
        const month = `${Date.getMonth() + 1 < 10 ? "0" : ""}` + `${Date.getMonth() + 1}`;
        const day = `${Date.getDate() < 10 ? "0" : ""}` + `${Date.getDate()}`;
        return `${Date.getFullYear()}-${month}-${day}`;
    }
    return "";
};

export const DateWithDaysOffset = (offset: number): Date => {
    const date = new Date();
    date.setDate(date.getDate() + offset);
    return date;
};

export const DateToFormatOnlyDate = (Date: Date): string => {
    if (Date) {
        const _date = Date.getDate();
        return `${_date.toString().length == 1 ? "0" + _date : _date}`;
    }
    return "";
};

export const DateToFormatOnlyMonth = (Date: Date): string => {
    const month: string[] = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
    ];
    if (Date) {
        return `${month[Date.getMonth()]}`;
    }
    return "";
};

export const DateToFormatOnlyYear = (Date: Date): string => {
    if (Date) {
        return `${Date.getFullYear()}`;
    }
    return "";
};

export const DateToUpdatedFormat = (Date: Date): string => {
    if (Date) {
        const month = DateToFormatOnlyMonth(Date);
        const day = `${Date.getDate() < 10 ? "0" : ""}` + `${Date.getDate()}`;
        const time =
            `${Date.getHours() < 10 ? "0" : ""}` +
            `${Date.getHours()}:${Date.getMinutes() < 10 ? "0" : ""}` +
            `${Date.getMinutes()}`;
        return `${time} - ${day} ${month}, ${Date.getFullYear()}`;
    }
    return "";
};

export const DateToUpdatedFormatWithYear = (Date: Date): string => {
    if (Date) {
        const month = DateToFormatOnlyMonth(Date);
        const day = `${Date.getDate() < 10 ? "0" : ""}` + `${Date.getDate()}`;
        return `${day} ${month}, ${Date.getFullYear()}`;
    }
    return "";
};

function createDateString(stringDate: string | Date, seperator: string) {
    if (stringDate) {
        const date = new Date(stringDate);
        const month = `${date.getMonth() + 1 < 10 ? "0" : ""}` + `${date.getMonth() + 1}`;
        const day = `${date.getDate() < 10 ? "0" : ""}` + `${date.getDate()}`;
        return `${day}${seperator}${month}${seperator}${date?.getUTCFullYear()}`;
    }
    return "";
}

const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeString = (str: string) => {
    return str.split(" ").map(capitalize).join(" ");
};

const DateToString = (Date: Date, shortMonths = false): string => {
    if (Date && isDate(Date)) {
        return `${
            shortMonths ? monthNames[Date.getMonth()].slice(0, 3) : monthNames[Date.getMonth()]
        } ${Date.getDate()}, ${Date.getFullYear()}`;
    }
    return "";
};

export enum SORT_TYPE {
    string = "string",
    number = "number",
    date = "date"
}

const objectSort = (data: any[], property: any, ascending: boolean, type?: SORT_TYPE) => {
    data.sort(function (first: any, second: any) {
        // date
        if (
            property === "date" ||
            property === "dueDate" ||
            property === "date_payout" ||
            type === "date"
        ) {
            return ascending
                ? new Date(first[property]).getTime() - new Date(second[property]).getTime()
                : new Date(second[property]).getTime() - new Date(first[property]).getTime();
        }
        // number
        if (type === "number") {
            return ascending
                ? Number(first[property]) - Number(second[property])
                : Number(second[property]) - Number(first[property]);
        }
        // anything else
        if (first[property] == second[property]) {
            return 0;
        } else if (first[property] < second[property]) {
            return ascending ? -1 : 1;
        }
        return ascending ? 1 : -1;
    });
    return data;
};

const listSort = (data: any[], ascending: boolean, type?: SORT_TYPE) => {
    data.sort(function (first: any, second: any) {
        // date
        if (type === "date") {
            return ascending
                ? new Date(first).getTime() - new Date(second).getTime()
                : new Date(second).getTime() - new Date(first).getTime();
        }
        // number
        if (type === "number") {
            return ascending ? Number(first) - Number(second) : Number(second) - Number(first);
        }
        // anything else
        if (first == second) {
            return 0;
        } else if (first < second) {
            return ascending ? -1 : 1;
        }
        return ascending ? 1 : -1;
    });
    return data;
};

const toLongNumberString = (value: any) => {
    const result = Number(value).toLocaleString("de-DE", {
        style: "currency",
        currency: "Nor"
    });

    return result.split("NOR")[0];
};

const groupBy = (key: any) => {
    return function group(array: any[]) {
        return array.reduce((acc, obj) => {
            const property = obj[key];
            acc[property] = acc[property] || [];
            acc[property].push(obj);
            return acc;
        }, {});
    };
};

const tillitChartGroupToArray = (groupedData) => {
    let arrayResult = [];
    for (const grouped in groupedData) {
        let approved = 0;
        let declined = 0;
        groupedData[grouped].map((data) => {
            approved += data.value.approved;
            declined += data.value.declined;
        });
        arrayResult = [
            ...arrayResult,
            {
                date: grouped,
                value: {
                    approved,
                    declined,
                    buyerCount: groupedData[grouped].length
                }
            }
        ];
    }
    return arrayResult;
};

const getLastDays = (days: number) => {
    let newArray = [];
    for (let i = days; i > 0; i--) {
        const date = new Date();
        date.setDate(date.getDate() - i + 1);
        newArray = [...newArray, `${date.getDate()}/${date.getMonth() + 1}`];
    }
    return newArray;
};

const getTimeSpan = (first: Date, second: Date) => {
    return first.getTime() - second.getTime();
};

const getDaySpan = (first: Date, second: Date) => {
    const timeSpan = getTimeSpan(first, second);
    return Math.floor(timeSpan / (1000 * 3600 * 24));
};

const getTimeSpanString = (first: Date, second: Date) => {
    const timespan = getTimeSpan(first, second);

    let day: number | string = Math.floor(timespan / (24 * 3600000));
    let hours: number | string = Math.floor((timespan % (24 * 3600000)) / (60 * 60 * 1000));
    let minutes: number | string = Math.floor(
        ((timespan % (24 * 3600000)) % (60 * 60 * 1000)) / (60 * 1000)
    );
    day !== 0 ? (day = day + " days") : (day = "");
    hours !== 0 ? (hours = hours + " hours") : (hours = "");
    minutes !== 0 ? (minutes = minutes + " minutes") : (minutes = "");
    if (day === "" && hours === "" && minutes === "") return "a few seconds";
    return `${day} ${hours} ${minutes}`;
};

function filterAll(orders: any, filterItems: any): IAllOrders[] {
    return orders.filter((order: IAllOrders): boolean => {
        let isExistable = true;
        Object.entries(filterItems).forEach((item: Array<any>) => {
            switch (item[0]) {
                case "Order value less than":
                    if (Number(order.amount) > item[1]) isExistable = false;
                    break;
                case "Order value more than":
                    if (Number(order.amount) < item[1]) isExistable = false;
                    break;
                default:
                    break;
            }
            return null;
        });
        return isExistable;
    });
}
// type IBMorder = IOrder;
function filtering(orders: any, filterItems: any, mode?: string): IBMorder[] {
    const temp = orders.filter((order: IBMorder): boolean => {
        let isExistable = true;
        Object.entries(filterItems).forEach((item: Array<any>) => {
            switch (item[0]) {
                case "Order ID":
                case "Merchant order ID":
                    if (order.merchantOrderID !== item[1]) isExistable = false;
                    break;
                case "Merchant name":
                    if (order.merchantName !== item[1]) isExistable = false;
                    break;
                case "Merchant name contains":
                    if (order.merchantName.toLowerCase().indexOf(item[1].toLowerCase()) === -1)
                        isExistable = false;
                    break;
                case "Order ID contains":
                case "Merchant order ID contains":
                    if (order.merchantOrderID.indexOf(item[1]) === -1) isExistable = false;
                    break;
                case "Customer":
                    if (order.comName !== item[1]) isExistable = false;
                    break;
                case "Customer contains":
                    if (order.comName.toLowerCase().indexOf(item[1].toLowerCase()) === -1) {
                        isExistable = false;
                    }
                    break;
                case "Statement number":
                    const statementId = mGroupedOrdersState.groupedOrders.actions.getStatementID(
                        item[1]
                    );
                    if (order.billing_statement_id !== statementId) isExistable = false;
                    break;
                case "Status is":
                    if (mode === "buyer") {
                        let status: string = order.status;
                        if (status === "UNVERIFIED") {
                            status = "Unconfirmed";
                        } else if (status === "VERIFIED") {
                            status = "Confirmed";
                        } else {
                            status = status[0] + status.slice(1).toLowerCase();
                        }
                        if (!item[1].includes(status)) isExistable = false;
                    } else {
                        if (item[1][0] === "PARTIALLY REFUNDED") {
                            if (!(order.status === "REFUNDED" && order.payable_amount > 0))
                                isExistable = false;
                        } else if (item[1][0] === "REFUNDED") {
                            if (!(order.status === "REFUNDED" && !(order.payable_amount > 0)))
                                isExistable = false;
                        } else {
                            if (!item[1].includes(order.status)) isExistable = false;
                        }
                    }
                    break;
                case "Store is":
                    if (!item[1].includes(order.merchant)) isExistable = false;
                    break;
                case "Order value less than":
                case "Amount less than":
                    if (Number(order.amount) > item[1]) isExistable = false;
                    break;
                case "Order value more than":
                case "Amount more than":
                    if (Number(order.amount) < item[1]) isExistable = false;
                    break;
                case "Purchase date":
                    const [startDate, endDate] = item[1]
                        .split(" - ")
                        .map((value) => new Date(value).getTime());
                    if (
                        new Date(order.purDate).getTime() < startDate ||
                        new Date(order.purDate).getTime() > endDate + 86400000
                    ) {
                        isExistable = false;
                    }
                    break;
                case "Date shipped":
                    const [startShipDate, endShipDate] = item[1]
                        .split(" - ")
                        .map((value) => new Date(value).getTime());
                    if (
                        new Date(order.dueDate).getTime() < startShipDate ||
                        new Date(order.dueDate).getTime() > endShipDate
                    ) {
                        isExistable = false;
                    }
                    break;
                case "Date fulfilled":
                    const [startFulDate, endFulDate] = item[1]
                        .split(" - ")
                        .map((value) => new Date(value).getTime());
                    if (
                        new Date(order.fulDate).getTime() < startFulDate ||
                        new Date(order.fulDate).getTime() > endFulDate + 86400000
                    )
                        isExistable = false;
                    break;
                case "Invoice due date":
                    const [startDueDate, endDueDate] = item[1]
                        .split(" - ")
                        .map((value) => new Date(value).getTime());
                    if (
                        new Date(order.dueDate).getTime() < startDueDate ||
                        new Date(order.dueDate).getTime() > endDueDate + 86400000
                    )
                        isExistable = false;
                    break;
                default:
                    break;
            }
            return null;
        });
        return isExistable;
    });
    if (mode === "last") {
        return objectSort(temp, "dueDate", true).slice(0, 10);
    }

    return temp;
}

const getBaseUrl = () => {
    return BASE_URL;
};

const getCompanySearchUrl = (countryCode: keyof typeof countryName) => {
    let baseUrl: string;
    switch (countryCode) {
        case NO:
            baseUrl = COMPANY_SEARCH_NO;
            break;
        case GB:
            baseUrl = COMPANY_SEARCH_GB;
            break;
        case SE:
            baseUrl = COMPANY_SEARCH_SE;
            break;
        case US:
            baseUrl = COMPANY_SEARCH_CHECKOUT_API(US);
            break;
        default:
            baseUrl = COMPANY_SEARCH_CHECKOUT_API(countryCode);
    }
    return baseUrl;
};

const toDate = (d: string) => {
    return new Date(d);
};

function getChartGroupLabel(datas: IDashboardChartValue[] = []) {
    let result = [];
    result = datas.map((data: IDashboardChartValue) => {
        const startDate = new Date(data.start_time);
        const endDate = new Date(data.end_time);

        const startDay = `${startDate.getDate()} ${monthNames[startDate.getMonth()].slice(0, 3)}`;
        const startTime = `${startDate.getHours()}:00`;
        const endDay = `${endDate.getDate()} ${monthNames[endDate.getMonth()].slice(0, 3)}`;
        const endTime = `${endDate.getHours()}:00`;

        const span = getDaySpan(new Date(data.end_time), new Date(data.start_time));

        let label = "";

        if (span === 0) {
            label = `${startTime} - ${endTime}`;
        } else if (span === 1) {
            if (datas.length <= 7) label = `${startDay} ${weekNames[startDate.getDay()]}`;
            else label = `${startDay}`;
        } else if (span <= 7) {
            label = `${startDay} - ${endDay}`;
        } else {
            label = `${monthNames[startDate.getMonth()]}`;
        }

        return label;
    });

    return result;
}
const removeSortCodeMask = (sortCode: string): string => {
    return sortCode.split("-").join("");
};

function groupedOrdersFiltering(
    groupedOrders: any,
    filterItems: any,
    mode?: string
): IGroupedStatement[] {
    const temp = groupedOrders.filter((groupedOrder: IGroupedStatement): boolean => {
        let isExistable = true;
        Object.entries(filterItems).forEach((item: Array<any>) => {
            switch (item[0]) {
                case "Company":
                    if (groupedOrder.buyer_company_name.toLowerCase() !== item[1].toLowerCase())
                        isExistable = false;
                    break;
                case "Company contain":
                    if (
                        //  this may not work if there are different buyers for the same statement, need to check
                        groupedOrder.buyer_company_name
                            .toLowerCase()
                            .indexOf(item[1].toLowerCase()) === -1
                    )
                        isExistable = false;
                    break;
                case "Start Date":
                    const startDate: number = new Date(item[1].split(" -- ")[0]).getTime();
                    const endDate: number = new Date(item[1].split(" -- ")[1]).getTime();
                    if (
                        new Date(groupedOrder.start_date).getTime() < startDate ||
                        new Date(groupedOrder.start_date).getTime() > endDate
                    )
                        isExistable = false;
                    break;
                case "Payment Status":
                    const statusMap = {
                        PAID: "paid",
                        NOT_PAID: "unpaid",
                        PARTIALLY_PAID: "partially paid"
                    };
                    const status = statusMap[groupedOrder.payment_status];
                    if (status.toLowerCase() !== item[1][0].toLowerCase()) isExistable = false;
                    break;
                default:
                    break;
            }
            return null;
        });
        return isExistable;
    });
    if (mode === "last") {
        return objectSort(temp, "dueDate", true).slice(0, 10);
    }

    return temp;
}

const getAllBusinessCustomers = () => {
    return BUSINESS_CUSTOMER_ONBOARDED_MERCHANTS;
};

function getCountry(countryCode: CountryCode) {
    if (countryCode in COUNTRIES) {
        return COUNTRIES[countryCode];
    }
    return UNKNOWN_COUNTRY;
}

function getCurrency(countryCode: CountryCode) {
    return (
        {
            GB: "GBP",
            NO: "NOK",
            SE: "SEK",
            US: "USD",
            ES: "EUR",
            NL: "EUR",
            DE: "EUR",
            FR: "EUR",
            BE: "EUR",
            RO: "EUR",
            AT: "EUR",
            GR: "EUR",
            FI: "EUR",
            PL: "EUR",
            EE: "EUR"
        }[`${countryCode}`] ||
        COUNTRIES[countryCode]?.currency ||
        undefined
    );
}

function mapUnreconciledInvoiceAmounts(
    recourseLimitConfig: IMerchantRecourseLimitConfig
): IMerchantUnreconciledInvoiceAmount[] {
    let data: IMerchantUnreconciledInvoiceAmount[] = [];
    if (recourseLimitConfig.unreconciled_invoice_amounts) {
        // if outdated response
        const amounts: IMerchantUnreconciledInvoiceAmountOutdated[] =
            recourseLimitConfig.unreconciled_invoice_amounts;
        amounts.forEach((amount) => {
            const updatedAmount: IMerchantUnreconciledInvoiceAmount = {
                total_exposure_on_recourse: amount.total_unreconciled_recourse_invoice_amount,
                currency: amount.currency
            };
            data.push(updatedAmount);
        });
    } else {
        data = recourseLimitConfig.merchant_recourse_exposure;
    }

    return data;
}

function getInitialRecourseLimitsFromConfig(recourseLimitConfig: IMerchantRecourseLimitConfig) {
    let recourseFallbackEnabled = false;
    let totalRecourseLimit = "";
    let currentRecourseExposure = "";
    let remainingRecourseLimit = "";
    let recourseCurrency = "";

    const merchantRecourseLimit = recourseLimitConfig?.merchant_recourse_limits?.[0];
    const merchantRemainingRecourseLimits = recourseLimitConfig?.merchant_remaining_recourse_limit;
    const unreconciledInvoiceAmount = mapUnreconciledInvoiceAmounts(recourseLimitConfig);

    if (merchantRecourseLimit || unreconciledInvoiceAmount || merchantRemainingRecourseLimits) {
        recourseCurrency = merchantRecourseLimit?.currency;
        recourseFallbackEnabled = merchantRecourseLimit?.recourse_fallback_enabled;
        totalRecourseLimit = merchantRecourseLimit?.max_amount.toString();
        currentRecourseExposure =
            unreconciledInvoiceAmount
                .filter((x) => x.currency == merchantRecourseLimit?.currency)[0]
                ?.total_exposure_on_recourse.toString() || "0";
        remainingRecourseLimit = (
            merchantRemainingRecourseLimits.filter(
                (x) => x.currency == merchantRecourseLimit?.currency
            )[0]?.remaining_recourse_limit || 0
        ).toString();
    }

    return {
        recourseFallbackEnabled,
        totalRecourseLimit,
        currentRecourseExposure,
        remainingRecourseLimit,
        recourseCurrency
    };
}

function isCompanyEmail(emailAddress) {
    let invalidEmail = false;
    const checkDomains = ["gmail", "yahoo", "hotmail", "outlook", "live"];
    const emailDomain = emailAddress.split("@")[1].split(".")[0];
    if (checkDomains.includes(emailDomain)) {
        invalidEmail = true;
    }
    return invalidEmail;
}

export {
    DateToString,
    objectSort,
    toLongNumberString,
    groupBy,
    getLastDays,
    tillitChartGroupToArray,
    getDaySpan,
    filtering,
    filterAll,
    getBaseUrl,
    getCompanySearchUrl,
    toDate,
    getTimeSpan,
    getTimeSpanString,
    getChartGroupLabel,
    removeSortCodeMask,
    groupedOrdersFiltering,
    createDateString,
    getAllBusinessCustomers,
    getCountry,
    getCurrency,
    mapUnreconciledInvoiceAmounts,
    getInitialRecourseLimitsFromConfig,
    listSort,
    isCompanyEmail
};
