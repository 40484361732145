<script lang="ts">
    import { _ } from "svelte-i18n";
    import { createEventDispatcher } from "svelte";
    import { Button, Textarea } from "@two-ui/core";

    import Modal from "@/components/Elements/Modal.svelte";
    import Radio from "@/components/Elements/Radio.svelte";
    import CheckboxItem from "@/components/checkboxitem/CheckboxItem.svelte";

    export let showModal = false;
    export let orderID = "";

    const dispatch = createEventDispatcher();

    let step = 1;

    const fraudTypeOptions = [
        {
            value: "first-party",
            label: $_("riskManagement.fraud.firstParty")
        },
        {
            value: "third-party",
            label: $_("riskManagement.fraud.thirdParty")
        }
    ];

    const valuesToBlockOptions = [
        {
            name: "all",
            value: $_("riskManagement.fraud.all")
        },
        {
            name: "email",
            value: $_("riskManagement.fraud.email")
        },
        {
            name: "phone_number",
            value: $_("riskManagement.fraud.phone")
        },
        {
            name: "org_number",
            value: $_("riskManagement.fraud.organizationNumber")
        }
    ];

    let fraudtypeSelected = fraudTypeOptions[0];
    let valueTypes: string[] = ["ALL"];

    function updateValueTypes(event) {
        const checkedItem = event.detail.checkedItemKey.toUpperCase();
        const isChecked = event.detail.checked;

        if (isChecked) {
            if (checkedItem === "ALL") {
                valueTypes = ["ALL"];
            } else {
                if (!valueTypes.includes("ALL")) {
                    if (!valueTypes.includes(checkedItem)) {
                        valueTypes.push(checkedItem);
                    }
                    if (
                        valueTypes.includes("EMAIL") &&
                        valueTypes.includes("PHONE_NUMBER") &&
                        valueTypes.includes("ORG_NUMBER")
                    ) {
                        valueTypes = ["ALL"];
                    }
                }
            }
        } else {
            if (checkedItem === "ALL") {
                valueTypes = [];
            } else {
                const index = valueTypes.indexOf(checkedItem);
                if (index !== -1) {
                    valueTypes.splice(index, 1);
                }
                if (valueTypes.length === 0 && valueTypes.includes("ALL")) {
                    valueTypes = ["EMAIL", "PHONE_NUMBER", "ORG_NUMBER"];
                }
            }
        }
    }

    function nextStep() {
        if (step === 1) {
            // TODO - mark order as fraud call here
            step = 2;
            return;
        } else {
            // TODO - block customer call here
            closeModal();
        }
    }

    function closeModal() {
        step = 1;
        showModal = false;
        dispatch("close");
    }
</script>

<Modal
    bind:show={showModal}
    on:close={closeModal}
    title={step === 1
        ? $_("order.details.mark_id_as_fraud", {
              values: { orderID }
          })
        : $_("order.details.block_customer_details")}
>
    {#if step === 1}
        <div>{$_("order.details.marking_as_fraud_will_also_reject")}</div>
    {:else}
        <div>
            <div>{$_("order.details.once_you_block")}</div>
            <section>
                <span>{$_("riskManagement.fraud.fraudType")}</span>
                <div class="grid grid-cols-2 gap-2">
                    {#each fraudTypeOptions as fraudTypeItem}
                        <Radio
                            bind:group={fraudtypeSelected}
                            option={fraudTypeItem}
                            id={fraudTypeItem.label}
                        />
                    {/each}
                </div>
            </section>
            <section>
                <span>{$_("riskManagement.fraud.selectValuesToBlock")}</span>
                <div class="grid grid-cols-2 gap-2">
                    {#each valuesToBlockOptions as valueItem}
                        <CheckboxItem
                            statusItem={valueItem}
                            isChecked={valueTypes.includes(valueItem.name.toUpperCase()) ||
                                valueTypes.includes("ALL")}
                            on:checkedItem={updateValueTypes}
                        />
                    {/each}
                </div>
            </section>
            <section>
                <div class="w-full">
                    <Textarea
                        id="order-notes"
                        label={"Comments"}
                        size="md"
                        style="height: 108px;"
                        placeholder={$_("order.new.orderNotesPlaceholder")}
                        resize="vertical"
                    />
                </div>
            </section>
            <div class="text-Content-General-Tertiary text-sm font-normal mt-4">
                *{$_("errors.allRequired")}
            </div>
        </div>
    {/if}
    <div slot="footer" class="flex gap-2 mt-14">
        <Button
            size="md"
            variant="secondaryGray"
            content={$_("order.new.limits.goBack")}
            on:click={closeModal}
        />
        <Button
            size="md"
            variant="destructivePrimary"
            content={step === 1
                ? $_("order.details.mark_as_fraud")
                : $_("riskManagement.fraud.block")}
            on:click={nextStep}
        />
    </div>
</Modal>

<style lang="scss">
    section {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-size: 14px;
        font-weight: 400;
    }
</style>
