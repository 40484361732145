<script lang="ts">
    import moment from "moment";
    import { onDestroy, onMount } from "svelte";
    import { _ } from "svelte-i18n";
    import { navigate } from "svelte-routing";

    import type { Unsubscriber } from "svelte/store";
    import LoadingWrapper from "../../../components/Elements/LoadingWrapper.svelte";
    import type { CurrencyCode, IRepaymentPlan } from "../../../static/types";
    import { account } from "../../../store/merchant/account.store";
    import {
        actions as planActions,
        plansLoading,
        plans as repaymentPlans
    } from "../../../store/merchant/plans.store";
    import { DateToString } from "../../../utils/functions";
    import utils, { type CurrencyFormatOptions } from "../../../utilsV2/currency";
    import RepaymentPlanStatusToken from "./planDetail/RepaymentPlanStatusToken.svelte";

    const timeZone = "GMT";

    let plans: IRepaymentPlan[] = [];
    let currency: CurrencyCode = "GBP";
    // TODO: implement column in merchant table that specifies what type of merchant it is, so we don't have to hardcode.

    let accountUnsubscribe: Unsubscriber;

    const formatMoney = (money: string | number, options?: CurrencyFormatOptions) =>
        utils.formatCurrency(currency)(money, options);

    $: currency = $account.feeCurrency;

    $: plans = $repaymentPlans;

    onMount(() => {
        accountUnsubscribe = account.subscribe((value: any) => {
            if (value && Object.keys(value).length && !plans.length && !$plansLoading) {
                planActions.getAllPlans("", {
                    errorMessage: $_("plans.api.errorMessage"),
                    errorDescription: $_("plans.api.errorDescription")
                });
            }
        });
    });

    onDestroy(() => {
        accountUnsubscribe();
    });

    function goToPlanDetails(planId: string): void {
        navigate(`/merchant/plans/${planId}`);
    }
</script>

<div data-testid="plans-table">
    <table class="repayment-plans mt-1">
        <thead class="table-head">
            <th>{$_("plans.table.columns.planCreated")}</th>
            <th>{$_("plans.table.columns.planReference")}</th>
            <th>{$_("plans.table.columns.status")}</th>
            <th>{$_("plans.table.columns.customerDetails")}</th>
            <th>{$_("plans.table.columns.fundedBy")}</th>
            <th>{$_("plans.table.columns.totalPlanValue")}</th>
        </thead>
        <tbody>
            {#if $plansLoading}
                <tr class="hover-none">
                    <td colspan="10">
                        <div class="relative h-28">
                            <LoadingWrapper loading={$plansLoading} />
                        </div>
                    </td>
                </tr>
            {:else}
                {#each plans as plan}
                    <tr data-testid="customer-counter" on:click={() => goToPlanDetails(plan.id)}>
                        <td>
                            <div class="pr-3">
                                <div class="normal-text">
                                    {DateToString(new Date(plan.created_ts))}
                                </div>
                                <div class="small-text">
                                    {moment(plan.created_ts).format("HH:mm")}
                                    {timeZone}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="pr-3">
                                <div class="flex items-center">
                                    <p>{plan.reference}</p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="pr-3">
                                <div class="token-wrapper">
                                    <RepaymentPlanStatusToken status={plan.summary_status} />
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="pr-3">
                                <div>
                                    <div class="normal-text">{plan.customer.trade_name}</div>
                                    <div
                                        class="small-text"
                                        style="background-color: transparent; padding: initial;"
                                    >
                                        {plan.customer.email}
                                    </div>
                                </div>
                            </div>
                        </td>

                        <td>
                            <div class="pr-3">
                                <div>
                                    <div class="normal-text">Two</div>
                                    <div
                                        class="small-text"
                                        style="background-color: transparent; padding: initial;"
                                    >
                                        {plan.on_recourse
                                            ? $_("plans.table.data.withRecourse")
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="pr-3">
                                <p>
                                    {formatMoney(plan.total_repayment_amount, {
                                        decimalPlaces: Number.isInteger(plan.total_repayment_amount)
                                            ? 0
                                            : 2
                                    })}
                                </p>
                                {#if plan.invoiced_amount}
                                    <p class="small-text">
                                        {formatMoney(plan.invoiced_amount, {
                                            decimalPlaces: Number.isInteger(plan.invoiced_amount)
                                                ? 0
                                                : 2
                                        })}
                                        {$_("plans.table.data.invoiced")}
                                    </p>
                                {/if}
                            </div>
                        </td>
                    </tr>
                {/each}
            {/if}

            {#if plans.length === 0 && !$plansLoading}
                <tr>
                    <td colspan="10">
                        <div class="w-full flex flex-col items-center pt-14">
                            <img src="/pictures/searchstate.svg" alt="mailbox" />
                            <p class="my-4 font-semibold text-black-dark text-center">
                                {@html $_("customers.table.noActiveCustomers.message")}
                            </p>
                        </div>
                    </td>
                </tr>
            {/if}
        </tbody>
    </table>
</div>

<style lang="scss">
    table.repayment-plans {
        width: 100%;
        background: var(--bgGeneralTertiary);
        border-spacing: 0 2px;

        thead {
            background-color: var(--bgGeneralQuaternary);
        }

        tr {
            height: 4.5rem;
            border-bottom: 1px solid #e4e7ec;
            background: white;
            cursor: pointer;

            &:hover {
                box-shadow: none;
            }

            &:not(.loading-tr):hover {
                background-color: var(--bgInteractiveSemanticHighlightHoverSecondary);
            }
        }

        th {
            cursor: default;
        }

        th,
        td {
            text-align: left;
            color: var(--gray-700);
            font-size: 0.875rem;
            font-weight: 400;
            padding: 1rem 0.5rem;
        }

        th:first-child,
        td:first-child {
            padding-left: 2rem;
        }
    }

    .normal-text {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: var(--ctnGeneralSecondary);
    }

    .small-text {
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: var(--ctnGeneralTertiary);
    }

    .token-wrapper {
        display: inline-block;
        min-width: 120px;
    }
</style>
