<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Button as TwoUIButton } from "@two-ui/core";
    import { slide } from "svelte/transition";

    import Icon from "../../../../components/icons/Icon.svelte";
    import ToolTip from "../../../../components/tooltip/ToolTip.svelte";
    import API from "../../../../services/apiService";
    import { GET_REPAYMENT_PLAN_INVOICE_URL } from "../../../../static/endPoints";
    import type { CurrencyCode } from "../../../../static/types";
    import utils from "../../../../utilsV2/currency";
    import { formatLocaleDateString } from "../../../../utilsV2/helpers";
    import PaymentStatusIcon from "./PaymentStatusIcon.svelte";
    import type { InstalmentStatus, RepaymentPlanDetails } from "./types";
    import RepaymentPlanInstalmentStatusToken from "./RepaymentPlanInstalmentStatusToken.svelte";

    export let planDetails: RepaymentPlanDetails;
    export let showInstalments = false;
    let downloadState = new Map();

    function formatCurrency(amount): string {
        return utils.formatCurrency(planDetails.currency)(amount);
    }

    function toggleInstalments() {
        showInstalments = !showInstalments;
    }

    async function getInvoiceDownloadUrl(invoiceId: string) {
        return await API.get(GET_REPAYMENT_PLAN_INVOICE_URL(invoiceId));
    }

    async function downloadInvoice(instalmentId: string) {
        const instalment = planDetails.instalments.find(
            (instalment) => instalment.id === instalmentId
        );
        const invoiceId = instalment.invoice_id;
        if (!invoiceId) return;
        downloadState.set(instalmentId, "loading");
        downloadState = new Map(downloadState);
        try {
            const downloadUrlResp = await getInvoiceDownloadUrl(invoiceId);
            const downloadUrl = downloadUrlResp.url;
            window.open(downloadUrl, "_blank");
            downloadState.set(instalmentId, "success");
            downloadState = new Map(downloadState);
        } catch (err) {
            downloadState.set(instalmentId, "error");
            downloadState = new Map(downloadState);
        } finally {
            setTimeout(() => {
                // clear if network takes forever
                downloadState.delete(instalmentId);
                downloadState = new Map(downloadState);
            }, 3000);
        }
    }

    function getDownloadUnavailableCopy(status: InstalmentStatus): string {
        var result: string;
        switch (status) {
            case "CANCELLED":
                result = $_(
                    "planDetails.instalmentsCard.tooltips.downloadUnavailablePlanCancelled"
                );
                break;
            default:
                result = $_("planDetails.instalmentsCard.tooltips.downloadUnavailable");
        }
        return result;
    }
</script>

<div class="instalments">
    <div class="main-bar">
        <p class="main-bar-title">{$_("planDetails.instalmentsCard.title")}</p>
        <div class="button-container">
            <button class="collapsible-button" on:click={toggleInstalments}
                >{showInstalments ? "-" : "+"}</button
            >
        </div>
    </div>
    {#if showInstalments}
        <div transition:slide|global>
            <table class="instalments">
                <thead>
                    <th>{$_("planDetails.instalmentsCard.table.headers.number")}</th>
                    <th>{$_("planDetails.instalmentsCard.table.headers.dateIssued")}</th>
                    <th>{$_("planDetails.instalmentsCard.table.headers.dueDate")}</th>
                    <th>{$_("planDetails.instalmentsCard.table.headers.status")}</th>
                    <th>
                        <ToolTip
                            width="250px"
                            position="top"
                            content={$_("planDetails.instalmentsCard.tooltips.customerPayment")}
                        >
                            {$_("planDetails.instalmentsCard.table.headers.customerPayment")}
                        </ToolTip>
                    </th>
                    <th>
                        <ToolTip
                            width="250px"
                            position="top"
                            content={$_("planDetails.instalmentsCard.tooltips.amount")}
                        >
                            {$_("planDetails.instalmentsCard.table.headers.amount")}
                        </ToolTip>
                    </th>
                    <th>{$_("planDetails.instalmentsCard.table.headers.viewInvoice")}</th>
                </thead>
                <tbody>
                    {#each planDetails.instalments as instalment (instalment.id)}
                        <tr>
                            <td>{instalment.invoice_number || "-"}</td>
                            <td>
                                {#if instalment.invoice_issue_date}
                                    {formatLocaleDateString(instalment.invoice_issue_date)}
                                {:else}
                                    -
                                {/if}
                            </td>
                            <td>
                                {#if instalment.invoice_due_date}
                                    {formatLocaleDateString(instalment.invoice_due_date)}
                                {:else}
                                    -
                                {/if}
                            </td>
                            <td>
                                {#if instalment.status}
                                    <div class="token-wrapper">
                                        <RepaymentPlanInstalmentStatusToken
                                            status={instalment.status}
                                        />
                                    </div>
                                {:else}
                                    -
                                {/if}
                            </td>
                            <td>
                                <PaymentStatusIcon
                                    customerPaymentStatus={instalment.customer_payment_status}
                                    instalmentStatus={instalment.status}
                                    invoiceDueDate={instalment.invoice_due_date}
                                />
                            </td>
                            <td>
                                <div class="instalment-amounts">
                                    <div>{formatCurrency(instalment.amount)}</div>
                                    <div class="secondary">
                                        {$_(
                                            "planDetails.instalmentsCard.table.data.parentInvoice",
                                            {
                                                values: {
                                                    amount: formatCurrency(
                                                        instalment.parent_invoice_amount
                                                    )
                                                }
                                            }
                                        )}
                                    </div>
                                </div>
                            </td>
                            <td>
                                {#if instalment?.invoice_id}
                                    <TwoUIButton
                                        variant="tertiaryColor"
                                        content={$_(
                                            "planDetails.instalmentsCard.table.data.downloadAvailable"
                                        )}
                                        on:click={() => downloadInvoice(instalment.id)}
                                        disabled={downloadState.get(instalment.id) === "loading"}
                                        loading={downloadState.get(instalment.id) === "loading"}
                                    >
                                        <Icon
                                            name="file-download"
                                            size={18}
                                            fill="var(--primary-500)"
                                            slot="rightIcon"
                                        />
                                    </TwoUIButton>
                                {:else}
                                    <ToolTip
                                        width="250px"
                                        position="top"
                                        content={getDownloadUnavailableCopy(
                                            planDetails.summary_status
                                        )}
                                    >
                                        <TwoUIButton
                                            variant="tertiaryColor"
                                            content={$_(
                                                "planDetails.instalmentsCard.table.data.downloadUnavailable"
                                            )}
                                            disabled
                                        />
                                    </ToolTip>
                                {/if}
                            </td>
                        </tr>
                    {/each}
                </tbody>
            </table>
        </div>
    {/if}
</div>

<style lang="scss">
    tr {
        height: unset;
    }

    .main-bar {
        display: flex;
        justify-content: space-between;

        .main-bar-title {
            font-weight: 700;
            padding: 1.5rem;
        }
    }

    .button-container {
        margin: auto 1rem;
        display: flex;
        gap: 1rem;
    }

    .collapsible-button {
        border-radius: 8px;
        border: 1px solid var(--gray-300, #d0d5dd);
        background: var(--basic-white, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        width: 38px;
        font-size: 24px;
        color: #344054;
    }

    table.instalments {
        width: 100%;

        th,
        td {
            cursor: default;
            text-align: left;
            color: var(--gray-700);
            font-size: 0.875rem;
            font-weight: 400;
            padding: 1rem 0.5rem;
        }

        th:first-child,
        td:first-child {
            padding-left: 1.5rem;
            padding-right: 0.5rem;
        }

        th:last-child {
            padding-left: 1.25rem;
        }

        td:last-child {
            padding-left: 0.5rem;
        }

        th:last-child,
        td:last-child {
            padding-right: 1.5rem;
            max-width: 70px;
        }

        thead {
            background-color: var(--bgGeneralQuaternary);
            color: var(--ctnGeneralSecondary);
        }

        td div.instalment-amounts {
            display: flex;
            flex-direction: column;
        }
    }

    .secondary {
        font-size: 0.75rem;
    }

    .token-wrapper {
        display: inline-block;
        min-width: 100px;
    }
</style>
