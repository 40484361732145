import { writable } from "svelte/store";

import API from "@/services/apiService";
import { GET_RISK_METRICS_OVERVIEW } from "@/static/endPoints";
import {
    ExposureType,
    LosesType,
    CreditApprovalType,
    FraudLosesType
} from "@/pages/merchant/risk-management/common/dataTypes";
import { CurrencyCode } from "@/static/types";

type OverviewRangeStats = {
    last_0_to_30_days: number;
    last_31_to_60_days: number;
    monthly_change: number | "NA";
};

type OverviewData = {
    currency?: CurrencyCode;
    credit_losses?: LosesType[];
    exposure?: ExposureType[];
    credit_approval?: CreditApprovalType[];
    fraud_losses?: FraudLosesType[];
    tpv?: OverviewRangeStats;
    fraud_prevented?: OverviewRangeStats;
    verified_customers?: OverviewRangeStats;
};

type PhoneItem = { value: string; id: string };

const overviewData = writable<OverviewData>({});
const dataLoaded = writable<boolean>(false);
// fraud rules
const fraudRulesAddNewFlow = writable<"block" | "whitelist" | "">("");
const fraudRulesAddNewStep = writable<number>(1);
const fraudRulesOrgNumber = writable<string>("");
const fraudRulesPhones = writable<PhoneItem[]>([]);
const fraudRulesComment = writable<string>("");

function addPhoneToStore(phoneCode) {
    fraudRulesPhones.update((phones) => [
        ...phones,
        { id: String(new Date().getTime()), value: phoneCode }
    ]);
}

function removePhoneFromStore(id) {
    fraudRulesPhones.update((phones) => phones.filter((phone) => phone.id !== id));
}
const fraudRulesEmails = writable<Array<string>>([""]);
const fraudRulesType = writable<string>("");
const fraudRulesReason = writable<string>("");

let isDataFetched = false;

const fetchOverviewData = (): void => {
    if (!isDataFetched) {
        API.get(GET_RISK_METRICS_OVERVIEW)
            .then((res) => {
                overviewData.set(res);
                dataLoaded.set(true);
                isDataFetched = true;
            })
            .catch((e) => {
                console.error(e);
            });
    }
};

export {
    overviewData,
    dataLoaded,
    fraudRulesAddNewFlow,
    fraudRulesAddNewStep,
    fraudRulesOrgNumber,
    fraudRulesPhones,
    fraudRulesEmails,
    fraudRulesType,
    fraudRulesReason,
    fraudRulesComment,
    fetchOverviewData,
    addPhoneToStore,
    removePhoneFromStore
};
