import environment from "@/services/environment";

const PURCHASE_DATE = "Purchase date";
const CUSTOMER_DETAILS = "Customer details";
const CUSTOMER_DETAILS_CLASS = "customer-details";

const tableHeadersAllOrders = [
    { title: PURCHASE_DATE, clickable: true, type: "date" },
    { title: "Order №", clickable: false, type: "number" },
    { title: "Invoice №", clickable: false, type: "number" },
    { title: "Order status", clickable: false, type: "status" },
    { title: CUSTOMER_DETAILS, clickable: false, type: CUSTOMER_DETAILS_CLASS },
    { title: "Amount", clickable: true, type: "number" }
];

const tableHeadersAllOrdersRecourse = getRecourseHeaders(tableHeadersAllOrders);

const tableHeadersFulfilledOrders = [
    { title: PURCHASE_DATE, clickable: true, type: "date" },
    { title: "Order №", clickable: false, type: "number" },
    { title: CUSTOMER_DETAILS, clickable: false, type: CUSTOMER_DETAILS_CLASS },
    { title: "Invoice №", clickable: false, type: "number" },
    { title: "Payment due date", clickable: false, type: "status" },
    { title: "Amount fulfilled", clickable: true, type: "number" }
];

const tableHeadersFulfilledOrdersRecourse = getRecourseHeaders(tableHeadersFulfilledOrders);

const tableHeadersRefundedOrders = [
    { title: PURCHASE_DATE, clickable: true, type: "date" },
    { title: "Order №", clickable: false, type: "number" },
    { title: "Latest refund date", clickable: false, type: "date" },
    { title: CUSTOMER_DETAILS, clickable: false, type: CUSTOMER_DETAILS_CLASS },
    { title: "Amount", clickable: true, type: "number" },
    { title: "Refunded", clickable: false, type: "number" }
];

const tableHeadersRefundedOrdersRecourse = getRecourseHeaders(tableHeadersRefundedOrders);

function getRecourseHeaders(headersArray) {
    var headers = [...headersArray];

    if (!environment.branding.hideRiskBearer) {
        headers.splice(headersArray.length - 1, 0, {
            title: "Risk bearer",
            clickable: false,
            type: "status",
            tooltip: true
        });
    }
    return headers;
}

export const getTabItems = (tabName: string, $_) => {
    switch (tabName) {
        case "All":
            return {
                tableHeaders: tableHeadersAllOrders,
                tableHeadersRecourse: tableHeadersAllOrdersRecourse,
                title: $_("order.table.tabs.all_orders.title"),
                description: $_("order.table.tabs.all_orders.summary", {
                    values: { productName: environment.branding.productName }
                })
            };
        case "Pending":
            return {
                tableHeaders: [
                    { title: PURCHASE_DATE, clickable: true, type: "date" },
                    { title: "Order №", clickable: false, type: "number" },
                    { title: CUSTOMER_DETAILS, clickable: false, type: CUSTOMER_DETAILS_CLASS },
                    { title: "Action required", clickable: false, tooltip: true },
                    { title: "Amount", clickable: true, type: "number" }
                ],
                title: $_("order.table.tabs.pending_orders.title"),
                description: $_("order.table.tabs.pending_orders.summary")
            };
        case "Ready to fulfill":
            return {
                tableHeaders: [
                    { title: PURCHASE_DATE, clickable: true, type: "date" },
                    { title: "Order №", clickable: false, type: "number" },
                    { title: CUSTOMER_DETAILS, clickable: false, type: CUSTOMER_DETAILS_CLASS },
                    { title: "Amount", clickable: true, type: "number" }
                ],
                title: $_("order.table.tabs.ready_to_fulfil.title"),
                description: $_("order.table.tabs.ready_to_fulfil.summary")
            };
        case "Fulfilled":
            return {
                tableHeaders: tableHeadersFulfilledOrders,
                tableHeadersRecourse: tableHeadersFulfilledOrdersRecourse,
                title: $_("order.table.tabs.fulfilled_orders.title"),
                description: $_("order.table.tabs.fulfilled_orders.summary")
            };
        case "Refunded":
            return {
                tableHeaders: tableHeadersRefundedOrders,
                tableHeadersRecourse: tableHeadersRefundedOrdersRecourse,
                title: $_("order.table.tabs.refunded_orders.title"),
                description: $_("order.table.tabs.refunded_orders.summary")
            };
        case "Cancelled":
            return {
                tableHeaders: [
                    { title: PURCHASE_DATE, clickable: true, type: "date" },
                    { title: "Order №", clickable: false, type: "number" },
                    { title: CUSTOMER_DETAILS, clickable: false, type: CUSTOMER_DETAILS_CLASS },
                    { title: "Contact details", clickable: false },
                    { title: "Amount", clickable: true, type: "number" }
                ],
                title: $_("order.table.tabs.cancelled_orders.title"),
                description: $_("order.table.tabs.cancelled_orders.summary")
            };
        case "Rejected":
            return {
                tableHeaders: [
                    { title: PURCHASE_DATE, clickable: true, type: "date" },
                    { title: "Order №", clickable: false, type: "number" },
                    { title: CUSTOMER_DETAILS, clickable: false, type: CUSTOMER_DETAILS_CLASS },
                    { title: "Contact details", clickable: false },
                    { title: "Amount", clickable: true, type: "number" }
                ],
                title: $_("order.table.tabs.rejected_orders.title"),
                description: $_("order.table.tabs.rejected_orders.summary")
            };
        default:
            return null;
    }
};
