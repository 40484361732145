/* eslint-disable
@typescript-eslint/no-unsafe-assignment,
@typescript-eslint/no-unsafe-call,
@typescript-eslint/no-unsafe-member-access,
@typescript-eslint/restrict-template-expressions,
@typescript-eslint/restrict-plus-operands,
@typescript-eslint/no-explicit-any */

import { writable } from "svelte/store";
import API from "../../services/apiService";
import modalState from "../modals.store";
import notificationState from "../notifications.store";
import {
    CREATE_USER_MERCHANT_SUCCESS,
    UPDATE_USER_MERCHANT_SUCCESS,
    DELETE_USER_MERCHANT_SUCCESS
} from "../../static/modals";
import {
    type IAccount,
    type ISignupAccount,
    type ITeamUser,
    type IBuyerLanguage,
    type IMNewUserConfig,
    NotificationType,
    type IMerchantRecourseLimitConfig,
    type IMerchantFlags
} from "../../static/types";
import { getAllBusinessCustomers } from "../../utils/functions";

import {
    GET_ACCOUNT,
    GET_TEAM,
    CREATE_USER_MERCHANT,
    USER_MERCHANT,
    GET_MERCHANT_RECOURSE_LIMIT,
    MERCHANT_SIGNUP,
    BASE_URL
} from "../../static/endPoints";
import { COUNTRIES } from "../../static/constant";

const userMerchantsUrl = `${BASE_URL}/v1/portal/user/merchants`;

function createAccount() {
    const account = writable<IAccount | ISignupAccount>(<IAccount>{});
    const teamMembers = writable([]);
    const loading = writable(true);
    const loggedInAsMerchant = writable(null);
    const loggedInAsMerchantPortalUser = writable(null);
    const showAccountAlert = writable(false);
    const taxNaming = writable("VAT");
    const recourseLimitConfig = writable<IMerchantRecourseLimitConfig>(undefined);
    const recourseLoading = writable<boolean>(true);
    const signupComplete = writable<boolean>(true);
    const getAccountExecuted = writable<boolean>(false);
    const recourseEnabled = writable<boolean>();
    const userMerchants = writable<Array<any>>(null);

    const setGetTeamLoading = (value: boolean) => {
        loading.set(value);
    };

    const setUserLoading = (value: boolean) => {
        loading.set(value);
    };

    const handleResponseErrors = (err) => {
        let message = "error";
        if (err.response.data) {
            const _error = err.response.data;
            message = _error.error_message;
            if (_error?.error_code == "SCHEMA_ERROR") {
                const errorBody = _error.error_json;
                message = errorBody.length ? errorBody[0].msg : "SCHEMA_ERROR";
            }
            if (_error?.error_code == "500") {
                message = "An unexpected error occurred. Please contact support at hello@two.inc";
            }
        }
        notificationState.actions.create(NotificationType.ERROR, message);
    };

    return {
        account,
        loading,
        loggedInAsMerchant,
        loggedInAsMerchantPortalUser,
        showAccountAlert,
        taxNaming,
        teamMembers,
        recourseLimitConfig,
        recourseEnabled,
        recourseLoading,
        signupComplete,
        getAccountExecuted,
        userMerchants,
        actions: {
            getAccount: () => {
                API.get(GET_ACCOUNT)
                    .then((res: any) => {
                        const data = res;
                        const accountData: IAccount = <IAccount>{};
                        const merchantName = data.profile?.display_name?.toLowerCase();
                        const onboardedMerchants = getAllBusinessCustomers();
                        if (data.status) {
                            handleIncompleteMerchant(accountData as ISignupAccount, data);
                            signupComplete.set(false);
                            recourseLoading.set(false);
                            return;
                        }
                        signupComplete.set(true);
                        accountData.accountName = data.profile?.display_name;
                        accountData.isOnboardedMerchant = onboardedMerchants.includes(merchantName);
                        accountData.logoPath = data.profile?.logo_path;
                        accountData.legalName = data.profile?.legal_name;
                        accountData.nationalID = data.profile?.national_id;
                        accountData.countryCode = data.profile?.country_code;
                        accountData.merchantCountry = COUNTRIES[data.profile?.country_code];
                        accountData.merchantPhoneCountryCode = COUNTRIES[data.profile?.country_code]
                            ? COUNTRIES[data.profile?.country_code].phoneCountryCode
                            : "+351";
                        accountData.email = data.contact?.email;
                        accountData.emailInvoice = data.profile?.email_invoice;
                        accountData.emailErp = data.profile?.email_erp;
                        accountData.phoneInvoice = data.profile?.phone_invoice;
                        accountData.phone = data.contact?.phone;
                        accountData.website = data.contact?.website;
                        accountData.registeredAddress = `${data.contact?.address_lines}
              ${data.contact?.address_postal_code} ${data.contact?.address_city}`;
                        accountData.formattedRegisterdAddress = `${data.contact?.address_lines},
            ${data.contact?.address_postal_code}, ${data.contact?.address_city}`;
                        accountData.bic = data.payment_information?.bank_account?.bic;
                        accountData.iban = data.payment_information?.bank_account?.iban;
                        accountData.bban = data.payment_information?.bank_account?.bban;
                        accountData.organizationName =
                            data.payment_information?.bank_account?.organization_name;
                        accountData.organizationNumber =
                            data.payment_information?.bank_account?.organization_number;
                        accountData.sortCode =
                            data.payment_information?.bank_account?.branch_sort_code;
                        accountData.localAccountNumber =
                            data.payment_information?.bank_account?.local_account_number;
                        accountData.description =
                            data.payment_information?.bank_account?.description;
                        accountData.payoutPlan = data.payment_information?.terms?.payout_plan;
                        accountData.feeCurrency = data.payment_information?.terms?.fee_currency;
                        accountData.standardTerms = data.payment_information?.terms?.term;
                        accountData.fixedFee =
                            data.payment_information?.terms?.fee_currency +
                            " " +
                            data.payment_information?.terms?.fixed_fee_per_order;
                        accountData.transactionFee = Number(
                            data.payment_information?.terms?.percentage_fee_per_order
                        ).toFixed(2);
                        accountData.requestedFinancing = data?.requested_financing;
                        accountData.short_name = data?.short_name;
                        accountData.partner_short_name = data?.partner_short_name;
                        accountData.bank_statement_email = data?.bank_statement_email;
                        accountData.bank_statement_format = data?.bank_statement_format;
                        accountData.merchant_user = data?.merchant_user;
                        accountData.admin_user = data?.admin_user;
                        accountData.fraud_contact = data?.fraud_contact ?? {
                            email: null,
                            phone: null
                        };
                        accountData.documents = data?.documents || [];
                        accountData.payout_accounts =
                            data?.payment_information?.payout_accounts ?? [];
                        const displayName = [
                            data?.merchant_user?.first_name,
                            data?.merchant_user?.last_name
                        ]
                            .filter((el) => {
                                return el != null;
                            })
                            .join(" ")
                            .trim();
                        accountData.is_missing_user_info =
                            data?.merchant_user && (!displayName || !data?.merchant_user?.email);
                        accountData.buyer_country_configurations =
                            data?.buyer_country_configurations;
                        const flags: IMerchantFlags = {
                            cross_border_enabled: data?.flags?.cross_border_enabled,
                            fraud_check_on_order_creation:
                                data?.flags?.fraud_check_on_order_creation,
                            enable_create_order_via_oc: data?.flags?.enable_create_order_via_oc,
                            enabled_leasing_instalments_api:
                                data?.flags?.enabled_leasing_instalments_api,
                            provides_own_invoice_numbers: data?.flags?.provides_own_invoice_numbers,
                            bank_statement_email_is_enabled:
                                data?.flags?.bank_statement_email_is_enabled,
                            api_key_management_enabled: data?.flags?.api_key_management_enabled,
                            has_made_order: data?.flags?.has_made_order,
                            has_billing_accounts: data?.flags?.has_billing_accounts,
                            buyer_fallback_enabled: data?.flags?.buyer_fallback_enabled,
                            merchant_initiated_enabled: data?.flags?.merchant_initiated_enabled,
                            fetch_only_leaf_orders: data?.flags?.fetch_only_leaf_orders,
                            webhooks_enabled: data?.flags?.webhooks_enabled,
                            show_line_item_more_details_on_order_creator:
                                data?.flags?.show_line_item_more_details_on_order_creator,
                            show_settlement_payout_customized_for_kredinor_on_portal:
                                data?.flags
                                    ?.show_settlement_payout_customized_for_kredinor_on_portal,
                            legacy_settlement_ui_portal: data?.flags?.legacy_settlement_ui_portal,
                            show_new_order_details_page: data?.flags?.show_new_order_details_page,
                            enable_fe_partial_fulfillment:
                                data?.flags?.enable_fe_partial_fulfillment,
                            enable_invoice_editing: data?.flags?.enable_invoice_editing,
                            enable_instalment_order_creation:
                                data?.flags?.enable_instalment_order_creation,
                            enable_fe_validate_customer_recourse_limit:
                                data?.flags?.enable_fe_validate_customer_recourse_limit,
                            business_registration_button_enabled:
                                data?.flags?.business_registration_button_enabled,
                            show_risk_management_related_features: false
                        };
                        accountData.flags = flags;
                        account.set(accountData);
                        showAccountAlert.set(accountData.is_missing_user_info);
                        loggedInAsMerchant.set(true);
                        taxNaming.set(determineTaxNaming(accountData.countryCode));
                    })
                    .catch(() => {
                        loggedInAsMerchant.set(false);
                    })
                    .finally(() => {
                        actions.getMerchantPortalUserMerchants();
                        getAccountExecuted.set(true);
                    });
            },
            getMerchantPortalUserMerchants: () => {
                API.get(userMerchantsUrl)
                    .then((response: any[]) => {
                        loggedInAsMerchantPortalUser.set(true);
                        userMerchants.set(response);
                    })
                    .catch(() => {
                        loggedInAsMerchantPortalUser.set(false);
                        userMerchants.set([]);
                    });
            },
            createUser: (data: IMNewUserConfig): void => {
                setUserLoading(true);
                API.post(CREATE_USER_MERCHANT, data)
                    .then(() => {
                        setUserLoading(false);
                        modalState.actions.setModal(null, {});
                        modalState.actions.setModal(CREATE_USER_MERCHANT_SUCCESS, {
                            first_name: data.first_name,
                            last_name: data.last_name,
                            phone_number: data.phone_number,
                            role: data.role
                        });
                    })
                    .catch((error) => {
                        setUserLoading(false);
                        handleResponseErrors(error);
                    });
            },
            getTeam: () => {
                setGetTeamLoading(true);
                let isSignedUp = false;
                let accountData = null;
                account.subscribe((value) => {
                    isSignedUp = !("application_id" in value);
                    accountData = value;
                });
                const teamMembersData: ITeamUser[] = [];
                if (!isSignedUp) {
                    teamMembersData.push(<ITeamUser>{
                        firstName: accountData.first_name || "",
                        lastName: accountData.last_name || "",
                        phoneNumber: accountData.phone || "",
                        email: accountData.email || "",
                        role: "ADMIN"
                    });
                    teamMembers.set(teamMembersData);
                    setGetTeamLoading(false);
                    return;
                }
                API.get(GET_TEAM)
                    .then((res: any) => {
                        for (const member of res) {
                            const teamMemberData: ITeamUser = {};
                            teamMemberData.id = member.id;
                            teamMemberData.firstName = member.first_name || "";
                            teamMemberData.lastName = member.last_name || "";
                            teamMemberData.team = member.team || "";
                            teamMemberData.phoneNumber = member.phone_number || "";
                            teamMemberData.email = member.email || "";
                            teamMemberData.role = member.role || "";
                            teamMemberData.status = member.status || "";
                            teamMembersData.push(teamMemberData);
                        }
                        teamMembers.set(teamMembersData);
                        setGetTeamLoading(false);
                    })
                    .catch(() => {
                        setGetTeamLoading(false);
                        notificationState.actions.create(
                            NotificationType.ERROR,
                            "Team members get failed."
                        );
                    });
            },

            updateUser: (userId: string, data: IMNewUserConfig): void => {
                setUserLoading(true);
                API.patch(USER_MERCHANT(userId), data)
                    .then(() => {
                        setUserLoading(false);
                        modalState.actions.setModal(null, {});
                        modalState.actions.setModal(UPDATE_USER_MERCHANT_SUCCESS, {
                            first_name: data.first_name,
                            last_name: data.last_name,
                            phone_number: data.phone_number,
                            role: data.role
                        });
                    })
                    .catch((error) => {
                        setUserLoading(false);
                        handleResponseErrors(error);
                    });
            },

            deleteUser: (userId: string, data: IMNewUserConfig): void => {
                setUserLoading(true);
                API.delete(USER_MERCHANT(userId), null)
                    .then(() => {
                        setUserLoading(false);
                        modalState.actions.setModal(null, {});
                        modalState.actions.setModal(DELETE_USER_MERCHANT_SUCCESS, {
                            first_name: data.first_name,
                            last_name: data.last_name,
                            phone_number: data.phone_number,
                            role: data.role
                        });
                    })
                    .catch((error) => {
                        setUserLoading(false);
                        handleResponseErrors(error);
                    });
            },

            getMerchantRecourseLimit: () => {
                recourseLoading.set(true);
                API.get(GET_MERCHANT_RECOURSE_LIMIT)
                    .then((res: any) => {
                        const recourseLimit: IMerchantRecourseLimitConfig = res;
                        recourseLimitConfig.set(recourseLimit);
                        recourseEnabled.set(
                            recourseLimit.merchant_recourse_limits.some(
                                (limit) => limit.recourse_fallback_enabled
                            )
                        );
                    })
                    .catch((error) => {
                        if (error.response.data) {
                            const _error = error.response.data;
                            console.error(_error.error_message);
                        }
                        recourseEnabled.set(false);
                    })
                    .finally(() => recourseLoading.set(false));
            },
            updateMerchantSignup: (data: object, application_id: string) => {
                const signupDetailsMap = {
                    phone: "phone_number",
                    phone_invoice: "phone_number_invoice",
                    email_erp: "email_invoice",
                    display_name: "brand_name",
                    standard_terms: "due_in_days"
                };
                Object.entries(signupDetailsMap).forEach(([key, replacementKey]) => {
                    if (data[key]) {
                        data[replacementKey] = data[key];
                        delete data[key];
                    }
                });
                return API.patch(MERCHANT_SIGNUP(application_id), data).then((response) =>
                    handleIncompleteMerchant({} as ISignupAccount, response)
                );
            }
        }
    };
}

const determineTaxNaming = (countryCode) => {
    if (countryCode == "US") return "Tax";
    return "VAT";
};

function handleIncompleteMerchant(accountData: ISignupAccount, response_data) {
    accountData.accountName = response_data.brand_name || "";
    accountData.logoPath = response_data.logo_path;
    accountData.legalName = response_data.company_name;
    accountData.nationalID = response_data.organization_number;
    accountData.countryCode = response_data.country_code;
    accountData.merchantCountry = COUNTRIES[response_data.country_code];
    accountData.merchantPhoneCountryCode = COUNTRIES[response_data.country_code]
        ? COUNTRIES[response_data.country_code].phoneCountryCode
        : "+351";
    accountData.email = response_data.email;
    accountData.emailInvoice = response_data.email_invoice;
    accountData.emailErp = response_data.email_erp;
    accountData.phone = response_data.phone_number;
    accountData.phoneInvoice = response_data.phone_number_invoice;
    accountData.phone = response_data.phone_number;
    accountData.website = response_data.website;
    accountData.registeredAddress = `${response_data.street_address}
      ${response_data.postal_code} ${response_data.city}`;
    accountData.formattedRegisterdAddress = `${response_data.street_address},
      ${response_data.postal_code}, ${response_data.city}`;
    accountData.bic = response_data.bank_account?.bic;
    accountData.iban = response_data.bank_account?.iban;
    accountData.bban = response_data.bank_account?.bban;
    accountData.organizationName = response_data.company_name;
    accountData.organizationNumber = response_data.organization_number;
    accountData.sortCode = response_data.bank_account?.branch_sort_code;
    accountData.localAccountNumber = response_data.bank_account?.local_account_number;
    accountData.description = response_data.bank_account?.description;
    accountData.feeCurrency = response_data.fee_currency;
    accountData.fixedFee = response_data.fee_currency + " " + response_data.fixed_fee_per_order;
    accountData.transactionFee = Number(response_data.percentage_fee_per_order).toFixed(2);
    accountData.short_name = "";
    accountData.bank_statement_email = "";
    accountData.bank_statement_format = null;
    accountData.merchant_user = {};
    accountData.admin_user = {};
    accountData.is_missing_user_info = true;
    accountData.application_id = response_data.id;
    accountData.first_name = response_data.first_name;
    accountData.last_name = response_data.last_name;
    accountData.vat_number = response_data.vat_number;
    accountData.address = {
        streetAddress: response_data.street_address || "",
        postalCode: response_data.postal_code || "",
        city: response_data.city || ""
    };
    accountData.buyer_country_configurations = [];
    accountData.fraud_contact = response_data.fraud_contact ?? { email: null, phone: null };
    accountData.documents = [];
    accountData.payout_accounts = response_data.bank_account
        ? [
              {
                  bank_country_code: response_data.bank_account.country_code,
                  bban: response_data.bank_account.bban,
                  branch_sort_code: response_data.bank_account.branch_sort_code,
                  currency: response_data.fee_currency,
                  local_account_number: response_data.bank_account.local_account_number,
                  recipient_name: response_data.bank_account.organization_name,
                  sale_country_code: response_data.bank_account.country_code
              }
          ]
        : [];
    accountData.payoutPlan = "TBC";
    accountData.flags = {
        has_billing_accounts: false,
        fraud_check_on_order_creation: false,
        enable_create_order_via_oc: false,
        enabled_leasing_instalments_api: true,
        provides_own_invoice_numbers: false,
        merchant_initiated_enabled: true,
        fetch_only_leaf_orders: false,
        webhooks_enabled: false,
        legacy_settlement_ui_portal: false,
        show_new_order_details_page: true,
        enable_fe_partial_fulfillment: false,
        enable_invoice_editing: false,
        enable_instalment_order_creation: false,
        cross_border_enabled: false,
        bank_statement_email_is_enabled: false,
        api_key_management_enabled: false,
        business_registration_button_enabled: false,
        buyer_fallback_enabled: false,
        show_line_item_more_details_on_order_creator: false,
        show_settlement_payout_customized_for_kredinor_on_portal: false,
        enable_fe_validate_customer_recourse_limit: false,
        has_made_order: false,
        show_risk_management_related_features: false
    };
    account.set(accountData);
    showAccountAlert.set(false);
    loggedInAsMerchant.set(true);
    taxNaming.set(determineTaxNaming(accountData.countryCode));
}

export const {
    actions,
    account,
    loading,
    showAccountAlert,
    taxNaming,
    teamMembers,
    recourseLimitConfig,
    recourseEnabled,
    signupComplete,
    getAccountExecuted,
    recourseLoading,
    userMerchants,
    loggedInAsMerchant,
    loggedInAsMerchantPortalUser
} = createAccount();
