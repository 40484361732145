<script lang="ts">
    import CheckBox from "../icons/checkbox.svelte";
    export let checked: boolean = false;
    export let checkboxLabel: string = "";
    export let borderColor = "";
    export let showCircle = true;
</script>

<label class="switch">
    <input type="checkbox" bind:checked on:change class="opacity-0 w-0 h-0" />
    <span
        class="slider flex items-center justify-center bg-white"
        class:borderColour={borderColor === "red"}
    >
        {#if checked}
            <CheckBox size={14} fill="var(--borderGeneralTertiary)" />
        {/if}
    </span>
    {#if showCircle}
        <span class="circle" />
    {/if}
    {#if checkboxLabel.length}
        <span class="label text-gray-500">{checkboxLabel}</span>
    {/if}
</label>

<style>
    .circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: none;
        background: #f8f8f9;
        position: absolute;
        top: 0px;
    }

    .borderColour {
        border-color: red !important;
    }

    .label {
        margin-left: 40px;
    }

    .switch {
        position: relative;
        display: inline-block;
        /* width: 40px; */
        min-width: 40px;
        height: 40px;
        line-height: 40px;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 11px;
        left: 11px;
        right: 0;
        bottom: 0;
        width: 18px;
        height: 18px;
        border: 2px solid#B4B4C9;
        border-radius: 4px;
        /* background-color: #ccc; */
        -webkit-transition: 0.2s;
        transition: 0.2s;
        z-index: 1;
        /* border-radius: 50%; */
    }
    .slider:hover + .circle {
        display: block;
    }

    input:checked + .slider {
        background-color: white;
        border-color: var(--borderGeneralTertiary);
    }

    input:checked + .slider {
        box-shadow: 0 0 1px #3b2199;
    }

    input:checked + .slider + .circle {
        display: block;
        background-color: #e3e4ec;
    }
</style>
