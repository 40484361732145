<script lang="ts">
    import { _ } from "svelte-i18n";

    import { account } from "../../../store/merchant/account.store";
    import MetaWrapper from "../../../components/metadata/MetaWrapper.svelte";
    import PageHeader from "../../../components/pageHeader/PageHeader.svelte";
    import PayoutsBalanceOverview from "./PayoutsBalanceOverview.svelte";
    import PayoutsRecent from "./PayoutsRecent.svelte";

    export let TABS = [$_("payouts.overview")];

    $: showKredinorView =
        $account?.flags?.show_settlement_payout_customized_for_kredinor_on_portal ?? false;

    const selectedTab = TABS[0];

    function setTab() {
        console.log("not implemented yet!");
        // SomeState.actions.setTab(tab)
    }
</script>

<MetaWrapper title={$_("payouts.title")}>
    <div class="page-container">
        <PageHeader title={$_("payouts.title")}>
            <div slot="tabs" class="tab-headers">
                {#each TABS as tab}
                    <button
                        class="tab-header"
                        class:selected={selectedTab == tab}
                        on:click={setTab}
                    >
                        {tab}
                    </button>
                {/each}
            </div>
        </PageHeader>
        <div class="content-container">
            <PayoutsBalanceOverview {showKredinorView} />
            <PayoutsRecent {showKredinorView} />
        </div>
    </div>
</MetaWrapper>

<style lang="scss">
    * {
        box-sizing: border-box;
    }

    .page-container {
        background-color: inherit;
        height: 100%;
    }
</style>
