<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import { Button as TwoUIButton } from "@two-ui/core";
    import { capitalize } from "lodash";

    import MetaWrapper from "@/components/metadata/MetaWrapper.svelte";
    import PageHeader from "@/components/pageHeader/PageHeader.svelte";
    import StatementOfAccountModal from "@/components/modals/StatementOfAccountModal.svelte";
    import CreditLimit from "./CreditLimit.svelte";
    import TradeAccounts from "./TradeAccounts.svelte";
    import BillingAccounts from "./BillingAccounts.svelte";
    import API from "@/services/apiService";
    import { adaptTradeAccountsToUI, type TradeAccountUI } from "./helpers";
    import { BASE_URL, GET_CUSTOMER_BETA } from "@/static/endPoints";
    import modalState from "@/store/modals.store";
    import { singleCustomer } from "@/store/merchant/customers.store";
    import { type TradeAccount, type ICustomersResponse, NotificationType } from "@/static/types";
    import { account, signupComplete } from "@/store/merchant/account.store";
    import environment from "@/services/environment";
    import SignupIncompleteModal from "@/components/modals/AccountSetup/SignupIncompleteModal.svelte";
    import { buyerDetails } from "@/store/merchant/allOrders.store";
    import { CREATE_ORDER_PATH } from "../orders/variables";
    import notificationState from "@/store/notifications.store";
    import { CUSTOMER_PAGE_ROUTE } from "@/pages/merchant/orders/variables";

    export let url;
    export let id;

    const searchParams = new URLSearchParams(window.location.search);
    const buyerOrgNumber = id;
    const buyerCountryCode = searchParams.get("country");

    const ORDER_CREATOR_ROUTE = `${CREATE_ORDER_PATH}/`;
    const TRADE_ACCOUNT_TAB_NAME = $_("customer.tradeAccount");
    const BILLING_ACCOUNT_TAB_NAME = $_("customer.billingAccount");
    const CREDIT_LIMIT_TAB_NAME = $_("customer.creditLimit.title");
    const tabs = [CREDIT_LIMIT_TAB_NAME, TRADE_ACCOUNT_TAB_NAME, BILLING_ACCOUNT_TAB_NAME];
    let selectedTab = tabs[0];

    let merchantInitiatedEnabled: boolean = false;
    let loadingTradeAccounts: boolean = false;
    let tradeAccounts: Array<TradeAccountUI> = [];

    function setTab(tabName) {
        selectedTab = tabName;
    }

    async function fetchSingleCustomerDetails() {
        API.get(
            GET_CUSTOMER_BETA +
                `?country_code=${buyerCountryCode}&organization_number=${buyerOrgNumber}`
        )
            .then((res: ICustomersResponse) => {
                if (res.customers && res.customers[0]) {
                    singleCustomer.set(res.customers[0]);
                } else {
                    notificationState.actions.create(
                        NotificationType.ERROR,
                        $_("customers.errors.customerNotFound"),
                        null,
                        $_("customers.errors.pleaseAddCustomer")
                    );
                    navigate(CUSTOMER_PAGE_ROUTE);
                }
            })
            .catch((e) => {
                console.error(`Failed to fetchSingleCustomerDetails because ${e}`);
                notificationState.actions.create(
                    NotificationType.ERROR,
                    $_("customers.errors.somethingWentWrong"),
                    null,
                    $_("customers.errors.searchOrAddCustomer")
                );
                navigate(CUSTOMER_PAGE_ROUTE);
            });
    }

    async function fetchTradeAccounts() {
        loadingTradeAccounts = true;
        API.get(
            `${BASE_URL}/v1/portal/merchant/trade_accounts?country_code=${buyerCountryCode}&organization_number=${buyerOrgNumber}`
        )
            .then((response: { data: Array<TradeAccount> }) => {
                tradeAccounts = adaptTradeAccountsToUI(response.data).sort((a, b) =>
                    a.name.localeCompare(b.name)
                );
            })
            .catch((error) => {
                console.error(`fetchTradeAccounts failed with ${error}`);
            })
            .finally(() => {
                loadingTradeAccounts = false;
            });
    }

    function showInvoiceStatementModal() {
        const props = {
            exportUrl: `${BASE_URL}/v1/portal/merchant/invoice/statement`,
            isBuyerPortal: false,
            headerSubText: $_("customer.downloadSpreadsheet"),
            showAllText: $_("customer.showAllInvoices"),
            id: "statement-of-account-modal",
            additionalFilters: {
                buyer_companies: [
                    {
                        national_identification_number: buyerOrgNumber,
                        country_code: buyerCountryCode
                    }
                ]
            }
        };
        modalState.actions.setModal("", props, StatementOfAccountModal);
    }

    function handleCreateOrder() {
        buyerDetails.set({
            companyName: $singleCustomer?.buyer_company_name,
            companyNumber: buyerOrgNumber,
            countryCode: buyerCountryCode
        });
        navigate(ORDER_CREATOR_ROUTE);
    }

    onMount(async () => {
        if (!$singleCustomer) {
            await fetchSingleCustomerDetails();
        }
        await fetchTradeAccounts();
    });

    $: {
        merchantInitiatedEnabled = $account?.flags?.merchant_initiated_enabled;
    }
</script>

<MetaWrapper title={$_("customer.detailsPageTitle")}>
    <div class={`page-container ${url}`}>
        <PageHeader
            title={$singleCustomer?.buyer_company_name.split(" ").map(capitalize).join(" ") ||
                $_("components.loading")}
            description={$singleCustomer?.buyer_organization_number || $_("components.loading")}
            backLink="/merchant/customers"
        >
            <div slot="tabs" class="tab-headers">
                {#each tabs as tab}
                    <button
                        class="px-6 mr-2 h-10 tab-header"
                        on:click={() => setTab(tab)}
                        class:selected={selectedTab === tab}
                    >
                        {tab}
                    </button>
                {/each}
            </div>
            <div slot="cta" class="flex gap-2">
                <TwoUIButton
                    on:click={showInvoiceStatementModal}
                    size="md"
                    variant="secondaryGray"
                    content={$_("customer.downloadStatement")}
                />
                {#if merchantInitiatedEnabled && !environment.branding.hideOrderCreationBtn}
                    <TwoUIButton
                        on:click={() =>
                            $signupComplete
                                ? handleCreateOrder()
                                : modalState.actions.setModal("", {}, SignupIncompleteModal)}
                        size="md"
                        variant="primary"
                        content={$_("order.new.create")}
                    />
                {/if}
            </div>
        </PageHeader>

        <div class="content">
            {#if selectedTab === TRADE_ACCOUNT_TAB_NAME}
                <TradeAccounts {tradeAccounts} {loadingTradeAccounts} {fetchTradeAccounts} />
            {:else if selectedTab === BILLING_ACCOUNT_TAB_NAME}
                <BillingAccounts {id} />
            {:else}
                <CreditLimit singleCustomer={$singleCustomer} />
            {/if}
        </div>
    </div>
</MetaWrapper>

<style>
</style>
