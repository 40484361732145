<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Button, Textarea, TextInput } from "@two-ui/core";
    import { navigate } from "svelte-routing";
    import { onDestroy, onMount } from "svelte";
    import Fa from "svelte-fa";
    import { faCircleInfo, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

    import API from "@/services/apiService";
    import MetaWrapper from "@/components/metadata/MetaWrapper.svelte";
    import { account } from "@/store/merchant/account.store";
    import type countryName from "@/static/countryName";
    import {
        type CurrencyCode,
        CurrencyCodeEnum,
        type ICountry,
        type IMerchantOrderInvalidLineItems,
        NotificationType
    } from "@/static/types";
    import modalState from "@/store/modals.store";
    import orderCreateStore, {
        selectedCompanyName,
        selectedPaymentMethod
    } from "@/store/merchant/orderCreate.store";
    import { creditLimitState, actions as creditActions } from "@/store/merchant/creditlimit.store";
    import {
        BASE_URL,
        CREATE_NEW_ORDER,
        NEW_ORDER_INTENT,
        CREATE_NEW_ORDER_WITH_FRAUD_CHECK
    } from "@/static/endPoints";
    import notificationState from "@/store/notifications.store";
    import { emailIsValid } from "@/utils/validation";
    import LineItems from "./LineItems.svelte";
    import BuyerDetails from "./BuyerDetails.svelte";
    import { COUNTRIES, SE } from "@/static/constant";
    import {
        merchantOrderIDInfo,
        purchaseOrderInfo,
        projectInfo,
        departmentInfo,
        invoiceEmailInfo
    } from "@/static/createOrder";
    import Addresses from "./Addresses.svelte";
    import PageHeader from "@/components/pageHeader/PageHeader.svelte";
    import environment from "@/services/environment";
    import { orderReplica } from "@/store/merchant/allOrders.store";
    import * as helpLinks from "@/services/helpCentreService";
    import { CREATE_ORDER } from "@/static/modals";
    import { scrollToFirstVisibleError } from "./helpers";
    import {
        INSTALMENT_PLAN,
        netTermsOptions,
        instalmentOptions,
        STANDARD_TERMS
    } from "@/utilsV2/netTerms";
    import { getErrorMessage } from "@/utilsV2/helpers";

    const helpMenuLinks = {
        [$_("helpLinks.orderCreateFaq")]: helpLinks.ORDER_CREATE_FAQ,
        [$_("helpLinks.aboutTwo", { values: { brandName: environment.branding.displayName } })]:
            environment.branding.aboutLink
    };
    // Buyer Details
    let companyID: string = "";
    let companyExternalID: string = "";
    let countryCode: keyof typeof countryName | null = null;
    let address = {
        streetAddress: "",
        postalCode: "",
        city: "",
        country: ""
    };
    let shippingAddress = {
        streetAddress: "",
        postalCode: "",
        city: "",
        country: ""
    };
    let representative = {
        first_name: "",
        last_name: "",
        phone_number: "",
        email: ""
    };
    let invalid = {
        selectCompany: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        orderEmail: "",
        merchantOrderID: "",
        billingAddressStreetName: "",
        billingAddressPostcode: "",
        billingAddressCity: "",
        shippingAddressStreetName: "",
        shippingAddressPostcode: "",
        shippingAddressCity: "",
        payment_terms: "",
        emailForInvoice: ""
    };
    let invalidLineItems: IMerchantOrderInvalidLineItems[] = [];

    // Line Items
    let line_items = [];
    let gross_amount = 0;
    let net_amount = 0;
    let tax_amount = 0;
    let discount_amount = 0;
    let buttonText: string = $_("order.new.sendOrder");
    let invoiceType: string;

    let lineItemsError: string = "";
    let disableOrderBtn: boolean = false;

    // Order Details
    let orderDetails: {
        description: string;
        project: string;
        department: string;
        merchant_order_id: string;
        purchase_order_number: string;
        emailForInvoice: string;
        merchant_reference: string;
        merchant_additional_info: string;
    } = {
        description: $orderReplica?.notes || "",
        project: $orderReplica?.project || "",
        department: $orderReplica?.buyerDepartment || "",
        merchant_order_id: "",
        purchase_order_number: $orderReplica?.purchaseOrderNumber || "",
        emailForInvoice: $orderReplica?.invoice_details?.invoice_emails?.[0] || "",
        merchant_reference: "",
        merchant_additional_info: ""
    };
    let orderIntentFailed: string = "";
    let currency: CurrencyCode;
    let merchant_short_name: string = "";
    let merchantLegalName: string = "";
    let min_order_value: string = "100";
    let merchantCountryCode: keyof typeof countryName;
    let availableCreditLimit: number;
    let maxOrderValue: number;
    let merchantRecourseLimitValue: number;
    let buyerRecourseLimitValue: number = 0;
    let orderValueError: boolean = false;
    let buyerFallbackEnabled: boolean = false;
    let fallbackThreshold: string = "0.00";
    let allowFallbackForOrder = true;
    let showShippingAddressFields: boolean = false;

    let filterItems: string[] = netTermsOptions($_);
    const dueInDaysFromReplica: number = $orderReplica?.invoice_details?.due_in_days;
    const dueInDaysLabelFromReplica: string | null = filterItems.find((term) =>
        term.includes(String(dueInDaysFromReplica))
    );
    const numberOfInstallmentsFromReplica: number =
        $orderReplica?.terms?.number_of_instalments || 0;
    const numberOfInstallmentsLabel = instalmentOptions($_).find((option) =>
        option.includes(String(numberOfInstallmentsFromReplica))
    );
    let payment_terms: string = dueInDaysFromReplica ? dueInDaysLabelFromReplica : "";
    if (!dueInDaysFromReplica && numberOfInstallmentsFromReplica) {
        payment_terms = numberOfInstallmentsLabel;
    }
    let orderIntentCompleted = false;

    $: gross_amount && checkFallbackCoversGrossAmount();
    $: address && resetInvalidOnCompanySearchResponse();

    function checkFallbackCoversGrossAmount() {
        if (buyerFallbackEnabled && gross_amount < parseFloat(fallbackThreshold)) {
            allowFallbackForOrder = true;
            invoiceType = "DIRECT_INVOICE";
        } else {
            allowFallbackForOrder = false;
            invoiceType = "FUNDED_INVOICE";
        }
    }

    function resetInvalidOnCompanySearchResponse() {
        if (address.streetAddress !== "") {
            invalid.billingAddressStreetName = "";
        }
        if (address.postalCode !== "") {
            invalid.billingAddressPostcode = "";
        }
        if (address.city !== "") {
            invalid.billingAddressCity = "";
        }
    }

    $: disableOrderBtn,
        (buttonText = disableOrderBtn ? $_("order.new.pleaseWait") : $_("order.new.sendOrder"));

    $: if ($account.countryCode) {
        merchant_short_name = $account.short_name || "";
        merchantCountryCode = $account.countryCode;
        merchantLegalName = $account.legalName;
        orderCreateStore.actions.setCountry(COUNTRIES[$account.countryCode]);

        // Temporary measure to support 35 and 120 days for creoate
        if (merchant_short_name === "creoate" || merchant_short_name === "creoatelimited") {
            filterItems = [
                "14 days",
                "30 days",
                "35 days",
                "45 days",
                "60 days",
                "90 days",
                "120 days"
            ];
        }
    }

    $: orderCreateStore.country.subscribe((country: ICountry) => {
        if (country) {
            countryCode = country.country_code;
            min_order_value = currency === CurrencyCodeEnum.NOK ? "100" : "2";
        }
    });

    $: if ($creditLimitState?.success === false && !orderIntentCompleted) {
        // try creating an order intent if there was an error fetching credit limits
        // why? because credit limits are not available for all buyers
        createOrderIntent();
    }

    $: if (line_items && line_items.length) {
        line_items.forEach(() => {
            invalidLineItems.length < line_items.length &&
                invalidLineItems.push({
                    itemName: "",
                    unitPrice: "",
                    quantity: "",
                    discount: "",
                    taxRate: "",
                    barcode: ""
                });
        });
    }

    function createOrderIntent() {
        orderIntentFailed = "";
        invalid.selectCompany = "";
        const order_intent_data = {
            buyer: {
                company: {
                    company_name: $selectedCompanyName,
                    country_prefix: countryCode,
                    organization_number: companyID,
                    company_type: companyExternalID
                }
            },
            currency,
            gross_amount: min_order_value,
            invoice_type: invoiceType,
            line_items: [
                // Note: Aprila validates amount for order intent against line items
                // so we should send a mock line item matching order intent amount
                {
                    description: "nominal line item for nominal order intent",
                    gross_amount: min_order_value,
                    name: "nominal line item",
                    net_amount: min_order_value,
                    quantity: 1,
                    quantity_unit: "unit",
                    unit_price: min_order_value,
                    tax_amount: "0.00",
                    tax_class_name: "VAT 0%",
                    tax_rate: "0.00",
                    type: "PHYSICAL"
                }
            ],
            merchant_short_name
        };

        API.post(NEW_ORDER_INTENT, order_intent_data)
            .then((res: any) => creditActions.processIntentResponse(res))
            .catch(() => creditActions.processIntentResponse({ approved: false }))
            .finally(() => (orderIntentCompleted = true));
    }

    function validateRepresentative() {
        let allValid: boolean = true;
        if (companyID === "") {
            invalid.selectCompany = $_("order.new.errorCompanySelection");
            allValid = false;
        } else {
            invalid.selectCompany = "";
        }
        if (!representative.first_name) {
            invalid.first_name = $_("order.new.errorFirstName");
            allValid = false;
        } else {
            invalid.first_name = "";
        }
        if (!representative.last_name) {
            invalid.last_name = $_("order.new.errorLastName");
            allValid = false;
        } else {
            invalid.last_name = "";
        }

        const phoneRegex = /^\(?[+ ]?(\.*[0-9]*)$/gm;
        if (
            !representative.phone_number ||
            representative.phone_number.length < 6 ||
            !phoneRegex.test(representative.phone_number)
        ) {
            invalid.phone_number = $_("order.new.errorPhoneNumber");
            allValid = false;
        } else {
            invalid.phone_number = "";
        }

        if (!representative.email || !emailIsValid(representative.email)) {
            invalid.email = $_("order.new.errorEmail");
            allValid = false;
        } else {
            invalid.email = "";
        }

        return allValid;
    }

    function validateBillingAddress() {
        let allValid = true;
        if (address.streetAddress === "") {
            invalid.billingAddressStreetName = $_("order.new.errorBillingStreetAddress");
            allValid = false;
        } else {
            invalid.billingAddressStreetName = "";
        }

        if (address.postalCode === "") {
            invalid.billingAddressPostcode = $_("order.new.errorBillingPostalCode");
            allValid = false;
        } else {
            invalid.billingAddressPostcode = "";
        }

        if (address.city === "") {
            invalid.billingAddressCity = $_("order.new.errorBillingCity");
            allValid = false;
        } else {
            invalid.billingAddressCity = "";
        }

        return allValid;
    }

    function validateShippingAddress() {
        let allValid = true;
        if (showShippingAddressFields) {
            if (shippingAddress.streetAddress === "") {
                invalid.shippingAddressStreetName = $_("order.new.errorShippingStreetAddress");
                allValid = false;
            } else {
                invalid.shippingAddressStreetName = "";
            }

            if (shippingAddress.postalCode === "") {
                invalid.shippingAddressPostcode = $_("order.new.errorShippingPostalCode");
                allValid = false;
            } else {
                invalid.shippingAddressPostcode = "";
            }

            if (shippingAddress.city === "") {
                invalid.shippingAddressCity = $_("order.new.errorShippingCity");
                allValid = false;
            } else {
                invalid.shippingAddressCity = "";
            }
        }
        return allValid;
    }

    function sendInvoice() {
        let data = null;
        let allValid = true;
        // payment terms
        if (payment_terms === "") {
            invalid.payment_terms = $_("order.new.errorPaymentTerms");
            allValid = false;
        } else {
            invalid.payment_terms = "";
        }

        // Email for invoices
        if (orderDetails.emailForInvoice && !emailIsValid(orderDetails.emailForInvoice)) {
            invalid.emailForInvoice = $_("order.new.errorEmail");
            allValid = false;
        } else {
            invalid.emailForInvoice = "";
        }

        const isValidRep = validateRepresentative();
        const isValidBillingAddress = validateBillingAddress();
        const isValidShippingAddress = validateShippingAddress();

        allValid = allValid && isValidRep && isValidBillingAddress && isValidShippingAddress;

        if (allValid && line_items.length <= 0) {
            lineItemsError = $_("order.new.errorLineItems");
            allValid = false;
        }

        if (allValid && line_items.length > 0 && !validateLineItems()) {
            allValid = false;
        }

        if (!orderDetails.merchant_order_id) {
            invalid.merchantOrderID = $_("order.new.errorMerchantOrderID");
            allValid = false;
        } else {
            invalid.merchantOrderID = "";
        }

        if (allValid) {
            data = prepareData();
        } else {
            // scroll to error
            setTimeout(() => {
                scrollToFirstVisibleError();
            }, 100);
        }

        // Should take 30 from "30 days" or 24 from "24 months"
        const extractedPaymentTermsAmount = payment_terms
            ? Number(payment_terms.match(/^\d+/)[0])
            : 0;

        if (allValid && data !== null) {
            disableOrderBtn = true;
            const order_intent_data = {
                buyer: {
                    company: {
                        company_name: $selectedCompanyName,
                        country_prefix: countryCode,
                        organization_number: companyID,
                        company_type: companyExternalID
                    },
                    representative
                },
                ...($selectedPaymentMethod === INSTALMENT_PLAN($_)
                    ? {
                          terms: {
                              period: "MONTHLY",
                              number_of_instalments: extractedPaymentTermsAmount
                          }
                      }
                    : null),
                currency,
                gross_amount: data.gross_amount,
                invoice_type: invoiceType,
                line_items: data.line_items,
                merchant_short_name,
                shipping_address: data.shipping_address,
                billing_address: data.billing_address
            };

            if (allowFallbackForOrder) {
                modalState.actions.setModal("MI_BUYER_FALLBACK", {
                    newOrderIntent,
                    order_intent_data,
                    data,
                    disableOrderBtn: () => (disableOrderBtn = false),
                    buyerName: $selectedCompanyName
                });
            } else {
                newOrderIntent(order_intent_data, data);
            }
        }
    }

    function newOrderIntent(order_intent_data: any, data: any) {
        API.post(NEW_ORDER_INTENT, order_intent_data)
            .then((res: any) => {
                if (res?.approved === true) {
                    orderIntentFailed = "";
                    API.post(
                        $account?.flags?.enable_create_order_via_oc
                            ? CREATE_NEW_ORDER_WITH_FRAUD_CHECK
                            : CREATE_NEW_ORDER,
                        data
                    )
                        .then(() => {
                            modalState.actions.setModal(CREATE_ORDER, {
                                fName: representative.first_name,
                                resetCreateOrderForm: resetFields
                            });
                            $orderReplica = null;
                            disableOrderBtn = false;
                        })
                        .catch((err: any) => {
                            let message: string = getErrorMessage(
                                err.response,
                                $_("order.new.orderIntentError")
                            );
                            if (
                                message.includes("Invalid phone number") &&
                                err.response.data.error_code == "SCHEMA_ERROR"
                            ) {
                                // scroll to phone number input if invalid phone number
                                const representativesSection =
                                    document.getElementById("representative-details");

                                if (representativesSection) {
                                    representativesSection.scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    });
                                }
                            }
                            if (err.response.status == 404) {
                                message = $_("errors.serverUnavailable");
                            }
                            console.log("err", err);
                            notificationState.actions.create(NotificationType.ERROR, message);
                            disableOrderBtn = false;
                        });
                } else {
                    orderIntentFailed = $_("order.new.orderIntentFailedMessage", {
                        values: { company: $selectedCompanyName }
                    });
                    disableOrderBtn = false;
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }
            })
            .catch((err: any) => {
                modalState.actions.setModal(null, {});
                let message: string = getErrorMessage(
                    err.response,
                    $_("order.new.intentError", {
                        values: { supportEmail: environment.branding.supportEmail }
                    })
                );

                if (message.includes("Postal code should")) {
                    notificationState.actions.create(
                        NotificationType.ERROR,
                        $_("order.new.postCodeIssue"),
                        null,
                        $_("order.new.postCodeIssueText")
                    );
                } else {
                    orderIntentFailed = $_("order.new.intentError", {
                        values: { supportEmail: environment.branding.supportEmail }
                    });
                }
                disableOrderBtn = false;
            });
    }

    function validateLineItems() {
        return line_items.every(isItemValid);
    }

    function isItemValid(item, index) {
        invalidLineItems[index] = {
            itemName: item.name === "" ? $_("order.new.lineItems.itemName") : "",
            unitPrice:
                item.unit_price === null || item.unit_price === 0
                    ? $_("order.new.lineItems.unitPrice")
                    : "",
            quantity:
                item.quantity === null || item.quantity <= 0
                    ? $_("order.new.lineItems.quantity")
                    : "",
            taxRate:
                item.tax_rate === null || item.tax_rate > 100
                    ? $_("order.new.lineItems.taxRate")
                    : "",
            discount: "",
            barcode:
                (item.barcodeValue == "") != (item.barcodeType == "")
                    ? $_("order.new.lineItems.barcode")
                    : ""
        };

        lineItemsError =
            invalidLineItems[index].itemName ||
            invalidLineItems[index].unitPrice ||
            invalidLineItems[index].quantity ||
            invalidLineItems[index].taxRate ||
            invalidLineItems[index].discount ||
            invalidLineItems[index].barcode ||
            "";

        return lineItemsError.length ? false : true;
    }

    function prepareData() {
        let merchant_base_url = "";

        if (BASE_URL.includes("https://api.two.inc")) {
            merchant_base_url = "https://checkout.two.inc";
        } else if (BASE_URL.includes("https://api.demo.two.inc")) {
            merchant_base_url = "https://checkout.demo.two.inc";
        } else {
            merchant_base_url = "https://checkout.staging.two.inc";
        }

        const billingAddressField = {
            organization_name: $selectedCompanyName,
            street_address: address.streetAddress,
            postal_code: address.postalCode.trim().replace(/\s+/g, ""),
            city: address.city,
            region: address.city,
            country: address.country || countryCode
        };

        let shippingAddressField;
        if (showShippingAddressFields) {
            shippingAddressField = {
                organization_name: $selectedCompanyName,
                street_address: shippingAddress.streetAddress,
                postal_code: shippingAddress.postalCode.trim().replace(/\s+/g, ""),
                city: shippingAddress.city,
                region: shippingAddress.city,
                country: shippingAddress.country
            };
        } else {
            shippingAddressField = billingAddressField;
        }

        const lineItems = JSON.parse(JSON.stringify(line_items));

        const taxSubtotals = [];
        // Only include taxSubtotals for Sweden merchants
        const includeTaxSubtotals = merchantCountryCode === SE;
        if (includeTaxSubtotals) {
            const taxSubtotalMap = {};
            for (const item of lineItems) {
                const tax_rate = (item.tax_rate / 100).toString();
                if (!(tax_rate in taxSubtotalMap)) {
                    taxSubtotalMap[tax_rate] = {
                        tax_amount: 0,
                        taxable_amount: 0,
                        tax_rate
                    };
                }
                taxSubtotalMap[tax_rate].tax_amount += parseFloat(item.tax_amount);
                taxSubtotalMap[tax_rate].taxable_amount += parseFloat(item.net_amount);
            }

            for (const k in taxSubtotalMap) {
                const taxSubtotal = taxSubtotalMap[k];
                taxSubtotal.tax_amount = taxSubtotal.tax_amount.toFixed(2).toString();
                taxSubtotal.taxable_amount = taxSubtotal.taxable_amount.toFixed(2).toString();
                taxSubtotals.push(taxSubtotal);
            }
        }

        lineItems.forEach((item) => {
            item.gross_amount = item.gross_amount.toString();
            item.net_amount = item.net_amount.toFixed(2).toString();
            item.unit_price = item.unit_price.toFixed(2).toString();
            item.tax_amount = item.tax_amount.toString();
            item.tax_rate = (item.tax_rate / 100).toString();
            item.discount_amount =
                item.discount_amount === null ? "0.00" : item.discount_amount.toFixed(2).toString();

            if (item.barcodeValue && item.barcodeValue !== "") {
                item.details = {
                    brand: item.brand,
                    categories: item.categories,
                    part_number: item.part_number,
                    barcodes: [
                        {
                            type: item.barcodeType,
                            value: item.barcodeValue
                        }
                    ]
                };
            }

            delete item.brand;
            delete item.part_number;
            delete item.categories;
            delete item.barcodeType;
            delete item.barcodeValue;
        });
        // Should take 30 from "30 days" or 24 from "24 months"
        const extractedPaymentTermsAmount = payment_terms
            ? Number(payment_terms.match(/^\d+/)[0])
            : 0;

        const data: any = {
            vendor_id: merchant_short_name,
            net_amount: net_amount.toString(),
            tax_amount: tax_amount.toString(),
            gross_amount: gross_amount.toString(),
            invoice_type: invoiceType,
            discount_amount: discount_amount.toString(),
            discount_rate: "0",
            ...($selectedPaymentMethod === INSTALMENT_PLAN($_)
                ? {
                      terms: {
                          period: "MONTHLY",
                          number_of_instalments: extractedPaymentTermsAmount
                      }
                  }
                : null),
            invoice_details: {
                payment_reference: "",
                payment_reference_type: "",
                payment_reference_message: "",
                payment_reference_ocr: "",
                invoice_emails: orderDetails.emailForInvoice ? [orderDetails.emailForInvoice] : [],
                ...($selectedPaymentMethod === STANDARD_TERMS($_)
                    ? { due_in_days: extractedPaymentTermsAmount }
                    : null)
            },
            buyer: {
                company: {
                    company_name: $selectedCompanyName,
                    country_prefix: countryCode,
                    organization_number: companyID,
                    company_type: companyExternalID
                },
                representative: {
                    ...representative,
                    phone_number: representative.phone_number.toString()
                }
            },
            buyer_purchase_order_number: orderDetails.purchase_order_number,
            buyer_department: orderDetails.department,
            buyer_project: orderDetails.project,
            order_note: orderDetails.description,
            merchant_reference: orderDetails.merchant_reference,
            currency,
            recurring: false,
            merchant_additional_info: orderDetails.merchant_additional_info,
            merchant_order_id: orderDetails.merchant_order_id,
            merchant_urls: {
                merchant_confirmation_url: `${merchant_base_url}/mi/order`,
                merchant_cancel_order_url: `${merchant_base_url}/mi/order`,
                merchant_edit_order_url: `${merchant_base_url}/mi/order`,
                merchant_order_verification_failed_url: `${merchant_base_url}/mi/order`,
                merchant_invoice_url: `${merchant_base_url}/mi/order`,
                merchant_shipping_document_url: `${merchant_base_url}/mi/order`
            },
            line_items: lineItems,
            billing_address: billingAddressField,
            shipping_address: shippingAddressField
        };
        if (includeTaxSubtotals) {
            data.tax_subtotals = taxSubtotals;
        }

        return data;
    }

    function resetFields() {
        line_items = [];
        selectedCompanyName.set("");
        companyID = "";
        companyExternalID = "";
        address = {
            streetAddress: "",
            postalCode: "",
            city: ""
        };
        invalid = {
            selectCompany: "",
            first_name: "",
            last_name: "",
            phone_number: "",
            email: "",
            orderEmail: "",
            merchantOrderID: "",
            billingAddressStreetName: "",
            billingAddressPostcode: "",
            billingAddressCity: "",
            shippingAddressStreetName: "",
            shippingAddressPostcode: "",
            shippingAddressCity: "",
            payment_terms: "",
            emailForInvoice: ""
        };
        representative = {
            first_name: "",
            last_name: "",
            phone_number: "",
            email: ""
        };
        orderDetails = {
            description: "",
            project: "",
            department: "",
            merchant_order_id: "",
            purchase_order_number: "",
            emailForInvoice: "",
            merchant_reference: "",
            merchant_additional_info: ""
        };
        gross_amount = 0;
        net_amount = 0;
        tax_amount = 0;
        discount_amount = 0;
        disableOrderBtn = false;
        payment_terms = "";
        (document.getElementById("users-phone") as HTMLInputElement).value = "";
    }

    $: if (!$selectedCompanyName || !companyID) {
        creditActions.resetState();
        orderIntentCompleted = false;
    }

    let emailForInvoiceInputTouched = false;
    $: if (representative.email && !emailForInvoiceInputTouched) {
        orderDetails.emailForInvoice = representative.email;
    }

    onMount(() => {
        // Something cause scrolling to middle of the page,
        // so we need to scroll to top to prevent.
        // Was not able to find the root cause.
        window.scrollTo({ top: 0, behavior: "smooth" });
    });

    onDestroy(() => {
        $orderReplica = null;
        resetFields();
    });
</script>

<MetaWrapper title={$_("order.new.createOrderTitle")}>
    <div class="page-container">
        <PageHeader title={$_("order.new.create")} {helpMenuLinks}>
            <div slot="cta" class="flex">
                <div class="w-36 ml-4">
                    <Button
                        on:click={() => navigate("/merchant/orders/")}
                        size="md"
                        variant="secondaryGray"
                        content={$_("components.cancel")}
                        fullSize={true}
                    />
                </div>
                <div class="w-36 ml-2">
                    <Button
                        id="send_order"
                        on:click={sendInvoice}
                        size="md"
                        variant="primary"
                        content={buttonText}
                        fullSize={true}
                        disabled={disableOrderBtn || (orderValueError && !allowFallbackForOrder)}
                    />
                </div>
            </div>
        </PageHeader>

        <!-- temporary block -->
        <div style="background-color: #B9E6FE" class="px-4 py-2 flex items-center text-sm">
            <Fa icon={faCircleInfo} class="mr-2" color="#1849A9" />
            <span style="color: #1849A9"
                ><b>{$_("redesign.title")}</b>
                <a
                    style="color: #1849A9"
                    class="underline"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfSuqEDojN9LVFbmBqzRZe7j7peCVAv0t5TeU7zZ3OcfZQy4A/viewform"
                    target="_blank">{$_("redesign.shareFeedback")}</a
                >
                {$_("redesign.onDesign")}
            </span>
        </div>

        <div class="grid grid-cols-1 mt-0 md:mt-[inherit]">
            <div
                class="bg-white rounded-2xl mx-8 my-6 p-8 box-border shadow-xs relative min-w-[740px] flex flex-col overflow-hidden"
            >
                {#if orderIntentFailed !== "" && companyID}
                    <div
                        id="order-intent-failed"
                        class="w-full p-2 mb-6 box-border shadow-xs relative bg-Background-Semantic-Error-Tertiary"
                    >
                        <div class="flex flex-column items-center">
                            <div>
                                <Fa icon={faCircleXmark} class="mr-2" />
                            </div>
                            <div>
                                {orderIntentFailed}
                            </div>
                        </div>
                    </div>
                {/if}
                <BuyerDetails
                    bind:currency
                    bind:companyID
                    bind:companyExternalID
                    bind:address
                    bind:representative
                    bind:invalid
                    bind:invoiceType
                    bind:availableCreditLimit
                    bind:maxOrderValue
                    bind:merchantRecourseLimitValue
                    bind:buyerRecourseLimitValue
                    bind:buyerFallbackEnabled
                    bind:fallbackThreshold
                    bind:payment_terms
                />
                <Addresses
                    bind:billingAddress={address}
                    bind:shippingAddress
                    bind:invalid
                    bind:showShippingAddressFields
                    initialCountryCode={countryCode}
                />
                <!-- divider -->
                <div class="border-t border-Border-General-Primary my-8" />
                <LineItems
                    bind:line_items
                    bind:gross_amount
                    bind:net_amount
                    bind:tax_amount
                    bind:discount_amount
                    bind:lineItemsError
                    bind:availableCreditLimit
                    bind:maxOrderValue
                    bind:merchantRecourseLimitValue
                    {currency}
                    {merchantCountryCode}
                    {buyerRecourseLimitValue}
                    bind:orderValueError
                    bind:allowFallbackForOrder
                    bind:invalidLineItems
                    bind:merchantLegalName
                />

                <div class="bg-white w-full rounded-2xl py-6 mb-6 box-border relative">
                    <p class="text-lg text-black-dark font-semibold mr-3">
                        {$_("order.new.orderDetails")}
                    </p>
                    <div class="flex flex-wrap pt-4 gap-[1rem]">
                        <TextInput
                            label={$_("order.new.internalOrderNumber")}
                            id="external_order_number"
                            size="md"
                            class="mt-3 basis-[calc(50%-0.5rem)]"
                            placeholder={$_("order.new.internalOrderNumberPlaceholder")}
                            bind:value={orderDetails.merchant_order_id}
                            bind:error={invalid.merchantOrderID}
                            required={true}
                            tooltipText={merchantOrderIDInfo($_)}
                        />
                        <TextInput
                            id="email-for-invoice"
                            label={$_("order.new.emailForInvoice")}
                            size="md"
                            type="email"
                            class="mt-3 basis-[calc(50%-0.5rem)]"
                            placeholder={$_("order.new.emailForInvoicePlaceholder")}
                            bind:value={orderDetails.emailForInvoice}
                            bind:error={invalid.emailForInvoice}
                            on:input={() => (emailForInvoiceInputTouched = true)}
                            tooltipText={invoiceEmailInfo($_)}
                        />
                        <TextInput
                            id="purchase_order_number"
                            label={$_("order.new.purchaseOrderNumber")}
                            size="md"
                            class="mt-3 basis-[calc(50%-0.5rem)]"
                            placeholder={$_("order.new.purchaseOrderNumberPlaceholder")}
                            bind:value={orderDetails.purchase_order_number}
                            tooltipText={purchaseOrderInfo($_)}
                        />
                        <TextInput
                            id="order-project"
                            label={$_("order.new.project")}
                            size="md"
                            class="mt-3 basis-[calc(50%-0.5rem)]"
                            placeholder={$_("order.new.projectPlaceholder")}
                            bind:value={orderDetails.project}
                            tooltipText={projectInfo($_)}
                        />
                        <TextInput
                            id="order-department"
                            label={$_("order.new.department")}
                            size="md"
                            class="mt-3 basis-[calc(50%-0.5rem)]"
                            placeholder={$_("order.new.departmentPlaceholder")}
                            bind:value={orderDetails.department}
                            tooltipText={departmentInfo($_)}
                        />
                    </div>
                    <div class="w-full mt-4">
                        <Textarea
                            id="order-notes"
                            label={$_("order.new.orderNotes")}
                            size="md"
                            style="height: 108px; padding: 10px 14px; margin: -6px -7px; 0; width: calc(100% + 14px);"
                            placeholder={$_("order.new.orderNotesPlaceholder")}
                            bind:value={orderDetails.description}
                            resize="vertical"
                        />
                    </div>
                </div>
                <div class="w-full">
                    <p class="text-sm text-user-gray">
                        {$_("order.new.invoiceDataNote")}
                    </p>
                </div>
            </div>
        </div>
    </div>
</MetaWrapper>
