<script lang="ts">
    import { _ } from "svelte-i18n";
    import { navigate } from "svelte-routing";
    import { Button } from "@two-ui/core";

    import MetaWrapper from "@/components/metadata/MetaWrapper.svelte";
    import PageHeader from "@/components/pageHeader/PageHeader.svelte";
    import ExposurePage from "./exposure/ExposurePage.svelte";
    import FraudInsightsPage from "./fraud/FraudInsights.svelte";
    import OverviewPage from "./overview/OverviewPage.svelte";
    import InsightsPage from "./insights/InsightsPage.svelte";
    import FraudRulesPage from "./fraud-rules/FraudRulesPage.svelte";
    import AddRuleDrawer from "./fraud-rules/AddRuleDrawer.svelte";
    import { fraudRulesAddNewFlow } from "@/store/merchant/risk.store";

    let tabFromPath: string = window.location.pathname.split("/")[3];

    export let TABS = [
        { alias: "overview", title: $_("riskManagement.overview.title") },
        { alias: "exposure", title: $_("riskManagement.exposure.title") },
        { alias: "insights", title: $_("riskManagement.creditInsights") },
        { alias: "fraud", title: $_("riskManagement.fraud.title") },
        { alias: "fraud-rules", title: $_("riskManagement.fraudRules.title") }
    ];

    let selectedTab = tabFromPath || TABS[0].alias;

    function onTabChange(tabAlias) {
        navigate(`/merchant/risk-management/${tabAlias}/`);
        selectedTab = tabAlias;
    }
</script>

<MetaWrapper title={$_("riskManagement.title")}>
    <div class="page-container">
        <PageHeader title={$_("riskManagement.title")}>
            <div slot="tabs" class="tab-headers">
                {#each TABS as tab}
                    <button
                        class="tab-header"
                        on:click={() => onTabChange(tab.alias)}
                        class:selected={selectedTab === tab.alias}
                    >
                        {tab.title}
                    </button>
                {/each}
            </div>
            <div slot="cta">
                {#if selectedTab === "fraud-rules"}
                    <div class="flex">
                        <Button
                            on:click={() => fraudRulesAddNewFlow.set("whitelist")}
                            size="md"
                            class="mr-2"
                            variant="secondaryGray"
                            content={$_("riskManagement.rules.addWhitelistRule")}
                        />
                        <Button
                            on:click={(e) => fraudRulesAddNewFlow.set("block")}
                            size="md"
                            variant="secondaryGray"
                            content={$_("riskManagement.rules.addBlockRule")}
                        />
                    </div>
                {/if}
            </div>
        </PageHeader>
        <div class="content-container">
            {#if selectedTab === TABS[0].alias}
                <OverviewPage />
            {:else if selectedTab === TABS[1].alias}
                <ExposurePage />
            {:else if selectedTab === TABS[2].alias}
                <InsightsPage />
            {:else if selectedTab === TABS[3].alias}
                <FraudInsightsPage />
            {:else if selectedTab === TABS[4].alias}
                <FraudRulesPage />
            {/if}
        </div>
        {#if $fraudRulesAddNewFlow}
            <AddRuleDrawer />
        {/if}
    </div>
</MetaWrapper>

<style lang="scss">
    * {
        box-sizing: border-box;
    }

    .page-container {
        background-color: inherit;
        height: 100%;
    }
</style>
