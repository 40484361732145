<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Button, ItemWithLabel } from "@two-ui/core";

    import {
        fraudRulesAddNewFlow,
        fraudRulesAddNewStep,
        fraudRulesOrgNumber,
        fraudRulesEmails,
        fraudRulesPhones
    } from "@/store/merchant/risk.store";
    import environment from "@/services/environment";

    const phoneBlockIsShown =
        $fraudRulesPhones.length && $fraudRulesPhones.some((phone) => phone.value.length > 5);
    const emailBlockIsShown = $fraudRulesEmails.length && $fraudRulesEmails.some((email) => email);
</script>

<div id="add-rule-review">
    <div class="flex justify-between items-center">
        <h3 class="text-base font-bold">{$_("riskManagement.rules.reviewTheRule")}</h3>
        <Button
            id="edit-rule"
            on:click={() => fraudRulesAddNewStep.set(1)}
            size="md"
            variant="tertiaryColor"
            content={$_("components.edit")}
        />
    </div>
    <p class="mt-3 mb-6">
        {$fraudRulesAddNewFlow === "block"
            ? $_("riskManagement.rules.reviewTheRuleBlockDescription")
            : $_("riskManagement.rules.reviewTheRuleWhitelistDescription", {
                  values: { brandName: environment.branding.displayName }
              })}
    </p>
    <div class="bg-Background-General-Tertiary px-4 py-2 rounded-lg">
        {#if $fraudRulesOrgNumber}
            <div class="my-3" class:mb-6={phoneBlockIsShown || emailBlockIsShown}>
                <ItemWithLabel
                    label={$_("riskManagement.fraud.organizationNumber")}
                    value={$fraudRulesOrgNumber}
                />
            </div>
        {/if}

        {#if phoneBlockIsShown}
            <div class="my-3" class:mb-6={emailBlockIsShown}>
                <ItemWithLabel
                    label={$_("components.inputType.phone")}
                    value={$fraudRulesPhones.map((phone) => phone.value).join("<br/>")}
                />
            </div>
        {/if}

        {#if emailBlockIsShown}
            <div class="my-3">
                <ItemWithLabel
                    label={$_("components.inputType.email")}
                    value={$fraudRulesEmails.join("<br/>")}
                />
            </div>
        {/if}
    </div>
</div>
