<script lang="ts">
    import { _ } from "svelte-i18n";

    import utils from "../../../../utilsV2/currency";
    import { DATA_TYPE } from "../productTable.constants";
    import type { IOrderItem } from "../../../../static/types";

    export let title = "";
    export let columns = [];
    export let items = [];
    export let locale = "";
    export let currency: any;
    export let taxNaming: string = "VAT";
    export let totalValue: number = 0;

    let netAmount: number = 0;
    let taxAmount: number = 0;
    let grossAmount: number = 0;
    let displayItems = [];

    $: {
        handleItemsChange(items);
    }

    function handleItemsChange(items) {
        displayItems = [];

        items.forEach((item) => {
            const existingItem = displayItems.find(
                (groupedItem) =>
                    groupedItem.name === item.name && groupedItem.unit_price === item.unit_price
            );

            if (existingItem) {
                existingItem.quantity += item.quantity;
            } else {
                displayItems.push(item);
            }
        });
        displayItems = displayItems;

        netAmount = 0;
        taxAmount = 0;
        grossAmount = 0;
        items.forEach((item) => {
            netAmount += Number(item.net_amount);
            taxAmount += Number(item.tax_amount);
            grossAmount += Number(item.gross_amount);
        });
    }

    function formatProductValue(product: IOrderItem, index, column) {
        const rawValue = column.propertyName ? product[column.propertyName] : index + 1;
        switch (column.type) {
            case DATA_TYPE.AMOUNT:
                return formatAmount(rawValue);
            case DATA_TYPE.PERCENT:
                return formatPercent(rawValue);
            default:
                return rawValue;
        }
    }

    function formatAmount(amount: number | string) {
        return utils.formatCurrency(currency)(amount, { locale });
    }

    function formatPercent(aNumber: number | string) {
        return (typeof aNumber === "string" ? parseFloat(aNumber) : aNumber).toLocaleString("en", {
            style: "percent"
        });
    }
</script>

<div class="scrollbar">
    <div class="main-bar">
        <span class="card-title">{title}</span>
    </div>

    <div class="product-table-wrap">
        <table class="table">
            <thead class="table-head">
                {#each columns as column}
                    <th
                        class="table-header table-header--{column.alignment}"
                        class:extend-margin={column.label === $_("order.edit.invoice_number") &&
                            items.length > 1}
                        style="width: {column.width}"
                    >
                        {@html column.label}
                    </th>
                {/each}
            </thead>
            {#each displayItems as item, index}
                <tr class="table-row">
                    {#each columns as column}
                        <td
                            class="table-cell table-cell--{column.alignment}"
                            class:table-cell--bold={column.bold}
                        >
                            {formatProductValue(item, index, column)}
                        </td>
                    {/each}
                </tr>
            {/each}
            <tr>
                <td colspan="5" />
                <td class="table-cell summary-cell table-cell--right">
                    {$_("order.edit.subtotal")}
                </td>
                <td class="summary-cell" />
                <td class="table-cell summary-cell table-cell--right">
                    {formatAmount(netAmount)}
                </td>
            </tr>
            <tr>
                <td colspan="5" />
                <td class="table-cell summary-cell table-cell--right">{taxNaming}</td>
                <td class="table-cell summary-cell table-cell--right" />
                <td class="table-cell summary-cell table-cell--right table-cell">
                    {formatAmount(taxAmount)}
                </td>
            </tr>
            <tr>
                <td colspan="5" />
                <td class="table-cell summary-cell table-cell--right table-cell table-cell--bold">
                    {$_("order.edit.total_invoiced")}
                </td>
                <td class="summary-cell" />
                <td class="table-cell summary-cell table-cell--right table-cell table-cell--bold">
                    {formatAmount(grossAmount)}
                </td>
            </tr>
            <tr>
                <td colspan="5" />
                <td class="table-cell table-cell--right table-cell"
                    >{$_("order.partialFulfil.total_remaining")}</td
                >
                <td />
                <td class="table-cell table-cell--right table-cell table-cell">
                    {formatAmount(totalValue - grossAmount)}
                </td>
            </tr>
        </table>
    </div>
</div>

<style lang="scss">
    .card {
        flex-basis: 100%;
        border-radius: 0.5rem;
        background-color: var(--basic);
        padding: 1.5rem;
        --tw-bg-opacity: 1;

        border: 1px solid var(--gray-100);
        box-shadow: 0 0 6px rgba(208, 213, 221, 0.25);

        &--table {
            padding: 0;
        }
    }

    .scrollbar {
        overflow: auto;
    }

    .card-title {
        display: flex;
        gap: 10px;
        font-weight: 700;
        line-height: 24px;
        padding: 24px;
    }

    .product-table-wrap {
        width: 100%;
    }

    .main-bar {
        display: flex;
        justify-content: space-between;
    }

    .table {
        width: 100%;
        min-width: 930px;
        table-layout: fixed;
        font-size: 14px;
        font-weight: 400;
        color: var(--gray-700);
        margin-bottom: 24px;

        border-collapse: collapse;
        border-spacing: unset;
    }

    th,
    td {
        cursor: default;
    }

    .table-head {
        background-color: var(--bgGeneralQuaternary);
        color: var(--ctnGeneralSecondary);
    }

    .table-header {
        width: 100%;
        padding: 0 8px;
        height: 48px;

        &:first-child {
            padding-left: 24px;
        }

        &.extend-margin {
            padding-left: 56px;
        }

        &:last-child {
            padding-right: 24px;
        }

        &--left {
            text-align: left;
        }

        &--right {
            text-align: right;
        }
    }

    .table-row {
        border-bottom: 1px solid var(--gray-200);
    }

    .table-cell {
        padding: 0 8px;
        font-weight: 400;

        &:first-child {
            padding-left: 24px;
        }

        &:last-child {
            padding-right: 24px;
        }

        &--left {
            text-align: left;
        }

        &--right {
            text-align: right;
        }

        &--bold {
            font-weight: 700;
            color: var(--gray-800);
        }
    }

    .summary-cell {
        border-bottom: 1px solid var(--gray-200);
    }
</style>
