<!-- This component is more universal version of the FiltersSidebar.svelte -->
<script lang="ts">
    import { onMount, tick } from "svelte";
    import Fa from "svelte-fa";
    import { faXmark } from "@fortawesome/free-solid-svg-icons";
    import { slide } from "svelte/transition";

    export let title;
    export let onClose;

    let contentRef;

    onMount(() => {
        document.body.style.overflow = "hidden";
        // scroll to top of the content
        tick().then(() => {
            if (contentRef) contentRef.scrollTop = 0;
        });
        return () => (document.body.style.overflow = "");
    });
</script>

<div
    class="drawer-sidebar-container"
    on:click|stopPropagation={onClose}
    role="button"
    tabindex={0}
    on:keyup
>
    <div
        on:click|stopPropagation
        role="button"
        tabindex={0}
        on:keyup
        transition:slide|global={{ delay: 300, duration: 250, axis: "x" }}
        class="drawer-sidebar"
    >
        <div
            class="flex justify-between text-2xl font-bold px-8 py-6 border-b border-Border-General-Primary"
        >
            {@html title}
            <button class="cursor-pointer" on:click={onClose}>
                <Fa icon={faXmark} size="md" color="var(--ctnGeneralTertiary)" />
            </button>
        </div>
        <div class="drawer-sidebar-main px-8 py-6" bind:this={contentRef}>
            <slot name="main" />
        </div>
        <div class="drawer-sidebar-footer px-8 py-6">
            <slot name="footer" />
        </div>
    </div>
</div>

<style>
    .drawer-sidebar-container {
        position: fixed;
        height: 100%;
        width: 100%;
        right: 0;
        background: rgba(0, 0, 0, 0.1);
        top: 0;
        z-index: 110;
        display: flex;
        justify-content: flex-end;
    }

    .drawer-sidebar {
        height: 100%;
        width: 42rem;
        right: 0;
        background: white;
        display: flex;
        flex-direction: column;
    }

    .drawer-sidebar-main {
        flex-grow: 1;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .drawer-sidebar-footer {
        box-shadow: 0px -3px 6px 0px rgba(208, 213, 221, 0.25);
        border-top: 1px solid var(--borderGeneralPrimary);
    }
</style>
