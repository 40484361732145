<script lang="ts">
    import Fa from "svelte-fa";
    import { _ } from "svelte-i18n";
    import { Dropdown, Button } from "@two-ui/core";
    import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
    import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
    import { createEventDispatcher } from "svelte";
    import { navigate } from "svelte-routing";
    import { capitalize } from "lodash";

    import type { CurrencyCode, ICustomer } from "@/static/types";
    import {
        customersLoading,
        singleCustomer,
        cursorPage,
        showCustomerSettings as canShowCustomerSettings,
        actions as customerActions,
        customers as customerStateCustomers
    } from "@/store/merchant/customers.store";
    import modalState from "@/store/modals.store";
    import { account as merchantAccount, recourseEnabled } from "@/store/merchant/account.store";
    import TableCell from "@/components/table/TableCell.svelte";
    import Switch from "@/components/Elements/Switch.svelte";
    import LoadingWrapper from "@/components/Elements/LoadingWrapper.svelte";
    import ToolTip from "@/components/tooltip/ToolTip.svelte";
    import Icon from "@/components/icons/Icon.svelte";
    import CombinedCustomersSetting from "./CombinedCustomersSetting.svelte";
    import utils, { type CurrencyFormatOptions } from "@/utilsV2/currency";
    import { getCurrency } from "@/utils/functions";
    import CursorPagination from "@/components/pagination/CursorPagination.svelte";
    import environment from "@/services/environment";
    import { buyerDetails } from "@/store/merchant/allOrders.store";
    import { CREATE_ORDER_PATH } from "../orders/variables";

    const dispatch = createEventDispatcher();

    export let selectedTab: string = "All";
    export let currentSort = "";
    export let recourseRelatedData: any;

    export let numAllRecords = 0;
    export let pageNum = 1;

    let isValidateCustomerRecourseLimit: boolean = false;

    let sortDown = true;

    let tableHeaders = [];
    let tableRows = [];
    let dropdownMounted = [];
    let dropdownReference = [];

    let prevPage = "";
    let nextPage = "";

    let customers: ICustomer[] = [];
    let initCustomersData: ICustomer[] = [];
    let selectedCustomer: ICustomer;
    let autoScrollSettings: boolean = false;

    const ORDER_CREATOR_ROUTE = `${CREATE_ORDER_PATH}/`;

    const COMMON_HEADERS = [
        {
            title: "Customer",
            translation: $_("customers.combined.customer"),
            clickable: false,
            type: "text"
        },
        {
            title: "status",
            translation: $_("account.document.status"),
            clickable: false,
            type: "text",
            tooltip: $_("customers.combined.statusTooltip", {
                values: { brandName: environment.branding.displayName }
            })
        },
        ...(environment.branding.hideCreditLimit
            ? []
            : [
                  {
                      title: "net-terms-limit",
                      translation: $_("order.new.limits.netTermsLimitText"),
                      clickable: false,
                      type: "number",
                      tooltip: $_("customers.combined.netTermsTooltip")
                  },
                  {
                      title: "instalment-limit",
                      translation: $_("order.new.limits.instalmentPlanLimit"),
                      clickable: false,
                      type: "number",
                      tooltip: $_("customers.combined.instalmentTooltip")
                  },
                  {
                      title: "recourse-limit",
                      translation: $_("customers.combined.recourseLimit"),
                      clickable: false,
                      type: "number",
                      tooltip: $_("customers.combined.recourseTooltip")
                  }
              ]),
        // ...(environment.branding.hideOnRecourse
        //     ? []
        //     : [
        //           {
        //               title: "On recourse",
        //               translation: $_("customers.combined.onRecourse"),
        //               clickable: false,
        //               type: "switch",
        //               tooltip: $_("customers.combined.onRecourseTooltip")
        //           }
        //       ]),
        {
            title: "total-spend",
            translation: $_("customers.combined.totalSpend"),
            clickable: false,
            type: "number",
            tooltip: $_("customers.combined.totalSpendTooltip")
        }
    ];

    const TABLE_HEADERS = {
        All: [...COMMON_HEADERS],
        "On recourse": [
            ...COMMON_HEADERS.filter((item) => item.title !== "instalment-limit"),
            {
                title: "current-recourse-exposure",
                translation: $_("customers.combined.currentRecourseExposure"),
                clickable: false,
                type: "number",
                tooltip: $_("customers.combined.currentRecourseExposureTooltip")
            }
        ]
    };

    $: if ($cursorPage) {
        prevPage = $cursorPage?.previous_page_cursor;
        nextPage = $cursorPage?.next_page_cursor;
    }

    $: if ($merchantAccount && Object.keys($merchantAccount).length) {
        // only if the account state is loaded
        const value = $merchantAccount;
        isValidateCustomerRecourseLimit = value.flags.enable_fe_validate_customer_recourse_limit;
    }

    $: merchantRecourseFallbackEnabled = $recourseEnabled;

    $: initCustomersData = $customerStateCustomers;

    $: if ($canShowCustomerSettings && merchantRecourseFallbackEnabled) {
        // merchant recourse fallback enabled only
        customerActions.getCustomerRecourseLimit(
            selectedCustomer?.buyer_organization_number,
            selectedCustomer?.buyer_country_code
        );

        // feature flag enable_fe_validate_customer_recourse_limit check
        if (isValidateCustomerRecourseLimit) {
            customerActions.getCustomerMaxAllowedRecourseLimit(
                selectedCustomer?.buyer_organization_number,
                selectedCustomer?.buyer_country_code
            );
        }
    }

    const formatMoney = (
        money: string | number,
        currency?: CurrencyCode,
        options?: CurrencyFormatOptions
    ) => utils.formatCurrency(currency)(money, options);

    function pageChanged(pageReference: string | null = null) {
        dispatch("pagechanged", {
            pageNum,
            pageReference
        });
    }

    function buildCustomersRows() {
        const tableRows = [];
        for (const customer of customers) {
            const currency: CurrencyCode = getCurrency(customer.buyer_country_code);
            const isWithOrders = customer.total_order_count > 0;
            const rowItem = {
                Customer: {
                    tableItemBold: customer.buyer_company_name.split(" ").map(capitalize).join(" "),
                    subItem: customer.buyer_organization_number
                },
                status: {
                    subItem: isWithOrders
                        ? $_("customers.combined.active")
                        : $_("customers.combined.lead"),
                    itemBgColor: isWithOrders
                        ? "var(--bgSemanticSuccessTertiary)"
                        : "var(--bgSemanticInfoTertiary)",
                    subItemTextColor: isWithOrders
                        ? "var(--ctnSemanticSuccessPrimary)"
                        : "var(--ctnSemanticInfoPrimary)",
                    subItemClasses: "rounded-full font-medium px-2 py-1"
                },
                "net-terms-limit": {
                    tableItem: formatMoney(customer.credit_limit_balance, currency),
                    subItem: $_("customers.combined.ofAmount", {
                        values: { amount: formatMoney(customer.credit_limit, currency) }
                    })
                },
                "instalment-limit": {
                    tableItem: formatMoney(customer.long_term_limit, currency),
                    subItem: $_("customers.combined.ofAmount", {
                        values: {
                            amount: formatMoney(customer.long_term_available_credit, currency)
                        }
                    })
                },
                "recourse-limit": {
                    tableItem: formatMoney(customer.remaining_buyer_recourse_limit, currency),
                    subItem: $_("customers.combined.ofAmount", {
                        values: { amount: formatMoney(customer.recourse_max_amount, currency) }
                    })
                },
                "total-spend": {
                    tableItem: formatMoney(customer.total_gross_amount, currency)
                },
                //"On recourse": customer.on_recourse,
                "current-recourse-exposure": {
                    tableItem: formatMoney(customer.current_recourse_exposure, currency)
                },
                onRowClick: () => {
                    singleCustomer.set(customer);
                    navigate(
                        `/merchant/customers/${customer.buyer_organization_number}?country=${customer.buyer_country_code}`
                    );
                }
            };
            tableRows.push(rowItem);
        }
        return tableRows;
    }

    $: {
        customers = initCustomersData;

        tableHeaders = TABLE_HEADERS[selectedTab];

        tableRows = buildCustomersRows();
        numAllRecords = tableRows.length;
    }

    function showCustomerSettings(customer: ICustomer, isScroll: boolean) {
        selectedCustomer = customer;
        autoScrollSettings = isScroll; // sets if we should autoscroll settings to recourse amount input field
        customerActions.toggleSettings(true);
    }

    function sortData(dataColumn) {
        dispatch("sorting", {
            sortItem: dataColumn
        });
        if (currentSort !== dataColumn) {
            currentSort = dataColumn;
            sortDown = true;
        } else {
            sortDown = !sortDown;
        }
    }

    function handleCustomerSettings(index) {
        showCustomerSettings(customers[index], false);
    }

    function handleCreateOrder(index) {
        buyerDetails.set({
            companyName: customers[index].buyer_company_name,
            companyNumber: customers[index].buyer_organization_number,
            countryCode: customers[index].buyer_country_code
        });
        navigate(ORDER_CREATOR_ROUTE);
    }

    function onToggleDropdown(e: CustomEvent, index: number) {
        e.stopPropagation();
        dropdownMounted[index] = !Boolean(dropdownMounted[index]);
    }
</script>

<div data-testid="customer-table" class="customer-table">
    <table class="table text-sm w-full table-auto text-left">
        <thead class="table-head">
            {#if tableHeaders && tableHeaders.length}
                {#each tableHeaders as tableHeader}
                    {#if (tableHeader.title == "On recourse" && merchantRecourseFallbackEnabled) || tableHeader.title !== "On recourse"}
                        <th
                            class="m-auto"
                            class:clickable={tableHeader.clickable}
                            class:date={tableHeader.type === "date"}
                            class:number={tableHeader.type === "number"}
                            class:status={tableHeader.type === "status"}
                            class:switch={tableHeader.type === "switch"}
                            on:click={() => sortData(tableHeader.title)}
                        >
                            <div
                                class="flex my-auto items-center mr-4"
                                class:mr-6={tableHeader.title === "status"}
                            >
                                {#if !tableHeader.notext}<div>
                                        {tableHeader.translation || tableHeader.title}
                                    </div>{/if}
                                {#if tableHeader.clickable}
                                    <div
                                        class="my-auto px-2"
                                        class:icon-rotate={currentSort === tableHeader.title &&
                                            sortDown}
                                    >
                                        <Icon
                                            name="arrow-up"
                                            size={20}
                                            fill={currentSort === tableHeader.title
                                                ? "var(--ctnInteractiveSemanticHighlightDefault)"
                                                : "grey"}
                                        />
                                    </div>
                                {:else if tableHeader.tooltip}
                                    <div class="tooltip-container" style="margin-top: 2px">
                                        <ToolTip
                                            width="250px"
                                            position="left-down"
                                            content={tableHeader.tooltip}
                                        >
                                            <Fa
                                                size="md"
                                                icon={faCircleQuestion}
                                                color="var(--primary-500)"
                                            />
                                        </ToolTip>
                                    </div>
                                {/if}
                            </div>
                        </th>
                    {/if}
                {/each}
            {/if}
            <th />
        </thead>
        {#if $customersLoading}
            <div class="relative w-full h-28 table-row">
                <LoadingWrapper background={"white"} loading={$customersLoading} />
            </div>
        {/if}
        <tbody class:hidden={$customersLoading}>
            {#each tableRows as tableRow, index}
                <tr on:click={() => tableRow.onRowClick()}>
                    {#each tableHeaders as tableHeader}
                        {#if (tableHeader.title == "On recourse" && merchantRecourseFallbackEnabled) || tableHeader.title !== "On recourse"}
                            <td>
                                {#if tableHeader.type == "switch"}
                                    <span
                                        on:click|stopPropagation
                                        on:keyup
                                        role="button"
                                        tabindex="0"
                                    >
                                        <Switch
                                            bind:checked={tableRow[tableHeader.title]}
                                            on:change={() => {
                                                if (tableRow[tableHeader.title]) {
                                                    const data = {
                                                        buyer_organization_number:
                                                            customers[index]
                                                                ?.buyer_organization_number,
                                                        buyer_country_code:
                                                            customers[index]?.buyer_country_code,
                                                        max_amount: "0",
                                                        currency: getCurrency(
                                                            customers[index]?.buyer_country_code
                                                        )
                                                    };

                                                    modalState.actions.setModal(
                                                        "CONFIRM_ENABLE_CUSTOMER_RECOURSE_FALLBACK",
                                                        {
                                                            data,
                                                            customer_name:
                                                                customers[index]
                                                                    ?.buyer_company_name,
                                                            is_disable: true
                                                        }
                                                    );
                                                } else {
                                                    showCustomerSettings(customers[index], true);
                                                }
                                            }}
                                        />
                                    </span>
                                {:else}
                                    <TableCell
                                        cellItem={tableRow[tableHeader.title]}
                                        type={tableHeader.type}
                                    />
                                {/if}
                            </td>
                        {/if}
                    {/each}
                    {#if !environment.branding.hideCustomerConfig}
                        <td>
                            <Button
                                on:click={(e) => onToggleDropdown(e, index)}
                                size="sm"
                                variant="secondaryGray"
                                content=""
                                class="select-options mr-2"
                                bind:element={dropdownReference[index]}
                            >
                                <Fa
                                    size="lg"
                                    icon={faEllipsisV}
                                    color="var(--ctnInteractiveGeneralDefault)"
                                    slot="leftIcon"
                                />
                            </Button>

                            <Dropdown
                                id={`customer-actions-${index}`}
                                bind:isMounted={dropdownMounted[index]}
                                reference={dropdownReference[index]}
                                position="left"
                            >
                                <div>
                                    <button
                                        class="px-4 py-2 cursor-pointer w-full text-left text-black hover:bg-Background-Interactive-States-Semantic-Highlight-Hover-Secondary"
                                        on:click|stopPropagation={() =>
                                            handleCustomerSettings(index)}
                                    >
                                        {$_("customer.customerSettings")}
                                    </button>
                                    <button
                                        class="px-4 py-2 cursor-pointer w-full text-left text-black hover:bg-Background-Interactive-States-Semantic-Highlight-Hover-Secondary"
                                        on:click={() => handleCreateOrder(index)}
                                    >
                                        {$_("order.new.create")}
                                    </button>
                                </div>
                            </Dropdown>
                        </td>
                    {/if}
                </tr>
            {/each}

            {#if customers.length === 0 && !$customersLoading}
                <tr>
                    <td colspan="10">
                        <div class="w-full flex flex-col items-center pt-14">
                            <img src="/pictures/searchstate.svg" alt="mailbox" />
                            <p class="my-4 font-semibold text-black-dark text-center">
                                {$_("customers.combined.noCustomersMessage")}
                            </p>
                            <p class="text-center mb-5">
                                {$_("customers.combined.noCustomersDescription", {
                                    values: { brandName: environment.branding.displayName }
                                })}
                            </p>
                        </div>
                    </td>
                </tr>
            {/if}
        </tbody>
    </table>

    {#if customers.length > 0 && !$customersLoading}
        <CursorPagination
            loading={$customersLoading}
            setPage={pageChanged}
            rowCount={tableRows.length}
            {nextPage}
            {prevPage}
            bind:pageNum
        />
    {/if}

    {#if $canShowCustomerSettings}
        <CombinedCustomersSetting {selectedCustomer} {autoScrollSettings} {recourseRelatedData} />
    {/if}
</div>

<style>
    .select-options {
        display: flex;
        width: 44px;
        height: 44px;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        position: relative;
        &:hover {
            background: var(--bgGeneralPrimary);
        }
    }
    .customer-table {
        background-color: rgb(243, 244, 247);
        overflow: auto;
    }
    .customer-table .table {
        border-spacing: 0 2px;
    }

    td:first-child,
    th:first-child {
        padding-left: 2rem;
    }

    th {
        top: 0;
        background-color: var(--bgGeneralQuaternary);
        height: 3rem;
        cursor: default;
        z-index: 1;
    }

    .clickable {
        cursor: pointer;
    }

    tr {
        background: white;
        height: 4.5rem;
        cursor: var(--cursor, pointer);
    }

    tr:not(.loading-tr):hover {
        background-color: var(--bgInteractiveSemanticHighlightHoverSecondary);
    }

    .date {
        width: 13rem;
    }

    .number {
        width: 12rem;
    }

    .status {
        width: 12rem;
        z-index: 15;
    }

    .tooltip-container {
        margin: auto 0.5rem;
    }

    @media only screen and (max-width: 1300px) {
        .date {
            width: 10rem;
        }

        .number {
            width: 8rem;
        }

        .status {
            width: 8rem;
        }
    }

    table {
        overflow: auto;
    }
</style>
