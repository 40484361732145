import axios from "axios";
import type { AxiosRequestConfig } from "axios";

import { getBaseUrl } from "../utils/functions";
import { getFingerprintHeaders } from "./fingerprint";

let requestController: AbortController | null = null;

export const searchApi = axios.create({
    withCredentials: false,
    baseURL: getBaseUrl()
});

// Create a instance of axios to use the same base url.
const portalAPI = (isCredentialsSkipped: boolean) => {
    let baseUrl = getBaseUrl();
    return axios.create({
        baseURL: baseUrl,
        withCredentials: !isCredentialsSkipped,
        signal: requestController.signal
    });
};

export const cancelRequest = () => {
    if (requestController) {
        requestController.abort();
        requestController = null;
    }
};

// implement a method to execute all the request from here.
const apiRequest = ({ method, url, data, headers }: AxiosRequestConfig) => {
    //check if the url is for the merchant portal to set the cookie
    requestController = new AbortController();

    headers = {
        ...headers,
        "Access-Control-Allow-Origin": "*"
    };
    const pathsWithoutCredentials = [
        "v1/portal/merchant",
        "v1/portal/buyer/",
        "v1/portal/logout",
        "v1/monboarding",
        "v1/get_svix_app_portal_url",
        "v2/portal/merchant",
        "billing/v1",
        "v1/portal/mi/order",
        "/v1/mi/orders",
        "/v1/order/",
        "/v1/portal/merchant/buyer_credit_limit",
        "/v1/portal/merchant/risk_metrics/overview",
        "/v1/invoice/",
        "/merchant/recourse_limit",
        "/merchant/buyer_recourse_limit"
    ];

    const isCredentialsSkipped = pathsWithoutCredentials.every((path) => url.includes(path));

    return new Promise((resolve, reject) => {
        getFingerprintHeaders()
            .then((fingerprintHeaders) => {
                if (fingerprintHeaders) {
                    headers = { ...headers, ...fingerprintHeaders };
                }

                return portalAPI(isCredentialsSkipped)({ method, url, data, headers });
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err instanceof Error ? err : new Error(err));
            });
    });
};

// function to execute the http get request
const get = (url: string, data?: any, headers?: any) =>
    apiRequest({ method: "get", url, data, headers });

// function to execute the http delete request
const deleteRequest = (url: string, data: any) => apiRequest({ method: "delete", url, data });

// function to execute the http post request
const post = (url: string, data?: any, headers?: any) =>
    apiRequest({ method: "post", url, data, headers });

// function to execute the http put request
const put = (url: string, data: any) => apiRequest({ method: "put", url, data });

// function to execute the http path request
const patch = (url: string, data: any) => apiRequest({ method: "patch", url, data });

// expose your method to other services or actions
const API = {
    get,
    delete: deleteRequest,
    post,
    put,
    patch,
    cancelRequest
};

export default API;
