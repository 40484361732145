import moment from "moment";
import { type CurrencyCode } from "@/static/types";

export type FraudListItem = {
    uuid: string;
    status: "Order" | "Order Intent" | "Onboarding";
    companyName: string;
    companyId: string;
    dateTime: string;
    amount: string;
    currency: CurrencyCode;
};

const fraudList: FraudListItem[] = [
    {
        uuid: "1e456b1c-4f5a-4f67-9b3d-1d4f75ad74e1",
        status: "Order",
        companyName: "Big Spender AS",
        companyId: "1234567890",
        dateTime: "2024-12-20T08:30:15",
        amount: "5000.00",
        currency: "NOK"
    },
    {
        uuid: "2c9d8e4b-7f8c-41c7-819f-987fa6c1e1b1",
        status: "Onboarding",
        companyName: "New Buyer AS",
        companyId: "9923673523",
        dateTime: "2024-12-19T09:15:45",
        amount: null,
        currency: null
    },
    {
        uuid: "3f7d1a5b-9e2c-4f7a-b5d9-123b4c5a76f4",
        status: "Order",
        companyName: "Food & Wine AS",
        companyId: "5675765203",
        dateTime: "2024-12-18T10:00:30",
        amount: "1000.00",
        currency: "NOK"
    },
    {
        uuid: "df79ab17-1732-4355-99e8-91d9ac98231b",
        status: "Order Intent",
        companyName: "I Want to Buy RN",
        companyId: "9645785612",
        dateTime: "2024-12-18T10:20:30",
        amount: "1250.00",
        currency: "NOK"
    },
    {
        uuid: "4a2d9b3e-7c5f-4e6a-81bd-76f9a2e5c4f1",
        status: "Order",
        companyName: "Electronics Hub AS",
        companyId: "1239874560",
        dateTime: "2024-12-18T11:45:50",
        amount: "2500.00",
        currency: "NOK"
    },
    {
        uuid: "5f3e1b7c-6f5d-4a9c-97b1-23c5a7f8d4e1",
        status: "Onboarding",
        companyName: "Smart Solutions AS",
        companyId: "9987654321",
        dateTime: "2024-12-17T13:10:05",
        amount: null,
        currency: null
    },
    {
        uuid: "6e2b4c5a-8d9f-4f7a-a3e2-76f1a9b5c7d1",
        status: "Order",
        companyName: "Tech & Co AS",
        companyId: "4545454545",
        dateTime: "2024-12-17T14:25:20",
        amount: "3000.00",
        currency: "NOK"
    },
    {
        uuid: "7a6d5b9c-3e2f-4a7f-91bd-23f5a7c9b4e1",
        status: "Order",
        companyName: "Best Services AS",
        companyId: "7654321098",
        dateTime: "2024-12-16T15:40:35",
        amount: "1500.00",
        currency: "NOK"
    },
    {
        uuid: "8d3b1c7e-4f5a-4f7a-9e2b-23f1c5d9a7e1",
        status: "Onboarding",
        companyName: "Quality Goods AS",
        companyId: "2233445566",
        dateTime: "2024-12-15T16:55:50",
        amount: null,
        currency: null
    },
    {
        uuid: "9b7c1a6d-3e2f-4f5a-97c1-23f9b4e7a5d1",
        status: "Order",
        companyName: "Premium Partners AS",
        companyId: "7788990011",
        dateTime: "2024-12-14T17:05:45",
        amount: "8000.00",
        currency: "NOK"
    },
    {
        uuid: "0c2b5d9e-3f7a-4e2f-91a7-76f5c9a4b1d1",
        status: "Order",
        companyName: "Super Deals AS",
        companyId: "5544332211",
        dateTime: "2024-12-13T18:20:25",
        amount: "4500.00",
        currency: "NOK"
    }
];

export function adaptFraudDataToUI() {
    return fraudList
        .sort((a, b) => {
            const dateA = new Date(a.dateTime).getTime();
            const dateB = new Date(b.dateTime).getTime();
            return dateB - dateA;
        })
        .map((item) => ({
            ...item,
            dateTime: moment(item.dateTime).format("DD/MM/YYYY HH:MM:SS")
        }));
}
