<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";

    import MetaWrapper from "../../../components/metadata/MetaWrapper.svelte";
    import modalState from "../../../store/modals.store";
    import { account } from "../../../store/merchant/account.store";
    import { Button as TwoUIButton } from "@two-ui/core";
    import { frameList } from "../../../static/itemList";
    import { NotificationType } from "../../../static/types";
    import PageHeader from "../../../components/pageHeader/PageHeader.svelte";
    import {
        DOCS_URL,
        GET_SVIX_APP_PORTAL_URL,
        GET_ORDER_SUMMARY
    } from "../../../static/endPoints";
    import API from "../../../services/apiService";
    import notificationState from "../../../store/notifications.store";
    import environment from "@/services/environment";
    import ExternalLinkIcon from "../../../components/icons/external-link.svelte";

    interface IResSwixPortalUrl {
        token?: string;
        url?: string;
    }

    interface IOrderSummary {
        count: number;
        state: string;
        status: string;
        invoice_type: string;
        peppol_compliant: boolean;
        has_delivery_method: boolean;
    }

    interface ICheckListItem {
        label: string;
        value: boolean;
        documentationLink?: string;
    }

    interface IChecklist {
        unverified_orders: ICheckListItem;
        cancelled_orders: ICheckListItem;
        verified_orders: ICheckListItem;
        confirmed_orders: ICheckListItem;
        fulfilled_orders: ICheckListItem;
        refunded_orders: ICheckListItem;
        partial_orders: ICheckListItem;
        approved_orders: ICheckListItem;
        rejected_orders: ICheckListItem;
        funded_invoice_orders: ICheckListItem;
        peppol_compliant_orders: ICheckListItem;
        delivery_method_orders: ICheckListItem;
    }

    const TABS = ["General", "Integration progress"];
    const tabsTranslations = {
        General: $_("integration.tabs.general"),
        "Integration progress": $_("integration.tabs.progress")
    };
    let selectedTab: string = "General";

    const setTab = (tab: string) => {
        selectedTab = tab;
        navigate(`/merchant/integration?tab=${tab}`);
    };

    let apiKeyManagementEnabled: boolean = false;
    let webhooksEnabled: boolean = false;
    let isLoadingWebhooksUrl: boolean = false;
    let orderSummary: IOrderSummary[] = [];
    const documentationBaseUrlCheckoutAPI = `${DOCS_URL}/openapi/checkout-api`;
    const checklist: IChecklist = {
        unverified_orders: {
            label: $_("integration.checklist.unverifiedOrders"),
            value: false,
            documentationLink: `${documentationBaseUrlCheckoutAPI}`
        },
        cancelled_orders: {
            label: $_("integration.checklist.cancelledOrders"),
            value: false,
            documentationLink: `${documentationBaseUrlCheckoutAPI}/#operation/cancel_order_handler.post`
        },
        verified_orders: {
            label: $_("integration.checklist.verifiedOrders"),
            value: false
        },
        confirmed_orders: {
            label: $_("integration.checklist.confirmedOrders"),
            value: false,
            documentationLink: `${documentationBaseUrlCheckoutAPI}/#operation/mark_confirmed_order_handler.post`
        },
        fulfilled_orders: {
            label: $_("integration.checklist.fulfilledOrders"),
            value: false,
            documentationLink: `${documentationBaseUrlCheckoutAPI}/#operation/mark_fulfilled_order_handler.post`
        },
        refunded_orders: {
            label: $_("integration.checklist.refundedOrders"),
            value: false,
            documentationLink: `${documentationBaseUrlCheckoutAPI}/#operation/refund_order_handler.post`
        },
        partial_orders: {
            label: $_("integration.checklist.partialOrders"),
            value: false,
            documentationLink: `${documentationBaseUrlCheckoutAPI}/#operation/fulfill_order_handler.post`
        },
        approved_orders: {
            label: $_("integration.checklist.approvedOrders", {
                values: { brandName: environment.branding.displayName }
            }),
            value: false
        },
        rejected_orders: {
            label: $_("integration.checklist.rejectedOrders", {
                values: { brandName: environment.branding.displayName }
            }),
            value: false
        },
        funded_invoice_orders: {
            label: $_("integration.checklist.fundedInvoiceOrders"),
            value: false,
            documentationLink: `${documentationBaseUrlCheckoutAPI}/#operation/create_order_handler.post!path=invoice_type&t=request`
        },
        peppol_compliant_orders: {
            label: $_("integration.checklist.peppolOrders"),
            value: false,
            documentationLink: `${documentationBaseUrlCheckoutAPI}/#operation/create_order_handler.post!path=tax_subtotals&t=request`
        },
        delivery_method_orders: {
            label: $_("integration.checklist.deliveryMethodOrders"),
            value: false,
            documentationLink: `${documentationBaseUrlCheckoutAPI}/#operation/create_order_handler.post!path=shipping_details/delivery_method&t=request`
        }
    };

    let checkListKeys;
    let checkListValues;

    function updateChecklistForItem(item, checklist) {
        if (item.peppol_compliant) checklist.peppol_compliant_orders.value = true;
        if (item.has_delivery_method) checklist.delivery_method_orders.value = true;
        if (item.invoice_type === "FUNDED_INVOICE") checklist.funded_invoice_orders.value = true;
        if (item.status === "APPROVED") checklist.approved_orders.value = true;
        if (item.status === "REJECTED") checklist.rejected_orders.value = true;
        if (item.status === "PARTIAL") checklist.partial_orders.value = true;

        updateChecklistForState(item.state, checklist);
    }

    function updateChecklistForState(state, checklist) {
        const commonStates = [
            "UNVERIFIED",
            "CANCELLED",
            "VERIFIED",
            "CONFIRMED",
            "FULFILLING",
            "FULFILLED",
            "DELIVERED",
            "REFUNDED"
        ];

        if (commonStates.includes(state)) {
            checklist.unverified_orders.value = true;

            if (state === "CANCELLED") {
                checklist.cancelled_orders.value = true;
            }

            if (
                [
                    "VERIFIED",
                    "CONFIRMED",
                    "FULFILLING",
                    "FULFILLED",
                    "DELIVERED",
                    "REFUNDED"
                ].includes(state)
            ) {
                checklist.verified_orders.value = true;
                checklist.confirmed_orders.value = true;

                if (["FULFILLING", "FULFILLED", "DELIVERED", "REFUNDED"].includes(state)) {
                    checklist.fulfilled_orders.value = true;

                    if (state === "REFUNDED") {
                        checklist.refunded_orders.value = true;
                    }
                }
            }
        }
    }

    $: if (orderSummary.length > 0) {
        orderSummary.forEach((item) => {
            updateChecklistForItem(item, checklist);
        });
    }

    $: if ($account && Object.keys($account).length) {
        // only if the account state is loaded
        apiKeyManagementEnabled = $account.flags.api_key_management_enabled;
        webhooksEnabled = $account.flags.webhooks_enabled;
    }

    const getOrderSummary = () => {
        API.get(GET_ORDER_SUMMARY)
            .then((res: IOrderSummary[]) => {
                orderSummary = [...res];
            })
            .catch((error) => {
                console.error(error);
                notificationState.actions.create(
                    NotificationType.ERROR,
                    "Error fetching order summary"
                );
            });
    };

    const handleManageAPIKey = () => {
        modalState.actions.setModal("API_KEY", {});
    };

    const handleManageSandboxAPIKey = () => {
        modalState.actions.setModal("SANDBOX_API_KEY", {});
    };

    const handleViewAPIDocs = () => {
        window.open(`${DOCS_URL}/developer-portal/api/`, "_blank");
    };

    const handleGetPlugin = (link) => {
        window.open(link, "_blank");
    };

    const handleGetInstallationGuide = (link) => {
        window.open(link, "_blank");
    };

    const handleWebhookSubscriptions = () => {
        isLoadingWebhooksUrl = true;
        API.get(`${GET_SVIX_APP_PORTAL_URL}`)
            .then((res: IResSwixPortalUrl) => {
                if (res) {
                    window.open(res.url, "_blank");
                }
            })
            .catch((error) => {
                console.error(error);
                notificationState.actions.create(
                    NotificationType.ERROR,
                    "Error in fetching svix portal url"
                );
            })
            .finally(() => {
                isLoadingWebhooksUrl = false;
            });
    };

    const handleIntegrationProgress = (checkList: IChecklist) => {
        const numberOfChecks = checkListKeys.length;

        let totalPassedChecks = 0;
        checkListValues.forEach((item: ICheckListItem) => {
            if (item.value === true) {
                totalPassedChecks += 1;
            }
        });

        const percentageProgress = (totalPassedChecks / numberOfChecks) * 100;

        return Math.ceil(percentageProgress);
    };

    const IntegrationButtonProps = {
        bgColor: "transparent",
        color: "#344054",
        borderColor: "#D0D5DD",
        hoverBg: "transparent",
        hoverColor: "#344054",
        paddingX: "16px",
        paddingY: "10px"
    };

    const IntegrationPluginButtonProps = {
        size: "small",
        bgColor: "transparent",
        color: "#344054",
        borderColor: "#D0D5DD",
        hoverBg: "transparent",
        hoverColor: "#344054"
    };

    const IntegrationPluginButtonPropsAlt = {
        size: "small",
        bgColor: "transparent",
        color: "var(--ctnGeneralPrimary)",
        hoverBg: "transparent",
        hoverColor: "#344054"
    };

    $: {
        if ($account.partner_short_name === "abn") {
            checkListKeys = Object.keys(checklist).slice(0, 7);
            checkListValues = Object.values(checklist).slice(0, 7);
        } else {
            checkListKeys = Object.keys(checklist);
            checkListValues = Object.values(checklist);
        }
    }

    onMount(getOrderSummary);
</script>

<MetaWrapper title={$_("integration.title")}>
    <div class="page-container">
        <PageHeader title="Integration" newUser={!$account?.flags?.has_made_order}>
            <div slot="tabs" class="tab-headers">
                {#each TABS as tab}
                    <!-- svelte-ignore a11y-click-events-have-key-events a11y-no-static-element-interactions -->
                    <div
                        class="cursor-pointer px-6 mr-2 h-10 tab-header"
                        on:click={() => setTab(tab)}
                        class:selected={selectedTab === tab}
                    >
                        {tabsTranslations[tab]}
                    </div>
                {/each}
            </div>
        </PageHeader>
        <div class="content-container">
            {#if selectedTab === "Integration progress"}
                <div class="white-background-container integration-progress-container">
                    <div class="flex justify-between mb-2">
                        <p class=" integration-page-heading">
                            {$_("integration.progress.heading")}
                        </p>
                        <div class="integration-progress-bar">
                            {$_("integration.progress.completed", {
                                values: { percentage: handleIntegrationProgress(checklist) }
                            })}
                        </div>
                    </div>

                    <p class="integration-checklist-sub-text">
                        {@html $_("integration.progress.subtext", {
                            values: {
                                sandboxLink:
                                    '<a class="cursor-pointer ctn-semantic-highlight-primary" href="https://portal.sandbox.two.inc/merchant">' +
                                    $_("integration.sandboxLink") +
                                    "</a>",
                                emailLink:
                                    '<a class="cursor-pointer ctn-semantic-highlight-primary" href="mailto:integration@two.inc">' +
                                    $_("integration.emailLink") +
                                    "</a>"
                            }
                        })}
                    </p>
                    <div class="integration-checklist-steps-container">
                        <p class="integration-page-heading mb-4">
                            {$_("integration.progress.preLaunchRequirements")}
                        </p>
                        {#each checkListKeys as key, index}
                            <div class="flex mb-3 items-center">
                                <div class="w-4 h-4 mr-2">
                                    {#if checklist[key].value}
                                        <img src="/pictures/check_circle.svg" alt="complete" />
                                    {:else}
                                        <img
                                            src="/pictures/error.svg"
                                            alt="incomplete"
                                            class="incomplete"
                                        />
                                    {/if}
                                </div>
                                <div class=" flex items-center text-base ctn-general-primary">
                                    {index + 1}. {checklist[key].label}
                                    {#if checklist[key].documentationLink}
                                        <a
                                            class="ml-1"
                                            href={checklist[key].documentationLink}
                                            target="_blank"
                                        >
                                            <ExternalLinkIcon fill="var(--primary-500)" />
                                        </a>
                                    {/if}
                                </div>
                            </div>
                        {/each}
                    </div>
                </div>
            {/if}
            {#if selectedTab === "General"}
                <div class="white-background-container general-container">
                    {#if $account.partner_short_name !== "abn"}
                        <div class="integration-page-heading">
                            <img src="/pictures/book-open.svg" alt="book" class="w-6 h-6" />
                            <p>{$_("integration.general.heading.apiDocumentation")}</p>
                        </div>

                        <p class="text-base ctn-general-primary mt-5 mb-6">
                            {$_("integration.general.subtext.apiDocumentation", {
                                values: { brandName: environment.branding.displayName }
                            })}
                            <br />
                            {#if apiKeyManagementEnabled}
                                {$_("integration.general.subtext.extra.withApiKey")}
                            {:else}
                                {$_("integration.general.subtext.extra.withoutApiKey")}
                                <br />
                            {/if}
                            {@html $_("integration.doNotHesitateToContact")}
                        </p>
                    {/if}
                    {#if $account.partner_short_name !== "abn"}
                        <div
                            class="mt-6 mb-6 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 sm:gap-4 xl:gap-7"
                        >
                            <div class=" mb-4 sm:mr-5 sm:mb-0" style="width : max-content">
                                <TwoUIButton
                                    on:click={handleViewAPIDocs}
                                    size="md"
                                    variant="secondaryGray"
                                    content={$_("integration.general.button.viewApiDocs")}
                                    fullSize={true}
                                />
                            </div>
                        </div>
                        <div class="integration-page-line"></div>
                    {/if}
                    <div class="integration-page-heading">
                        <img src="/pictures/layers.svg" alt="book" class="w-6 h-6" />
                        <p>{$_("integration.general.heading.environments")}</p>
                    </div>
                    <div class="mt-6 mb-6" style="display: flex; gap:8px;  flex-wrap:wrap;">
                        <div class="mb-4 sm:mr-5 sm:mb-0" style="width : max-content">
                            <TwoUIButton
                                on:click={handleManageSandboxAPIKey}
                                size="md"
                                variant="secondaryGray"
                                content={$_("integration.general.button.manageSandboxApiKeys")}
                                fullSize={true}
                            />
                        </div>
                        {#if apiKeyManagementEnabled}
                            <div class="h-0" />
                            <div class="mb-4 sm:mr-5 sm:mb-0" style="width : max-content">
                                <TwoUIButton
                                    on:click={handleManageAPIKey}
                                    size="md"
                                    variant="secondaryGray"
                                    content={$_("integration.general.button.manageProdApiKeys")}
                                    fullSize={true}
                                />
                            </div>
                        {/if}
                    </div>
                    <div class="integration-page-line"></div>

                    {#if webhooksEnabled}
                        <div class="integration-page-heading">
                            <img src="/pictures/square-wire-frame.svg" alt="book" class="w-6 h-6" />
                            <p>{$_("integration.general.heading.webhooks")}</p>
                        </div>
                        <div
                            class="mt-6 mb-6 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-2 sm:gap-4 xl:gap-7"
                        >
                            <div class="mb-4 sm:mr-5 sm:mb-0" style="width : max-content">
                                <TwoUIButton
                                    on:click={handleWebhookSubscriptions}
                                    size="md"
                                    variant="secondaryGray"
                                    content={$_(
                                        "integration.general.button.manageWebhookSubscriptions"
                                    )}
                                    fullSize={true}
                                    loading={isLoadingWebhooksUrl}
                                />
                            </div>
                        </div>
                    {/if}
                </div>

                {#if $account.partner_short_name !== "abn"}
                    <div class="integration-plugins-container">
                        {#each frameList as frameItem}
                            <div class="white-background-container" style="width: 100%;">
                                <img
                                    class="integration-plugin-image"
                                    src={frameItem.imgLink}
                                    alt={frameItem.alt}
                                />

                                <div class="flex w-full mt-4 items-center">
                                    <img
                                        src="/pictures/clock.svg"
                                        alt="clock"
                                        class="shrink-0 w-5 h-5"
                                    />
                                    <div class="w-full pl-4">
                                        <p class="text-user-gray font-normal text-sm">
                                            {$_("integration.general.averageSetupTime.label")}
                                        </p>
                                        <div
                                            class="text-gray-800 px-2 py-1 inline-block text-base font-medium"
                                        >
                                            {frameItem.hour}
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full my-2">
                                    <TwoUIButton
                                        on:click={() => handleGetPlugin(frameItem.pluginLink)}
                                        size="sm"
                                        variant="secondaryGray"
                                        content={$_("integration.general.button.getPlugin")}
                                        fullSize={true}
                                    />
                                </div>
                                <div class="w-full">
                                    <TwoUIButton
                                        on:click={() =>
                                            handleGetInstallationGuide(frameItem.guideLink)}
                                        size="sm"
                                        variant="tertiaryGrey"
                                        content={$_("integration.general.button.installationGuide")}
                                        fullSize={true}
                                    />
                                </div>
                            </div>
                        {/each}
                    </div>
                {/if}
            {/if}
        </div>
    </div>
</MetaWrapper>

<style>
    /*@import "./styles.css";*/

    .incomplete {
        /* filter: saturate(0%) brightness(200%); */
        color: #98a2b3;
        fill: #fff;
    }

    /* CSS FOR THE INTEGRATION PAGE */
    .content-container {
        padding: 1rem;
        grid-row-gap: 24px;
    }

    .general-container {
        width: 756px;
    }

    .white-background-container {
        display: flex;
        flex-direction: column;
        background: white;
        border-radius: 8px;
        border: solid 1px #e4e7ec;
        padding: 24px;
        color: #e4e7ec;
    }

    @media screen and (max-width: 768px) {
        .content-container {
            justify-content: center;
            align-items: center;
        }
        .general-container {
            width: auto;
        }
    }
    @media screen and (max-width: 1000px) {
        .general-container {
            width: 100%;
        }
    }

    .integration-checklist-sub-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: var(--ctnGeneralPrimary);
    }

    .integration-checklist-steps-container {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        width: 100%;
        border-radius: 4px;
        border: 1px solid var(--gray-200, #e4e7ec);
        padding: 16px;
        align-items: flex-start;
    }

    .integration-page-heading {
        display: flex;
        flex-direction: row;
        gap: 12px;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: var(--ctnGeneralPrimary);
    }

    .integration-progress-bar {
        display: flex;
        padding: 2px 12px;
        align-items: flex-start;
        gap: 8px;
        border-radius: 32px;
        background: var(--bgSemanticInfoTertiary);
        width: max-content;
        justify-content: center;

        color: var(--ctnSemanticInfoPrimary);
        font-feature-settings:
            "clig" off,
            "liga" off;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
    }

    .integration-page-line {
        height: 1px;
        align-self: stretch;
        background: linear-gradient(90deg, #e4e7ec 27.07%, rgba(228, 231, 236, 0) 90.05%);
        margin-bottom: 24px;
    }
    .integration-plugins-container {
        display: flex;
        width: 756px;
        align-items: flex-start;
        gap: 24px;
    }

    .integration-plugin-image {
        width: 117px;
        height: 24px;
        float: left;
    }

    .integration-progress-container {
        width: 756px;
    }

    @media screen and (max-width: 1000px) {
        .integration-plugins-container {
            width: 100%;
        }
    }

    @media screen and (max-width: 768px) {
        .integration-plugins-container {
            flex-direction: column;
        }
        .integration-plugin-image {
            min-height: 48px;
            min-width: 234px;
            align-items: start;
        }
        .integration-progress-container {
            width: 100%;
        }
    }
</style>
