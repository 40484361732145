<script lang="ts">
    import { _ } from "svelte-i18n";

    import MetaWrapper from "@/components/metadata/MetaWrapper.svelte";
    import type { IMorderSummary, IRefundSurcharge } from "@/static/types";
    import { DATA_TYPE, type ILineItemColumnProperties } from "../productTable.constants";
    import utils from "@/utilsV2/currency";

    export let isRefundProcess: boolean = false;
    export let order: IMorderSummary | undefined;
    export let totalOrderRefundedAmount: number = 0;
    export let totalOrderRefundedVAT: number = 0;
    export let taxNaming = $_("order.partialFulfil.vat");
    export let tableColumns;
    export let colspan: number = 0;

    const countryLocale = {
        GB: "en-GB",
        NO: "nb-NO",
        SE: "sv-SE"
    };

    function formatProductValue(product: ILineItemColumnProperties, index, column) {
        const rawValue = column.propertyName ? product[column.propertyName] : index + 1;
        switch (column.type) {
            case DATA_TYPE.AMOUNT:
                return formatAmount(
                    column.propertyName === "discount_amount" ? Math.abs(rawValue) : rawValue
                );
            case DATA_TYPE.PERCENT:
                return formatPercent(rawValue);
            default:
                return rawValue;
        }
    }

    function formatAmount(amount: number | string) {
        const locale = countryLocale[order.country];
        return utils.formatCurrency(order.currency)(amount, { locale });
    }

    function formatPercent(aNumber: number | string) {
        return (typeof aNumber === "string" ? parseFloat(aNumber) : aNumber).toLocaleString("en", {
            style: "percent"
        });
    }

    function formatSurchargeToMatchColumnTypes(refund_surcharge: IRefundSurcharge) {
        return <ILineItemColumnProperties>{
            name: refund_surcharge.surcharge_type,
            unit_price: refund_surcharge.amount,
            quantity: 1,
            discount_amount: 0,
            net_amount: refund_surcharge.amount,
            tax_rate: refund_surcharge.tax_rate,
            gross_amount:
                refund_surcharge.amount + refund_surcharge.amount * refund_surcharge.tax_rate
        };
    }
</script>

<MetaWrapper>
    <div class="scrollbar">
        <span class="card-title">{$_("order.refundedItems")}</span>

        <table class="table">
            <thead class="table-head">
                {#if isRefundProcess}
                    <th class="table-header table-header--left checkbox-col" style="width: 50px" />
                {/if}
                {#each tableColumns as column}
                    <th
                        class="table-header table-header--{column.alignment}"
                        style="width: {column.width}"
                    >
                        {@html column.label}
                    </th>
                {/each}
            </thead>

            {#each order.refundLineItems as refundItem, index}
                <tr class="table-row refund-line-item">
                    {#if isRefundProcess}
                        <td class="table-cell table-cell--left checkbox-col" />
                    {/if}
                    {#each tableColumns as column}
                        <td
                            class="table-cell table-cell--{column.alignment}"
                            class:table-cell--bold={column.bold}
                            class:table-cell--redbold={column.redbold}
                        >
                            {formatProductValue(refundItem, index, column)}
                        </td>
                    {/each}
                </tr>
            {/each}

            {#each order.refunds as refund, refundIndex}
                {#if !order.refundLineItems.length}
                    <tr class="table-row">
                        {#if isRefundProcess}
                            <td class="table-cell table-cell--left checkbox-col" />
                        {/if}
                        <td class="table-cell table-cell--left table-cell">{++refundIndex}</td>
                        <td class="table-cell table-cell--left table-cell--bold"
                            >{$_("order.partialRefund")}</td
                        >
                        <td class="table-cell table-cell--left table-cell--bold" colspan="5" />
                        <td class="table-cell table-cell--right table-cell--redbold">
                            {formatAmount(refund.amount)}
                        </td>
                    </tr>
                {/if}
                {#each refund.buyer_refund_surcharges || [] as surcharge}
                    <tr class="table-row">
                        {#if isRefundProcess}
                            <td class="table-cell table-cell--left checkbox-col" />
                        {/if}
                        {#each tableColumns as column}
                            <td
                                class="table-cell table-cell--{column.alignment}"
                                class:table-cell--bold={column.bold}
                            >
                                {formatProductValue(
                                    formatSurchargeToMatchColumnTypes(surcharge),
                                    refundIndex,
                                    column
                                )}
                            </td>
                        {/each}
                    </tr>
                {/each}
            {/each}
            <tr>
                <td {colspan} />
                <td class="table-cell summary-cell table-cell--right"
                    >{$_("order.edit.subtotal")}</td
                >
                <td class="summary-cell" />
                <td class="table-cell summary-cell table-cell--right table-cell--redbold">
                    -{formatAmount(totalOrderRefundedAmount - totalOrderRefundedVAT)}
                </td>
            </tr>
            <tr>
                <td {colspan} />
                <td class="table-cell summary-cell table-cell--right">{taxNaming}</td>
                <td class="table-cell summary-cell table-cell--right" />
                <td class="table-cell summary-cell table-cell--right table-cell--red">
                    -{formatAmount(totalOrderRefundedVAT)}
                </td>
            </tr>
            <tr>
                <td {colspan} />
                <td class="table-cell table-cell--right table-cell--bold"
                    >{$_("order.totalRefunded")}</td
                >
                <td />
                <td class="table-cell table-cell--right table-cell--bold">
                    {formatAmount(totalOrderRefundedAmount)}
                </td>
            </tr>
        </table>
    </div>
</MetaWrapper>

<style lang="scss">
    .card {
        flex-basis: 100%;
        border-radius: 0.5rem;
        background-color: var(--basic);
        padding: 1.5rem;
        --tw-bg-opacity: 1;

        border: 1px solid var(--gray-100);
        box-shadow: 0 0 6px rgba(208, 213, 221, 0.25);

        &--table {
            padding: 0;
        }
    }

    .scrollbar {
        overflow: auto;
    }

    .card-title {
        display: flex;
        gap: 10px;
        font-weight: 700;
        line-height: 24px;
    }

    .card-title {
        padding: 24px;
    }

    .card-heading {
        color: var(--ctnGeneralTertiary);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-top: 16px;
    }

    .download-invoice {
        border-radius: 8px;
        border: 1px solid var(--gray-300, #d0d5dd);
        background: var(--basic-white, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--gray-700, #344054);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.5px;
        padding: 8px 14px;
    }

    .product-table-wrap {
        width: 100%;
    }

    .main-bar {
        display: flex;
        justify-content: space-between;
    }

    .button-container {
        margin: auto 1rem;
        display: flex;
        gap: 1rem;
    }

    .checkbox-label {
        line-height: 20px;
        margin: auto;
        height: 20px;
        display: flex;
    }

    .item-checkbox {
        height: 1rem;
        width: 1rem;
        margin-right: 1rem;
    }

    .table {
        width: 100%;
        min-width: 930px;
        table-layout: fixed;
        font-size: 14px;
        font-weight: 400;
        color: var(--gray-700);
        margin-bottom: 24px;

        border-collapse: collapse;
        border-spacing: unset;
    }

    th,
    td {
        cursor: default;
    }

    .table-head {
        background-color: var(--bgGeneralQuaternary);
        color: var(--ctnGeneralSecondary);
    }

    .table-header {
        width: 100%;
        padding: 0 8px;
        height: 48px;

        &:first-child {
            padding-left: 24px;
        }

        &.extend-margin {
            padding-left: 56px;
        }

        &:last-child {
            padding-right: 24px;
        }

        &--left {
            text-align: left;
        }

        &--right {
            text-align: right;
        }
    }

    .table-row {
        border-bottom: 1px solid var(--gray-200);
    }

    .table-cell {
        padding: 0 8px;
        font-weight: 400;

        &:first-child {
            padding-left: 24px;
        }

        &:last-child {
            padding-right: 24px;
        }

        &--right {
            text-align: right;
        }

        &--bold {
            font-weight: 500;
            color: var(--gray-800);
        }

        &--red {
            color: var(--error-700);
        }

        &--redbold {
            font-weight: 700;
            color: var(--error-700);
        }
    }
    .summary-cell {
        border-bottom: 1px solid var(--gray-200);

        &--medium {
            width: 150px;
        }

        &--small {
            width: 100px;
        }
    }
</style>
