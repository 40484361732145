<script lang="ts">
    import { _ } from "svelte-i18n";
    import { createEventDispatcher, onMount } from "svelte";

    import Button from "../../Elements/Button.svelte";
    import { Button as TwoUIButton } from "@two-ui/core";
    import CloseButton from "../CloseButton.svelte";
    import InputBox from "../../Elements/InputBox.svelte";
    import PhoneInput from "../../authentication/PhoneInput.svelte";
    import Selector from "../../Elements/Selector.svelte";
    import type { IMUserRoles, IMNewUserConfig } from "../../../../src/static/types";
    import {
        loading as accountLoading,
        account,
        signupComplete
    } from "../../../store/merchant/account.store";
    import { emailIsValid, validatePhoneNumber } from "@/utils/validation";
    import SignupIncompleteModal from "../../../components/modals/AccountSetup/SignupIncompleteModal.svelte";
    import { COUNTRIES } from "../../../static/constant";
    import modalState from "../../../store/modals.store";
    const dispatch = createEventDispatcher();

    export let props: any = {};
    export let submitText = $_("components.submit");
    let submitUser: CallableFunction = null;
    let userId: string = "";
    let firstname: string = "";
    let lastname: string = "";
    let teamname: string = "";
    let email: string = "";
    let phoneNumberWithCode: string = "+44"; //phone number with countrycode
    let phoneCountryCode: string = "";
    let phoneNumber: string = "1234"; //phone number without countrycode
    let invalidEmail: string = "";
    let invalidPhone: string = "";
    let invalidFirstname: string = "";
    let invalidLastname: string = "";
    const userTypeOptions: IMUserRoles[] = ["Admin", "Developer", "Accountant", "User"];
    let selectedUserType: IMUserRoles = "User";

    let loading: boolean = false;

    function handleSubmit() {
        invalidFirstname = !firstname
            ? $_("components.input.required", {
                  values: { type: $_("components.input.firstNameType") }
              })
            : "";
        invalidLastname = !lastname
            ? $_("components.input.required", {
                  values: { type: $_("components.input.lastNameType") }
              })
            : "";
        invalidEmail = !email
            ? $_("components.input.required", {
                  values: { type: $_("components.input.emailType") }
              })
            : !emailIsValid(email)
              ? $_("components.input.invalid", {
                    values: { type: $_("components.input.emailType") }
                })
              : "";

        invalidPhone = validatePhoneNumber($_, phoneCountryCode, phoneNumberWithCode, false);

        if (invalidFirstname || invalidLastname || invalidEmail || invalidPhone) {
            return;
        }

        const data: IMNewUserConfig = {
            first_name: firstname,
            last_name: lastname,
            team: teamname,
            phone_number: phoneNumberWithCode,
            email,
            role: selectedUserType?.toUpperCase()
        };
        if (!$signupComplete) {
            modalState.actions.setModal("", {}, SignupIncompleteModal);
            return;
        }
        if (userId) {
            submitUser(userId, data);
        } else {
            submitUser(data);
        }
    }

    $: loading = $accountLoading;

    $: if (!phoneNumberWithCode) setPhoneNumber($account.countryCode);

    function setPhoneNumber(code: string) {
        phoneCountryCode = COUNTRIES[code]?.phoneCountryCode ?? "";
        phoneNumberWithCode = COUNTRIES[code]?.phoneCountryCode ?? "";
    }

    onMount(() => {
        userId = props?.id;
        firstname = props?.first_name;
        lastname = props?.last_name;
        teamname = props?.team;
        email = props?.email;
        selectedUserType = props?.role || selectedUserType;
        submitUser = props?.submit_user;
        if (props?.phone_number_with_code) {
            phoneNumberWithCode = props.phone_number_with_code;
        }
    });
</script>

<div class="h-full flex flex-col sm:w-modal-dekstop-width">
    <div class="grid gap-6">
        <div class="flex">
            <div class="w-1/2 pr-2">
                <InputBox
                    id="user-name"
                    label={$_("components.input.firstNameLabel")}
                    required
                    requiredStar={true}
                    type="text"
                    placeholder={$_("components.input.firstNamePlaceholder")}
                    bind:invalid={invalidFirstname}
                    bind:value={firstname}
                />
            </div>
            <div class="w-1/2 pl-2">
                <InputBox
                    id="user-surname"
                    label={$_("components.input.lastNameLabel")}
                    required
                    requiredStar={true}
                    type="text"
                    placeholder={$_("components.input.lastNamePlaceholder")}
                    bind:invalid={invalidLastname}
                    bind:value={lastname}
                />
            </div>
        </div>
        <div>
            <InputBox
                id="user-team"
                label={$_("components.input.teamNameLabel")}
                type="text"
                placeholder={$_("components.input.teamNamePlaceholder")}
                bind:value={teamname}
            />
        </div>
        <div>
            <InputBox
                id="user-email"
                label={$_("components.input.emailLabel")}
                required
                requiredStar={true}
                type="email"
                placeholder={$_("components.input.emailPlaceholder")}
                bind:invalid={invalidEmail}
                bind:value={email}
            />
        </div>
        <div class="flex flex-col justify-between relative">
            <label
                class="label text-sm text-gray-700"
                class:label_valid={invalidPhone.length}
                for=""
            >
                {$_("components.input.phoneNumberLabel")} <span>*</span>
            </label>
            <PhoneInput
                id="user-phone"
                disable={false}
                bind:phoneNumberWithCode
                bind:phoneNumber
                bind:countryCode={phoneCountryCode}
                bind:invalid={invalidPhone}
            />
            {#if invalidPhone.length}
                <p class="error">{invalidPhone}</p>
            {/if}
        </div>
        <div>
            <Selector
                id="user-role"
                label="Role"
                required
                showArrow={true}
                requiredStar={true}
                options={userTypeOptions}
                backgroundColor="white"
                bind:value={selectedUserType}
            />
        </div>
    </div>

    <div class="h-full pt-10 button-container">
        <div class="flex pb-4 button-sub-container">
            <div class="w-full sm:w-auto pr-4">
                <TwoUIButton
                    id="submit-user"
                    on:click={handleSubmit}
                    size="md"
                    variant="primary"
                    content={submitText}
                    fullSize={true}
                    {loading}
                />
            </div>
            <TwoUIButton
                on:click={() => dispatch("close")}
                size="md"
                variant="secondaryGray"
                content={$_("components.cancel")}
                fullSize={true}
            />
        </div>
    </div>
</div>
<div class="absolute top-6 right-6">
    <CloseButton on:click={() => dispatch("close")} />
</div>

<style>
    .error {
        position: absolute;
        top: 70px;
        color: var(--error-600);
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }

    .label_valid {
        color: var(--semantic-error);
    }

    .button-container {
        position: relative;
        min-height: 8rem;
    }

    .button-sub-container {
        position: absolute;
        bottom: 0;
    }
</style>
