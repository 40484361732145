<script lang="ts">
    import { _ } from "svelte-i18n";

    import Icon from "@/components/icons/Icon.svelte";

    export let numRows: number = 10;
    export let numAllRecords: number = 0;
    export let pageNum: number = 1;
    export let numPages: number = 1;
    export let prevPage;
    export let nextPage;
</script>

<div class="py-4 pr-20 flex justify-end page-counter font-normal text-xs">
    <span class="mr-4"
        >{`${numRows * pageNum - numRows + 1}-${
            numRows * pageNum > numAllRecords ? numAllRecords : numRows * pageNum
        } of ${numAllRecords}`}</span
    >
    <button
        class="cursor-pointer"
        aria-disabled={pageNum === 1}
        on:click={() => prevPage()}
        on:keypress={() => prevPage()}
    >
        <Icon name="arrow-left" size={16} fill={pageNum === 1 ? "#D0D5DD" : "#667085"} />
    </button>
    <span class="px-1"
        >{$_("components.table.pageNumOfPages", {
            values: { pageNum, amount: numPages }
        })}</span
    >
    <button
        class="cursor-pointer scale-x-[-1]"
        aria-disabled={pageNum === numPages}
        on:click={() => nextPage()}
        on:keypress={() => nextPage()}
    >
        <Icon name="arrow-left" size={16} fill={pageNum === numPages ? "#D0D5DD" : "#667085"} />
    </button>
</div>

<style lang="scss">
    .page-counter {
        color: var(--ctnGeneralSecondary);
        user-select: none;
        box-shadow: 0px -10px 5px #eee;
        z-index: 9;
    }
</style>
