<script lang="ts">
    import { _ } from "svelte-i18n";
    import { createEventDispatcher, onDestroy, onMount } from "svelte";
    import { emailIsValid, validatePhoneNumber } from "../../utils/validation";
    import type { InputType } from "../../static/types";
    export let id = Math.random().toString();
    export let value = "";
    export let minLength: number = null;
    export let maxLength: number = null;
    export let diallingCountryCode: string = null;
    export let inputType: InputType = "text";
    export let nullAllowed = true;
    export let placeholder = "";
    export let showInvalidText = false;
    export let customClasses = "";
    export let dataTestId = "";

    const dispatch = createEventDispatcher();

    const typeMap = {
        tel: $_("components.inputType.phone"),
        email: $_("components.inputType.email"),
        number: $_("components.inputType.number"),
        text: $_("components.inputType.text")
    };
    const requiredWarning = $_("components.input.required", {
        values: { type: typeMap[inputType] }
    });
    const invalidWarning = $_("components.input.invalid", {
        values: { type: typeMap[inputType].toLowerCase() }
    });
    let invalidText = "";

    onMount(() => {
        document.addEventListener("keydown", eventHandler);
        document.getElementById(id).focus();
    });

    onDestroy(() => {
        document.removeEventListener("keydown", eventHandler);
    });

    function eventHandler(event: KeyboardEvent) {
        if (event.key === "Escape") onCancel();
    }

    function validateValue(value?: string): boolean {
        if (!nullAllowed && nullOrEmpty(value)) {
            invalidText = requiredWarning;
            return false;
        }
        if (nullAllowed && !value) {
            invalidText = "";
            return true;
        }
        if (minLength && maxLength) {
            invalidText =
                value.length <= maxLength && value.length >= minLength
                    ? ""
                    : $_("components.input.length", {
                          values: { min: minLength - 1, max: maxLength + 1 }
                      });
            return !invalidText;
        }
        switch (inputType) {
            case "text":
                invalidText = typeof value === "string" ? "" : invalidWarning;
                break;
            case "tel":
                invalidText = validatePhoneNumber($_, diallingCountryCode, value);
                break;
            case "email":
                invalidText = emailIsValid(value) ? "" : invalidWarning;
                break;
            default:
                invalidText = "";
        }
        return !invalidText;
    }

    function onChange(event) {
        showInvalidText = false;
        validateValue(event.target.value);
        value = event.target.value;
    }

    function nullOrEmpty(value) {
        return ["", null].includes(value);
    }
    function onCancel() {
        dispatch("close");
    }
</script>

<div class={`input-field ${customClasses}`} class:show-error-input={showInvalidText}>
    <input
        on:blur={() => (showInvalidText = invalidText.length > 0)}
        {id}
        type={inputType}
        class="custom-input w-full show-error text-Content-General-Primary"
        {value}
        on:input={onChange}
        data-testid={dataTestId}
        {placeholder}
    />
</div>
{#if showInvalidText}
    <div class="error -bottom-5 text-sm text-Content-Semantic-Error-Primary">{invalidText}</div>
{/if}

<style>
    .input-field {
        border: 1px solid var(--gray-300);
        background: var(--basic);
        display: flex;
        align-items: center;
        gap: 0.5rem;
        align-self: stretch;
        border-radius: 0.5rem;
        background: var(--basic, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        width: 100%;
        height: 3rem;
    }
    .input-field:focus-within,
    .input-field:focus {
        border: 2px solid var(--borderSemanticHighlightSecondary);
    }

    input:focus {
        outline: none;
    }
    input {
        padding: 0.625rem 0.875rem 0.625rem 0.825rem;
        border-radius: 0.5rem;
    }
    .show-error-input:focus,
    .show-error-input:focus-within {
        border: 2px solid var(--semantic-error);
    }
    .error {
        padding-top: 4px;
    }
    .custom-input::placeholder {
        color: var(--ctnInteractiveGeneralDisabled);
    }
</style>
