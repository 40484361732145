export const ABN_COLOURS: Record<string, string> = {
    white: "#ffffff",
    black: "#000000",
    gray25: "#A7B0BE",
    gray50: "#F9FAFB",
    gray100: "#F2F4F7",
    gray200: "#E4E7EC",
    gray300: "#D0D5DD",
    gray400: "#98A2B3",
    gray500: "#667085",
    gray600: "#475467",
    gray700: "#344054",
    gray800: "#1D2939",
    primary25: "#F5F8FF",
    primary50: "#EEF4FF",
    primary100: "#C7D7FE",
    primary200: "#A4BCFD",
    primary300: "#8098F9",
    primary400: "#6172F3",
    primary500: "red",
    primary600: "green",
    primary700: "#000000",
    primary800: "#2D3282",
    primary900: "#13174B",
    secondary50: "#F0F9FF",
    secondary200: "#B9E6FE",
    secondary300: "#7CD4FD",
    secondary400: "#36BFFA",
    secondary500: "#0BA5EC",
    secondary600: "#0086C9",
    secondary700: "#026AA2",
    secondary800: "#0B4A6F",
    secondary900: "#032D44",
    error50: "#FEF3F2",
    error100: "#FEE4E2",
    error200: "#FECDCA",
    error400: "#F97066",
    error600: "#D92D20",
    error700: "#B42318",
    error800: "#7A271A",
    error900: "#3D0D0A",
    warning50: "#FFFAEB",
    warning100: "#FEF0C7",
    warning200: "#FEDF89",
    warning400: "#FDB022",
    warning600: "#DC6803",
    warning700: "#B54708",
    warning800: "#7A2E0E",
    warning900: "#421906",
    info50: "#EFF8FF",
    info200: "#B2DDFF",
    info400: "#53B1FD",
    info600: "#1570EF",
    info700: "#175CD3",
    info800: "#194185",
    info900: "#091B3F",
    success50: "#ECFDF3",
    success100: "#D1FADF",
    success200: "#A6F4C5",
    success400: "#32D583",
    success600: "#039855",
    success700: "#027A48",
    success800: "#05603A",
    success900: "#022717",
    // Brand
    brandPrimary: "#004C4C",
    brandSecondary: "#F3C000",
    // CONTENT
    // General
    ctnGeneralPrimary: "#222222",
    ctnGeneralSecondary: "#616161",
    ctnGeneralTertiary: "#616161",
    ctnGeneralInverse: "#FFFFFF",
    // Semantic
    ctnSemanticHighlightPrimary: "#004C4C",
    ctnSemanticHighlightSecondary: "#F3C000",
    ctnSemanticErrorPrimary: "#C21700",
    ctnSemanticErrorSecondary: "#3D0D0A",
    ctnSemanticSuccessPrimary: "#079455",
    ctnSemanticSuccessSecondary: "#023A22",
    ctnSemanticWarningPrimary: "#DC6803",
    ctnSemanticWarningSecondary: "#421906",
    ctnSemanticInfoPrimary: "#175CD3",
    ctnSemanticInfoSecondary: "#0C2659",
    // Interactive
    ctnInteractiveGeneralDefault: "#616161",
    ctnInteractiveGeneralHover: "#222222",
    ctnInteractiveGeneralFocused: "#222222",
    ctnInteractiveGeneralLoading: "#6A778E",
    ctnInteractiveGeneralDisabled: "#7D7D7D",
    ctnInteractiveGeneralInverse: "#FFFFFF",
    ctnInteractiveSemanticHighlightDefault: "#004C4C",
    ctnInteractiveSemanticHighlightHover: "#003737",
    ctnInteractiveSemanticHighlightFocused: "#002929",
    ctnInteractiveSemanticHighlightLoading: "#CCCCCC",
    // ctnInteractiveSemanticHighlightDisabled: '#6172F3',
    ctnInteractiveSemanticErrorDefault: "#C21700",
    ctnInteractiveSemanticErrorHover: "#B51500",
    ctnInteractiveSemanticErrorFocused: "#A81400",
    ctnInteractiveSemanticErrorLoading: "#F97066",
    ctnInteractiveSemanticSuccessDefault: "#079455",
    ctnInteractiveSemanticSuccessHover: "#027A48",
    ctnInteractiveSemanticSuccessFocused: "#027A48",
    ctnInteractiveSemanticSuccessLoading: "#26BA6F",
    ctnInteractiveSemanticWarningDefault: "#DC6803",
    ctnInteractiveSemanticWarningHover: "#B54708",
    ctnInteractiveSemanticWarningFocused: "#B54708",
    ctnInteractiveSemanticWarningLoading: "#FEDF89",
    ctnInteractiveSemanticInfoDefault: "#175CD3",
    ctnInteractiveSemanticInfoHover: "#1849A9",
    ctnInteractiveSemanticInfoFocused: "#1849A9",
    ctnInteractiveSemanticInfoLoading: "#53B1FD",
    // BACKGROUND
    // General
    bgGeneralPrimary: "#FFFFFF",
    bgGeneralSecondary: "#F3F3F3",
    bgGeneralTertiary: "#F3F3F3",
    bgGeneralQuaternary: "#E9E9E9",
    bgGeneralInverse: "#222222",
    // Semantic
    bgSemanticHighlightPrimary: "#004C4C",
    bgSemanticHighlightSecondary: "#00A296",
    bgSemanticHighlightTertiary: "#E3F0F7",
    bgSemanticHighlightInverse: "#CCCCCC",
    bgSemanticErrorPrimary: "#C21700",
    bgSemanticErrorSecondary: "#FEE4E2",
    bgSemanticErrorTertiary: "#FEF3F2",
    bgSemanticSuccessPrimary: "#079455",
    bgSemanticSuccessSecondary: "#DCFAE6",
    bgSemanticSuccessTertiary: "#ECFDF3",
    bgSemanticWarningPrimary: "#DC6803",
    bgSemanticWarningSecondary: "#FEF0C7",
    bgSemanticWarningTertiary: "#FFFAEB",
    bgSemanticInfoPrimary: "#175CD3",
    bgSemanticInfoSecondary: "#B2DDFF",
    bgSemanticInfoTertiary: "#EFF8FF",
    // Interactive
    bgInteractiveGeneralDefault: "#FFFFFF",
    bgInteractiveGeneralHover: "#E9E9E9",
    bgInteractiveGeneralFocused: "#DEDEDE",
    bgInteractiveGeneralLoading: "#EAECF0",
    bgInteractiveGeneralDisabled: "#DEDEDE",
    bgInteractiveGeneralInverse: "#1D2939",
    bgInteractiveSemanticHighlightDefault: "#004C4C",
    bgInteractiveSemanticHighlightHoverPrimary: "#003737",
    bgInteractiveSemanticHighlightHoverSecondary: "#E9E9E9",
    bgInteractiveSemanticHighlightFocusedPrimary: "#002929",
    bgInteractiveSemanticHighlightFocusedSecondary: "#DEDEDE",
    bgInteractiveSemanticHighlightPressed: "#004C4C",
    bgInteractiveSemanticHighlightLoading: "#CCCCCC",
    bgInteractiveSemanticHighlightDisabled: "#CCCCCC",
    bgInteractiveSemanticErrorDefault: "#C21700",
    bgInteractiveSemanticErrorHover: "#B51500",
    bgInteractiveSemanticErrorHoverSecondary: "#FEF3F2",
    bgInteractiveSemanticErrorFocused: "#A81400",
    bgInteractiveSemanticErrorFocusedSecondary: "#FEF3F2",
    bgInteractiveSemanticErrorPressed: "#912018",
    bgInteractiveSemanticErrorLoading: "#FECDCA",
    bgInteractiveSemanticErrorDisabled: "#EAECF0",
    // BORDER
    // General
    borderGeneralPrimary: "#E9E9E9",
    borderGeneralSecondary: "#7D7D7D",
    borderGeneralTertiary: "#004C4C",
    // Semantic
    // Highlight
    borderSemanticHighlightPrimary: "#004C4C",
    borderSemanticHighlightSecondary: "#CCCCCC",
    borderSemanticHighlightTertiary: "#CCCCCC",
    // Error
    borderSemanticErrorPrimary: "#C21700",
    borderSemanticErrorSecondary: "#FCA19B",
    borderSemanticErrorTertiary: "#FEE4E2",
    // Success
    borderSemanticSuccessPrimary: "#079455",
    borderSemanticSuccessSecondary: "#A6F4C5",
    borderSemanticSuccessTertiary: "#D1FADF",
    // Warning
    borderSemanticWarningPrimary: "#DC6803",
    borderSemanticWarningSecondary: "#FEDF89",
    borderSemanticWarningTertiary: "#FEF0C7",
    // Info
    borderSemanticInfoPrimary: "#175CD3",
    borderSemanticInfoSecondary: "#53B1FD",
    borderSemanticInfoTertiary: "#B2DDFF"
};

export default ABN_COLOURS;
