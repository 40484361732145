<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Button } from "@two-ui/core";

    import API from "@/services/apiService";
    import Drawer from "@/components/Elements/Drawer.svelte";
    import AddRuleForm from "./AddRuleForm.svelte";
    import AddRuleReview from "./AddRuleReview.svelte";
    import notificationState from "@/store/notifications.store";
    import { NotificationType } from "@/static/types";
    import {
        fraudRulesAddNewFlow,
        fraudRulesAddNewStep,
        fraudRulesEmails,
        fraudRulesPhones,
        fraudRulesComment,
        fraudRulesOrgNumber,
        fraudRulesType,
        fraudRulesReason
    } from "@/store/merchant/risk.store";

    function onCancel() {
        fraudRulesAddNewFlow.set("");
        fraudRulesOrgNumber.set("");
        fraudRulesComment.set("");
        fraudRulesPhones.set([]);
        fraudRulesEmails.set([""]);
        fraudRulesAddNewStep.set(1);
        fraudRulesType.set("");
        fraudRulesReason.set("");
    }

    let ruleFormRef;

    function goToReviewStep() {
        if (ruleFormRef && ruleFormRef.onContinue) {
            ruleFormRef.onContinue();
        }
    }

    function submitFraudRule() {
        const data = {
            type: $fraudRulesAddNewFlow === "block" ? $fraudRulesType : $fraudRulesReason,
            comment: $fraudRulesComment
        };

        if ($fraudRulesOrgNumber) {
            data["org_number"] = $fraudRulesOrgNumber;
        }

        if ($fraudRulesPhones.some((phone) => phone.value && phone.value.length > 5)) {
            data["phones"] = $fraudRulesPhones.map(({ value }) => value);
        }

        if ($fraudRulesEmails.some((email) => email)) {
            data["emails"] = $fraudRulesEmails;
        }

        API.post("https://jsonplaceholder.typicode.com/posts", data)
            .then(() => {
                onCancel();
                // TODO: adjust according to the backend once it's ready
                notificationState.actions.create(NotificationType.SUCCESS, "3 new rule(s) added");
                setTimeout(() => {
                    notificationState.actions.create(
                        NotificationType.WARNING,
                        "Values already on the allow list",
                        null,
                        "These values can't be added again:<br/><b>Email - againguy@jellycat.com</b>"
                    );
                }, 500);
                setTimeout(() => {
                    notificationState.actions.create(
                        NotificationType.ERROR,
                        "Unable to block whitelisted values",
                        null,
                        "To block them, please remove it from the allow list first:<br/><b>Email - againguy@jellycat.com</b>"
                    );
                }, 1000);
            })
            .catch(() => {
                notificationState.actions.create(NotificationType.ERROR, "submitFraudRule failed");
            });
    }

    function onFormSubmit() {
        if ($fraudRulesAddNewStep === 1) {
            goToReviewStep();
        } else {
            submitFraudRule();
        }
    }
</script>

<Drawer
    title={$fraudRulesAddNewFlow === "block"
        ? $_("riskManagement.rules.addBlockRule")
        : $fraudRulesAddNewFlow === "whitelist"
          ? $_("riskManagement.rules.addWhitelistRule")
          : $_("components.loading")}
    onClose={onCancel}
>
    <div slot="main">
        {#if $fraudRulesAddNewStep === 1}
            <AddRuleForm bind:this={ruleFormRef} />
        {:else}
            <AddRuleReview />
        {/if}
    </div>
    <div class="flex" slot="footer">
        <Button
            on:click={onFormSubmit}
            size="md"
            class="mr-2"
            variant={$fraudRulesAddNewStep === 2 && $fraudRulesAddNewFlow === "block"
                ? "destructivePrimary"
                : "primary"}
            content={$fraudRulesAddNewStep === 1
                ? $_("components.continue")
                : $fraudRulesAddNewFlow === "block"
                  ? $_("riskManagement.fraud.block")
                  : $_("riskManagement.fraud.whitelist")}
        />
        <Button
            on:click={onCancel}
            size="md"
            variant="secondaryGray"
            content={$_("components.cancel")}
        />
    </div>
</Drawer>

<style lang="scss">
    :global(.with-error .phone-with-custom-input) {
        border: 1px solid var(--borderSemanticErrorSecondary);
        background: var(--bgInteractiveSemanticErrorHoverSecondary);
    }
</style>
