<script lang="ts">
    import Fa from "svelte-fa";
    import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
    import { createEventDispatcher } from "svelte";

    import ToolTip from "../../components/tooltip/ToolTip.svelte";

    export let requiredStar: boolean = true;
    export let value: any = "";
    export let label: string = "";
    export let type: string = "";
    export let disabled: boolean = false;
    export let required: boolean = false;
    export let name: string = "";
    export let isFocus: boolean = false;
    let innerType: string = "password";

    export let id: string = "userinput";
    export let invalid: string = "";
    export let showInvalidError: boolean = true;
    export let placeholder: string = "";
    export let onBlur: any = () => {
        return;
    };
    export let onFocus: ((event: FocusEvent) => void) | null = null;
    export let helperText: string = "";

    export let toolTipContent: string = "";
    export let hasIcon: boolean = false;

    let showLabel = true;

    const dispatch = createEventDispatcher();

    function handleChange(): any {
        if (value) {
            invalid = "";
        }
        dispatch("change", { value, id });
    }
    function topLabel(): any {
        isFocus = true;
    }
    function loseFocus(): any {
        isFocus = false;
    }
    function handleFocus(event: FocusEvent) {
        isFocus = true;
        if (onFocus) {
            onFocus(event);
        }
    }
    function changeType(): any {
        if (innerType === "text") {
            innerType = "password";
        } else if (innerType === "password") {
            innerType = "text";
        }
    }
    $: showLabel = Boolean(label || toolTipContent);
</script>

{#if showLabel}
    <div class="flex items-center" class:with-error={invalid ? true : false}>
        <label
            class="label text-s text-Content-General-Secondary pb-[6px]"
            class:focus={isFocus}
            class:left-12={type === "email" || type === "password"}
            class:label_valid={invalid}
            for={id}
        >
            {label}
            {#if required && requiredStar}
                <span class:label_valid={invalid}>*</span>
            {/if}
        </label>
        {#if toolTipContent}
            <span class="ml-2 z-10">
                <ToolTip width="250px" position="top" placement="start" content={toolTipContent}>
                    <Fa size="sm" icon={faCircleQuestion} color="var(--primary-500)" />
                </ToolTip>
            </span>
        {/if}
    </div>
{/if}

<div
    class="relative flex w-full input_wrapper rounded-lg"
    class:mt-1={showLabel}
    class:border_focus={isFocus}
    class:incorrect={invalid ? true : false}
    class:has-icon={hasIcon}
>
    {#if type === "email"}
        <input
            type="email"
            bind:value
            {name}
            {placeholder}
            {id}
            {disabled}
            class="w-full border-none outline-hidden"
            on:focus={topLabel}
            on:focusout={loseFocus}
            on:input={handleChange}
            on:blur={onBlur}
            on:focus={handleFocus}
        />
    {:else if type === "number"}
        <input
            type="number"
            bind:value
            {name}
            {placeholder}
            {id}
            {disabled}
            class="w-full border-none outline-hidden"
            on:focus={handleFocus}
            on:blur={onBlur}
            on:focusout={loseFocus}
            on:input={handleChange}
        />
    {:else if type === "textarea"}
        <textarea
            bind:value
            {name}
            {placeholder}
            {id}
            {disabled}
            class="w-full border-none outline-hidden"
            on:focus={handleFocus}
            on:blur={onBlur}
            on:focusout={loseFocus}
            on:input={handleChange}
        />
    {:else if type === "password"}
        <img
            class="absolute ml-4 top-4 front-icon"
            src={`/pictures/password${invalid ? "-incorrect" : isFocus ? "-color" : ""}.svg`}
            alt="password"
        />
        <!-- I found Great and bad and fucking solution -->
        {#if innerType === "text"}
            <input
                bind:value
                type="text"
                {name}
                {id}
                class="w-full pl-12 bg-white border-none outline-hidden"
                on:focus={handleFocus}
                on:blur={onBlur}
                on:focusout={loseFocus}
                on:input={handleChange}
            />
        {:else if innerType === "password"}
            <input
                bind:value
                {name}
                type="password"
                {id}
                class="w-full pl-12 bg-white border-none outline-hidden"
                on:focus={handleFocus}
                on:blur={onBlur}
                on:focusout={loseFocus}
                on:input={handleChange}
            />
        {/if}
        {#if value !== ""}
            <img
                class="mr-3 cursor-pointer front-icon"
                on:click={changeType}
                on:keydown={changeType}
                src={innerType === "text" ? "/pictures/eye.svg" : "/pictures/eyeclose.svg"}
                alt="eye"
            />
        {/if}
    {:else if type === "text"}
        <input
            bind:value
            type="text"
            {placeholder}
            {name}
            {disabled}
            {id}
            class="w-full pl-2 bg-white border-none outline-hidden sm:pl-4"
            class:disabled
            on:focus={handleFocus}
            on:blur={onBlur}
            on:focusout={loseFocus}
            on:input={handleChange}
            on:click
            autocomplete="off"
        />
    {:else if type === "dropdown-text"}
        <input
            bind:value
            type="text"
            {placeholder}
            {name}
            {disabled}
            {id}
            class="w-full pl-12 pr-8 bg-white border-none outline-hidden"
            on:focus={handleFocus}
            on:blur={onBlur}
            on:focusout={loseFocus}
            on:input={handleChange}
            on:click
            autocomplete="off"
        />
    {/if}
    {#if showInvalidError}
        <p class="error">
            {invalid}
        </p>
    {/if}
</div>
{#if helperText && !invalid}
    <p class="helper-text">{helperText}</p>
{/if}

<style lang="scss">
    .focus {
        color: var(--ctnGeneralPrimary);
    }

    .error {
        position: absolute;
        top: 48px;
        color: var(--error-600);
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }

    .border_focus {
        border: 2px solid var(--borderSemanticHighlightSecondary);
    }

    input {
        z-index: 1;
        height: 42px;
        border-radius: 8px;
        color: var(--ctnGeneralPrimary);
        padding-left: 14px;
        font-weight: normal;
    }

    input[type="text"].disabled {
        color: var(--ctnInteractiveGeneralDisabled);
        background: var(--bgInteractiveGeneralDisabled) !important;
    }

    textarea {
        z-index: 1;
        min-height: 100px;
        border-radius: 8px;
        background-color: white;
        padding: 10px 14px;

        &:disabled {
            color: var(--gray-500);
            background-color: var(--gray-50);
        }
    }

    input:focus,
    textarea:focus {
        background-color: white;
    }

    .incorrect {
        border: 1px solid var(--error-300);
        input {
            background-color: var(--bgSemanticErrorTertiary);
        }
    }

    .left-12 {
        left: 3rem;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    .label_valid {
        color: var(--error-600);
    }

    .helper-text {
        font-family: Aeonik;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--gray-400);
        padding-top: 4px;
    }

    .has-icon {
        padding-left: 1.5rem;
    }
</style>
