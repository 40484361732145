<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Statistic } from "@two-ui/core";
    import { onMount } from "svelte";

    import {
        getApprovalPerMonthChart,
        getRejectedNumberChart,
        getLossesPerMonthChart,
        getLostPercentageVolumeChart,
        getExposureChangeOverTimeChart
    } from "../common/dataUtils";
    import RiskChart from "../common/RiskChart.svelte";
    import LoadingWrapper from "@/components/Elements/LoadingWrapper.svelte";
    import environment from "@/services/environment";
    import utils from "@/utilsV2/currency";
    import { overviewData, dataLoaded, fetchOverviewData } from "@/store/merchant/risk.store";

    onMount(fetchOverviewData);

    const calculateTotalWeeklyExposure = (exposureData) => {
        return exposureData.reduce((total, item) => total + item.weekly_exposure, 0);
    };

    const calculateTotalWeeklyExposureOverride = (exposureData) => {
        return exposureData
            .filter((item) => item.exposure_type === "OVERRIDE")
            .reduce((total, item) => total + item.weekly_exposure, 0);
    };
</script>

{#if !$dataLoaded}
    <div class="gcard relative h-28">
        <LoadingWrapper loading={true} />
    </div>
{:else}
    <section id="cards" class="mx-8 mt-6 grid grid-cols-2 gap-4">
        <Statistic
            id="current-exposure"
            text={$_("riskManagement.exposure.total")}
            value={`${$overviewData?.currency} ${utils.formatNumber(
                calculateTotalWeeklyExposure($overviewData.exposure)
            )}`}
            textClasses="ctn-general-primary font-semibold text-base"
        >
            {$_("riskManagement.exposure.invoicesAwaitingPayment")}
        </Statistic>
        <Statistic
            id="bespoke-exposure"
            text={$_("riskManagement.exposure.bespokeExposure")}
            value={`${$overviewData?.currency} ${utils.formatNumber(
                calculateTotalWeeklyExposureOverride($overviewData.exposure)
            )}`}
            textClasses="ctn-general-primary font-semibold text-base"
        >
            {$_("riskManagement.exposure.invoicesOverdueAwaitingPayment")}
        </Statistic>
        <Statistic
            id="credit-approval-rate"
            text={$_("riskManagement.exposure.creditApprovalRate")}
            value="NA"
            valueClasses="ctn-general-tertiary"
            textClasses="ctn-general-primary font-semibold text-base"
        >
            {$_("riskManagement.exposure.creditApprovalRateDescription")}
        </Statistic>
        <Statistic
            id="credit-limit-change"
            text={$_("riskManagement.exposure.creditLimitChange")}
            value="NA"
            valueClasses="ctn-general-tertiary"
            textClasses="ctn-general-primary font-semibold text-base"
        >
            {$_("riskManagement.exposure.creditLimitChangeDescription")}
        </Statistic>
    </section>

    <section id="exposure-chart" class="mx-8 mt-4 grid grid-cols-1 bg-white rounded-lg">
        <RiskChart
            title={$_("riskManagement.exposure.title")}
            titleTooltip={$_("riskManagement.exposure.chartTooltip")}
            chartData={getExposureChangeOverTimeChart(
                $_("riskManagement.exposure.customersWithOverride"),
                $_("riskManagement.exposure.organicExposure", {
                    values: { brandName: environment.branding.displayName }
                }),
                $overviewData?.exposure,
                $overviewData?.currency
            )}
        />
    </section>

    <section id="credit-loses-charts" class="mx-8 mt-4 grid grid-cols-2 bg-white rounded-lg">
        <RiskChart
            title={$_("riskManagement.exposure.creditLoses")}
            subtitle={$_("riskManagement.exposure.losesPerMonth")}
            subtitleTooltip={$_("riskManagement.exposure.losesPerMonthTooltip")}
            chartData={getLossesPerMonthChart(
                "credit-losses-per-month",
                $_("riskManagement.exposure.customersWithOverride"),
                $_("riskManagement.exposure.organicExposure", {
                    values: { brandName: environment.branding.displayName }
                }),
                $overviewData?.credit_losses,
                $overviewData?.currency
            )}
            showCustomLegend={false}
        />

        <RiskChart
            title="&nbsp;"
            subtitle={$_("riskManagement.fraudInsights.percentageLostText")}
            subtitleTooltip={$_("riskManagement.exposure.percentageLostTooltip")}
            chartData={getLostPercentageVolumeChart(
                "credit-lost-percentage-volume",
                $_("riskManagement.exposure.customersWithOverride"),
                $_("riskManagement.exposure.organicExposure", {
                    values: { brandName: environment.branding.displayName }
                }),
                $overviewData?.credit_losses
            )}
        />
    </section>

    <section id="credit-approval" class="mx-8 my-4 grid grid-cols-2 bg-white rounded-lg">
        <RiskChart
            title={$_("riskManagement.exposure.creditApproval")}
            subtitle={$_("riskManagement.exposure.approvalRate") + " (%)"}
            subtitleTooltip={$_("riskManagement.exposure.approvalRateTooltip")}
            chartData={getApprovalPerMonthChart(
                "credit-approval-per-month",
                $_("riskManagement.exposure.customersWithOverride"),
                $_("riskManagement.exposure.organicExposure", {
                    values: { brandName: environment.branding.displayName }
                }),
                $overviewData?.credit_approval
            )}
            showCustomLegend={false}
        />

        <RiskChart
            title="&nbsp;"
            subtitle={$_("riskManagement.exposure.countRejectedCustomers")}
            subtitleTooltip={$_("riskManagement.exposure.countRejectedCustomersTooltip")}
            chartData={getRejectedNumberChart(
                "credit-approval-percentage-volume",
                $_("riskManagement.exposure.rejectedCustomers"),
                $_("riskManagement.exposure.totalCustomers"),
                $overviewData?.credit_approval
            )}
            showCustomLegend={false}
            showTotal={false}
        />
    </section>
{/if}
